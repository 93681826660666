//@ts-nocheck
import { useState, useEffect } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/dist/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';
import 'yet-another-react-lightbox/dist/styles.css';

// const slides = [
//   { src: 'https://source.unsplash.com/3bAblStd-IY/1080x607' },
//   { src: 'https://source.unsplash.com/HXqpJnLyHzg/1080x1620' },
//   { src: 'https://source.unsplash.com/yRc9FiM1Zdk/1080x1619' },
//   { src: 'https://dev.webapi.s-car.com.ua/api/Image?path=pic/0/0/0/0/0/0/0/2/8/000000028_i_i.jpg' },
// ];

export const LightboxComp = ({ runLightbox, setRunLightbox }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [slides, setSlides] = useState([]);
  const [el, setEl] = useState(null);

  const lightboxConfig = {
    width: 800, // задать ширину модального окна в пикселях
    height: 600, // задать высоту модального окна в пикселях
  };

  const closeHandler = () => {
    setEl(null)
    setLightboxOpen(false);
    setSlides([]);
    setRunLightbox([])

  };

  console.log('el', el);
  
  const handleContextMenu = (event) => {
      event.preventDefault();
  };

  useEffect(() => {
    if (runLightbox.length) {
      const arr = runLightbox.map((item) => {
        return {
          src: `${process.env.REACT_APP_API_URL}Image?path=${item.imagePath}`,
        };
      });
      setSlides([...arr]);
      setLightboxOpen(true);
    }

  }, [runLightbox]);

  useEffect(() => {
    if (lightboxOpen && !el) {
      const timeoutId = setTimeout(() => {
        const newEl = document.querySelector('.yarl__root.yarl__portal');
        if (newEl) {
          setEl(newEl);
        }
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [lightboxOpen, el]);

  useEffect(() => {
    if (el) {
      el.addEventListener('contextmenu', handleContextMenu, true);
      return () => el.removeEventListener('contextmenu', handleContextMenu, true);
    }
  }, [el]);
  
  

  return (
    <Lightbox
      open={lightboxOpen}
      close={closeHandler}
      slides={slides}
      plugins={[Fullscreen, Thumbnails, Zoom]}
      config={lightboxConfig}
      styles={{ root: { width: "70%", margin: "auto", height: "70%", border: "1px solid #dee2e6" } }}
    />
  );
};
