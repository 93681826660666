import React from "react";

type MarkerProps = {
  lat: number;
  lng: number;
  color: string;
  isDraggable: boolean;
  onClick: () => void;
};

export const Marker = ({
  lat,
  lng,
  color,
  onClick,
  isDraggable
}: MarkerProps) => {
  return (
    <div
      onClick={onClick}
      className="marker"
      draggable={isDraggable}
      id={color}
    />
  );
};

export default Marker;
