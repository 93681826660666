import React from "react";

// importing Mainpage components
import Footer from "../../components/Footer";
import OfficeImages from "../../components/office/OfficeImages";
import Breadcrumbs from "../../components/navigation/Breadcrumbs";

import "../../styles/office/office.scss";

function Office() {
  return (
    <body>
      <Breadcrumbs />
      <OfficeImages />
      <Footer />
    </body>
  );
}

export default Office;
