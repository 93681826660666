import produce from "immer";
import {
  GET_NEWS,
  GET_NEWS_SUCCESS,
  POST_NEWS,
  GET_ERROR,
  FETCH_CONTACTS,
  GET_TARGET_SUCCESS,
  GET_TARGETOF_SUCCESS,
  GET_TARGET_ERROR
} from "../const";

var targetArrType: Array<any> = [];

export const initialState = {
  error: false,
  errorText: "",
  isLoading: true,
  goToEditState: false,
  contact: "",
  newsArr: [],
  langArr: ["ru", "en", "uk"],
  langArrLocal: [],
  targetArr: targetArrType,
  targetOfArr: targetArrType,
  currentPage: 0,
  pageSize: 0,
  totalItems: 0,
  totalPages: 0
};

export const getNewsSelector = state => ({ ...state.news });

const newsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_NEWS:
        console.log("GET_PAGE");

        break;
      case GET_NEWS_SUCCESS:
        // {"items":[{"id":"f8b2453f-8aa2-45fe-84ba-671d1dd2f057","name":"3","annotation":"3","date":"2020-08-25T11:32:10.39"},{"id":"38b15da8-d234-40ee-93e8-f1377dbd44ae","name":"тест ру","annotation":"аннтотация ру","date":"2020-08-24T13:45:31.477"},{"id":"42c47fc9-bc3c-4465-af3a-003744d26f27","name":"123","annotation":"123","date":"2020-08-19T09:03:17.477"}],"currentPage":1,"pageSize":5,"totalItems":3,"totalPages":1,"isFirst":true,"isLast":true}
        if (action.value) {
          draft.isLoading = false;
          if (action.value.items !== null) {
            draft.newsArr = action.value.items;
            draft.currentPage = action.value.currentPage;
            draft.pageSize = action.value.pageSize;
            draft.totalItems = action.value.totalItems;
            draft.totalPages = action.value.totalPages;
          }
        } else {
          draft.isLoading = false;
        }
        break;
      case POST_NEWS:
        break;
      case GET_TARGET_SUCCESS:
        let retValArr: Array<any> = [];
        for (var i = 0; i < action.value.length; i++) {
          let retValItem = { label: "", value: "" };
          retValItem.label = action.value[i].name;
          retValItem.value = action.value[i].id;
          retValArr.push(retValItem);
        }
        console.log(JSON.stringify(retValArr));
        draft.targetArr = retValArr;
        break;
      case GET_TARGETOF_SUCCESS:
        let retValOfArr: Array<any> = [];
        for (var j = 0; j < action.value.length; j++) {
          let retValItem = { label: "", value: "" };
          retValItem.label = action.value[j].name;
          retValItem.value = action.value[j].id;
          retValOfArr.push(retValItem);
        }
        console.log(JSON.stringify(retValOfArr));
        draft.targetOfArr = retValOfArr;
        break;
      case GET_TARGET_ERROR:
        break;
      case FETCH_CONTACTS:
        draft.contact = action.contact.contact.name;
        break;
      case GET_ERROR:
        alert(action.value);
        break;
      default:
        return state;
    }
  });

export default newsReducer;
