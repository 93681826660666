import Form from 'react-bootstrap/Form';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export default function GetWarehouses({ setNpWarehous, npCityRef }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [rawWarehouses, setRawWarehouses] = useState([]);

  const warehouses = useWarehousesSearch(searchTerm, npCityRef);

  useEffect(() => {
    if (warehouses.length > 0) {
      setRawWarehouses(warehouses);
    }
  }, [warehouses]);

  const delayedQuery = debounce((e) => setSearchTerm(e), 1000);

  const handleSearchTermChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="custom-label">Отделение</Form.Label>
      <Combobox
        aria-label="Cities"
        openOnFocus
        onSelect={(name) => {
          setNpWarehous(
            rawWarehouses.filter((item: any) => item.Description === name)
          );
        }}
      >
        <ComboboxInput
          className="city-search-input"
          onChange={handleSearchTermChange}
          placeholder="Отделение"
          disabled={!npCityRef.length}
        />
        {warehouses && (
          <ComboboxPopover className="shadow-popup">
            {warehouses.length > 0 ? (
              <ComboboxList>
                {console.log('sd', warehouses)}
                {warehouses.map((city: any, ind) => {
                  return <ComboboxOption key={ind} value={city?.Description} />;
                })}
              </ComboboxList>
            ) : (
              <span style={{ display: 'block', margin: 8 }}>
                Уточните запрос
              </span>
            )}
          </ComboboxPopover>
        )}
      </Combobox>
    </Form.Group>
  );
}

function useWarehousesSearch(searchTerm, npCityRef) {
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    console.log('npCityRef.length', npCityRef.length);

    if (npCityRef.length) {
      fetchWarehouses(searchTerm, npCityRef).then((data) => {
        setWarehouses(data?.info?.totalCount > 0 ? data.data : []);
      });
    }
  }, [searchTerm, npCityRef]);

  return warehouses;
}

function fetchWarehouses(searchTerm, npCityRef) {
  const requestURL = 'https://api.novaposhta.ua/v2.0/json/';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      apiKey: '2b832a405d1a044db2a379c4530f2730',
      modelName: 'Address',
      calledMethod: 'getWarehouses',
      methodProperties: {
        CityRef: npCityRef[0].DeliveryCity,
        FindByString: searchTerm,
        Page: '1',
        Limit: '500',
        Language: 'RU',
      },
    }),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(requestURL, options)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
