import { call, takeLatest, put } from "redux-saga/effects";
import request from "../../../utils/request";
import { POST_LOGINPAGE, POST_REGISTER } from "../const";
import { API_URL } from "../../../utils/settings";
import { loginError, loginSuccess, registerError, registerSuccess } from "../actions";
import getLanguage from "../../../utils/getLanguage";

export function* postLogin(action) {
  const { login, pwd, func } = action;
  const requestURL = `${API_URL}/api/v1/Users/login`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      username: login,
      password: pwd,
      clientId: "19a8667d-39f1-4464-9027-d5d154ec68f5",
      fcmToken: "bk3RNwTe3H0:CI2k_HHwgIpoDKCIZvvDMExUdFQ3P1",
      source: "web"
    }),
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json"
    }
  };

  try {
    const log = yield call(request, requestURL, options);
    if (log.accessToken && log.refreshToken) {
      func();
      yield put(loginSuccess(log));
    } else {
      yield put(loginError(log.value));
    }
    return log;
  } catch (err) {
    yield put(loginError(err));
  }
}

export function* postRegister(action) {

  //const { name, surname, phone, email } = action;
  const requestURL = `${API_URL}/api/v1/Users/registration`;
  const options = {
    method: "POST",
    body: JSON.stringify({
      id: "00000000-0000-0000-0000-000000000000",
      name: "Иван",
      surname: "Иванов",
      region: "1b462e49-dc06-4058-a1bb-f48c5d901a95",
      city: "bd6d547a-9d16-4466-a90b-f626252157be",
      email: "user@gmail.com",
      customerType: "Частное лицо",
      phoneNumber: [
        "+380665554433"
      ],
      password: "1234567890123456",
      confirmPassword: "1234567890123456",
      companyName: "NoN"
    }),
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json"
    }
  };

  try {
    const log = yield call(request, requestURL, options);
    if (log.accessToken && log.refreshToken) {
      // func();
      yield put(registerSuccess(log));
    } else {
      alert("1" + JSON.stringify(log));
      yield put(registerError(log.value));
    }
    return log;
  } catch (err) {
    alert(JSON.stringify(err));
    yield put(registerError(err));
  }
}

export default function* loginSaga() {
  yield takeLatest(POST_LOGINPAGE, postLogin);
  yield takeLatest(POST_REGISTER, postRegister);
}
