import produce from 'immer';
import {
  GET_INVOICES,
  INVOICES_LOADING,
  GET_INVOICES_PRODUCTS,
  SET_ERROR,
} from '../const';

export const initialState = {
  invoices: [],
  invoicesProducts: [],
  isGetInvoices: false,
  isGetInvoicesProducts: false,
  loading: false,
  error: false,
};

const profileInvoiceReducer = (state = initialState, action) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case GET_INVOICES:
        if (Array.isArray(action.payload)) {
          draft.invoices = action.payload.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          draft.isGetInvoices = true;
        }
        break;
      case INVOICES_LOADING:
        draft.loading = action.payload;
        break;
      case GET_INVOICES_PRODUCTS:
        if (Array.isArray(action.payload)) {
          draft.invoicesProducts = action.payload.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          draft.isGetInvoicesProducts = true;
        }
        break;
      case SET_ERROR:
        draft.error = true;
        break;
      default:
        return state;
    }
  });

export default profileInvoiceReducer;
