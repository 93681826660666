import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Row, Button, Modal, Col, Container, Form } from 'react-bootstrap';
import Footer from '../../components/Footer';
import Breadcrumbs from '../../components/navigation/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import LocationSearchInput from '../../components/Autocomplete';
import { useSelector, RootStateOrAny } from 'react-redux';
import getLanguage from '../../utils/getLanguage';
import {
  regions,
  EMAIL_REGEXP,
  buttonColor,
  buttonHeader,
  buttonHeaderActive,
  errorText,
  notificationStyle,
  typeOfPerson,
} from './config';

type Props = {
  saveData: Function;
  onLogin: Function;
  onGoogleLogin: Function;
};

const Register = (props: Props) => {
  const { saveData, onLogin, onGoogleLogin } = props;

  const [modalShow, setModalShow] = useState<boolean>(true);
  const [showLogin, setShowLogin] = useState<boolean>(true);
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const [goodRegister, setGoodRegister] = useState<boolean>(false);

  function ModalOffice(props) {
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [phone, setPhone] = useState<string>('+38');
    const [email, setEmail] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [type, setType] = useState<string>('Частное лицо');
    const [company, setCompany] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [secondPassword, setSecondPassword] = useState<string>('');
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isSaved, setisPasswordSaved] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [login, setLogin] = useState<string>('');
    const [loginPwd, setLoginPassword] = useState<string>('');
    const [errorLogin, setErrorLogin] = useState<string>('');
    const [validate, setValidate] = useState<boolean>(false);

    const history = useHistory();
    const googleId =
      process.env.REACT_APP_GOOGLE_ID !== undefined
        ? process.env.REACT_APP_GOOGLE_ID
        : '';

    const notificationData = useSelector(
      (state: RootStateOrAny) => state.register.notificationData
    );
    const successLogged = useSelector(
      (state: RootStateOrAny) => state.register.successLogged
    );
    const isErrorLogin = useSelector(
      (state: RootStateOrAny) => state.register.isErrorLogin
    );
    const loginErrorMessage = useSelector(
      (state: RootStateOrAny) => state.register.loginErrorMessage
    );

    useEffect(() => {
      if (successLogged) {
        history.push('/cabinet/profile');
      }
    }, [successLogged]);

    useEffect(() => {
      if (isErrorLogin) {
        setErrorLogin(loginErrorMessage);
      }
    }, [isErrorLogin]);

    useEffect(() => {
      if (localStorage.getItem('login')) {
        setLogin(localStorage.getItem('login') as string);
      }
      if (localStorage.getItem('pwd')) {
        setLoginPassword(localStorage.getItem('pwd') as string);
      }
    }, []);

    const typeChanged = (e) => setType(e.target.value);

    const regionChanged = (e) => setRegion(e.target.value);

    const handleClose = () => history.push('/');

    const callback = () => console.info('Done');

    const verifyCallback = (response) => console.log(response);

    const setAddress = (value) => setCity(value);

    const setChecked = (evt) => setIsChecked(evt.target.checked);

    const setSavePassword = (evt) => setisPasswordSaved(evt.target.checked);

    function saveRegister() {
      let retVal = {
        id: '00000000-0000-0000-0000-000000000000',
        Name: name,
        Surname: surname,
        PhoneNumber: phone,
        Email: email,
        Region: region,
        Address: city,
        CustomerType: type,
        CompanyName: company,
        Password: password,
        ConfirmPassword: secondPassword,
      };

      if (
        name.length > 0 &&
        surname.length > 0 &&
        phone.length > 0 &&
        email.length > 0 &&
        password.length > 0 &&
        region.length > 0
      ) {
        if (!email.match(EMAIL_REGEXP)) {
          setError('Е-мейл не валидый');
        } else if (!validate) {
          setError('Адресс не валидый');
        } else if (password === secondPassword) {
          saveData(retVal);
          setShowRegister(false);
          setGoodRegister(true);
        } else {
          setError('Ошибка : пароли не совпадают');
        }
      } else {
        setError('Ошибка : заполните все обязательные поля!');
      }
    }

    const saveLogin = () => {
      if (login.length > 0 && loginPwd.length > 0) {
        if (isSaved) {
          localStorage.setItem('login', login);
          localStorage.setItem('pwd', loginPwd);
        }
        const data = { Login: login, Password: loginPwd };
        onLogin(data);
      } else {
        setErrorLogin('Ошибка : заполните все поля!');
      }
    };

    const googleAuth = async (data: any) => {
      onGoogleLogin(data);
      // const API_LOGIN = 'https://dev.webapi.s-car.com.ua/api/user/authenticate';
      // const { credential } = data;
      // const options = {
      //   method: 'POST',
      //   body: JSON.stringify({ idToken: credential }),
      //   headers: {
      //     accept: 'application/json',
      //     'Accept-Language': getLanguage().toLowerCase(),
      //     'Content-Type': 'application/json',
      //   },
      // };

      // const response = await fetch(API_LOGIN, options);
      // console.log('response', response);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        onEntered={function () {}}
        onExit={function () {
          history.push('/');
        }}
        onExiting={function () {
          console.log('onExiting ');
        }}
        onExited={function () {
          console.log('onExited  ');
        }}
        onEnter={() => console.log('onEnter   ')}
        onEntering={function () {
          console.log('onEntering');
        }}
        onHide={handleClose}
        centered
      >
        <Modal.Header
          style={goodRegister ? { display: 'none' } : { padding: '0px' }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <Row style={{ marginLeft: '-0.3px' }}>
              <Col
                style={showLogin ? buttonHeaderActive : buttonHeader}
                className="text-center"
                onClick={() => {
                  setShowLogin(true);
                  setShowRegister(false);
                }}
              >
                <p style={showLogin ? { color: 'white' } : { color: 'black' }}>
                  Логин
                </p>
              </Col>
              <Col
                style={showRegister ? buttonHeaderActive : buttonHeader}
                className="text-center"
                onClick={() => {
                  setShowLogin(false);
                  setShowRegister(true);
                }}
              >
                <p
                  style={showRegister ? { color: 'white' } : { color: 'black' }}
                >
                  Регистрация
                </p>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showLogin && (
            <>
              <Row className={'mb-3'}>
                <Col xs="12">
                  <p>Введите логин и пароль</p>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Е-мейл/телефон"
                    value={login}
                    onInput={(e) => setLogin(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xs="12">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Пароль"
                    value={loginPwd}
                    onInput={(e) => setLoginPassword(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className={'mt-2'}>
                <Col xs={'12'}>
                  <span style={errorText}>{errorLogin}</span>
                  <span style={notificationStyle}>{notificationData}</span>
                </Col>
              </Row>
              <Row className={'mt-2'}>
                <Col className={'col-6 pt-2'}>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Запомнить меня"
                      checked={isSaved}
                      onChange={(evt) => setSavePassword(evt)}
                    />
                  </Form.Group>
                </Col>
                <Col className={'col-6 text-right'}>
                  <button
                    type="button"
                    className="btn btn-outline-danger border border-white"
                  >
                    Забыли пароль
                  </button>
                </Col>
              </Row>
            </>
          )}
          {goodRegister ? (
            <Row>
              <Col xs={'12'}>
                <span style={notificationStyle}>{notificationData}</span>
              </Col>
            </Row>
          ) : null}
          {showRegister && (
            <>
              <Row>
                <Col xs="12">
                  Подайте на регистрацию, наши менеджеры свяжутся с вами в
                  течение дня
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Имя"
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Фамилия"
                    value={surname}
                    onInput={(e) => setSurname(e.target.value)}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Телефон"
                    value={phone}
                    onInput={(e) => setPhone(e.target.value)}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Е-мейл"
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Введите пароль еще раз"
                    value={secondPassword}
                    onInput={(e) => setSecondPassword(e.target.value)}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    as="select"
                    custom
                    onChange={regionChanged}
                    value={region}
                    style={{ marginBottom: '10px' }}
                  >
                    {regions.map((region, index) => {
                      return (
                        <option key={index} value={region.name}>
                          {region.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-1">
                <Col xs="11" className="pr-0">
                  <LocationSearchInput
                    placeholder="Введите адрес"
                    setMap={() => {}}
                    setValidate={setValidate}
                    setAddress={setAddress}
                    language={'ru'}
                  />
                </Col>
                <Col xs="1" style={{ color: 'red' }}>
                  *
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    as="select"
                    custom
                    onChange={typeChanged}
                    value={type}
                    style={{ marginBottom: '10px' }}
                  >
                    {typeOfPerson.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col xs="11" className="pr-0">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Компания"
                    value={company}
                    onInput={(e) => setCompany(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="11" className="pr-0">
                  <Recaptcha
                    sitekey="6LeTgGYaAAAAAKsaxRxwqsIliSYFbddJfE9UqrGi"
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={callback}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="1" style={{ color: 'red', paddingRight: '0px' }}>
                  <span>*</span>
                </Col>
                <Col xs="11" className={'pl-0'}>
                  Обязательные данные при регистрации
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="12">
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Согласен на обработку личных данных"
                      onChange={(evt) => setChecked(evt)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className={'mt-2'}>
                <Col xs={'12'}>
                  <span style={errorText}>{error}</span>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {showLogin && (
            <>
              <GoogleOAuthProvider clientId={googleId}>
                <Button style={buttonColor} block onClick={() => saveLogin()}>
                  Войти в кабинет
                </Button>
                <GoogleLogin
                  onSuccess={(credentialResponse: object) => {
                    console.log(credentialResponse);
                    googleAuth(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </>
          )}
          {showRegister && (
            <>
              <Button
                disabled={!isChecked}
                style={buttonColor}
                block
                onClick={() => saveRegister()}
              >
                Подать заявку
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      <Breadcrumbs />
      <Container>
        <ModalOffice show={modalShow} onHide={() => setModalShow(false)} />
      </Container>
      <Footer />
    </div>
  );
};

export default Register;
/**
 6LeTgGYaAAAAAKsaxRxwqsIliSYFbddJfE9UqrGi

 6LeTgGYaAAAAACgzGc02HQtuhltG4I09-cGx2v5Z
 *
 */
