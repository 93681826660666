import React from "react";
import {
  Row,
  Col,
  Container,
} from "react-bootstrap";
// npm install use-state-if-mounted
// importing Contact components styles
import "../styles/mainpage/contacts.scss";

// importing Map image (instead of iframe for now) !!!

// setting Contact parameters
type Props = {
  item: any;
  onChanged: Function;
  onEdited: Function;
  onDelete: Function;
};
//const target = ["All", "Regions", "Offices", "Managers"];

function NewsComponent(props: Props) {
  const { item, onDelete, onEdited } = props;
  console.log(JSON.stringify(item));
  return (
    <Container className="margin-bottom-50">
      <Row className="justify-content-left">
        <Col md={{ span: 4 }}>
          <span style={{ color: "red" }} onClick={() => onEdited(item.id)}>
            EDIT
          </span>
          <span
            style={{ color: "red", marginLeft: 10 }}
            onClick={() => onDelete(item.id)}
          >
            DELETE
          </span>
        </Col>
      </Row>
      <Row className="ContactsBlock">
        <Col xs={12} lg={true}>
          <Row>
            <p className=" margin-bottom-20">
              {new Date(item.date).toLocaleDateString()}:{item.name}
            </p>
          </Row>
          <Row>
            <p className="ContactPhone margin-bottom-20">{item.annotation}</p>
          </Row>

        </Col>
      </Row>
      <div style={{ height: 2, backgroundColor: "black", width: "100%" }} />
    </Container>
  );
}

export default NewsComponent;
