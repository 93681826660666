export const ADD_TO_CART = 'Cart/ADD_TO_CART';
export const REMOVE_ITEM_CART = 'Cart/REMOVE_ITEM_CART';
export const COUNT_TO_CART = 'Cart/COUNT_TO_CART';
export const COMMENT_TO_CART = 'Cart/COMMENT_TO_CART';
export const GABARITE_TO_CART = 'Cart/GABARITE_TO_CART';
export const GET_CART = 'Cart/GET_CART';
export const ADD = 'Cart/ADD';
export const REMOVE = 'Cart/REMOVE';
export const GET = 'Cart/GET';
export const UPDATE = 'Cart/UPDATE';
export const CART_FETCH_FAIL = 'Cart/CART_FETCH_FAIL';
export const CART_CATCH_FAIL = 'Cart/CART_CATCH_FAIL';
export const CART_LOADING = 'Cart/CART_LOADING';
export const CART_ITEM_CHECKED = 'Cart/CART_ITEM_CHECKED';
export const TO_STEP = 'Cart/TO_STEP';
export const ORDER = 'Cart/ORDER';
export const SUCCESS = 'Cart/SUCCESS';
export const ADD_TO_CART_MESS = 'Cart/ADD_TO_CART_MESS';
