import produce from 'immer';
import { GET_ORDERS, ORDERS_LOADING, GET_ORDERS_PRODUCTS, SET_ERROR } from '../const';

export const initialState = {
  orders: [],
  ordersProducts: [],
  isGetOrders: false,
  isGetOrdersProducts: false,
  loading: false,
  error: false,
};

const profileOrdersReducer = (state = initialState, action) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case GET_ORDERS:
        if (Array.isArray(action.payload)) {
          draft.orders = action.payload.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          draft.isGetOrders = true;
        }
        break;
      case ORDERS_LOADING:
        draft.loading = action.payload;
        break;
      case GET_ORDERS_PRODUCTS:
        if (Array.isArray(action.payload)) {
          draft.ordersProducts = action.payload.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          draft.isGetOrdersProducts = true;
        }
        break;
      case SET_ERROR:
        draft.error = true;
        break;
      default:
        return state;
    }
  });

export default profileOrdersReducer;
