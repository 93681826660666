import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import CartItems from './cartItems';
import CartOrder from './cartOrder';
import { sagaCartRemove, toStep, sagaCartGet } from './actions';
import Footer from '../../components/Footer';
import '../../styles/cart/cart.scss';
import declension from '../../utils/declension';

interface DefaultRootState {
  cart: {
    products: [any];
    sum: { sum: number; isTransport: boolean; isGabarite: boolean };
    selectedSum: { sum: number; isTransport: boolean; isGabarite: boolean };
    error: any;
    loading: boolean;
    selectedProducts: [];
    step: number;
  };
}

const selectedData = (products, selected) => {
  let count = 0;
  let gabarite = 1;

  // eslint-disable-next-line
  products.map((prod: any) => {
    if (selected.includes(prod.id)) {
      count += Number(prod.count);
      if (prod.gabarite) {
        gabarite = 2;
      }
    }
  });

  const isGabarite = {
    1: 'Товары с отметками Габарит - отсутствуют.',
    2: 'Есть товары с отметками Габарит.',
  };
  return { count, gabarite: isGabarite[gabarite] };
};

function Cart() {
  const cart = useSelector((state: DefaultRootState) => state.cart);
  const dispatch = useDispatch();
  const [wait, setWait] = useState('');

  const { count, gabarite } = selectedData(
    cart.products,
    cart.selectedProducts
  );

  useEffect(() => {
    dispatch(sagaCartGet());

    setTimeout(() => {
      setWait('В корзине пусто');
    }, 1000);
  }, []);

  return (
    <>
      <Container fluid>
        {cart.products.length ? (
          <>
            <div
              className={
                cart.step === 1 ? 'cart-header pb-4 sticky' : 'cart-header'
              }
            >
              <div>
                <h2
                  className={
                    cart.step === 1 ? 'cart-title active' : 'cart-title'
                  }
                >
                  {cart.step === 1 ? (
                    'В вашей корзине'
                  ) : (
                    <span
                      className="to-cart"
                      onClick={() => dispatch(toStep(1))}
                    >
                      В корзину
                    </span>
                  )}
                </h2>
                {cart.step === 1 && (
                  <>
                    <h3 className="mt-2">
                      Сумма корзины:{' '}
                      {cart.sum?.isTransport && !cart.sum?.isGabarite ? (
                        <span>{cart.sum?.sum}</span>
                      ) : (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              В стоимости товаров не учтены все транспортные
                              затраты
                            </Tooltip>
                          }
                        >
                          <div className="table-popover d-inline-block">
                            <span>
                              ({cart.sum?.sum})
                              <span className="c-main-red">!</span>
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </h3>
                    <h3>
                      В заказ:{' '}
                      {cart.selectedSum?.isTransport &&
                      !cart.selectedSum?.isGabarite ? (
                        <span>{cart.selectedSum?.sum}</span>
                      ) : (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              В стоимости товаров не учтены все транспортные
                              затраты
                            </Tooltip>
                          }
                        >
                          <div className="table-popover d-inline-block">
                            <span>
                              ({cart.selectedSum?.sum})
                              <span className="c-main-red">!</span>
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </h3>
                  </>
                )}
              </div>
              <div>
                <h2
                  className={
                    cart.step === 2 ? 'order-title active' : 'order-title'
                  }
                >
                  Оформление заказа
                </h2>
              </div>
              {cart.step === 1 ? (
                <div className="cart-header_btns">
                  <Button
                    className="btn-second"
                    disabled={!cart.selectedProducts.length}
                    onClick={() => dispatch(toStep(2))}
                  >
                    <h3>Заказать</h3>
                  </Button>
                  <Button
                    disabled={!cart.selectedProducts.length}
                    className="btn-main ml-3 mr-3"
                    onClick={() =>
                      dispatch(sagaCartRemove(cart.selectedProducts))
                    }
                  >
                    Очистить
                  </Button>
                  {cart.loading && (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ width: '3rem', height: '3rem' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="prod-descr mb-3">
                  {' '}
                  <h2>
                    {' '}
                    К оплате: {cart.selectedSum.sum}{' '}
                    {cart.products[0]?.currency}
                  </h2>
                  <h4 className="mt-1">
                    В заказе {cart.selectedProducts.length}{' '}
                    {declension(
                      'товар',
                      'товаров',
                      'товара',
                      cart.selectedProducts.length
                    )}{' '}
                    в количестве {count} шт.
                  </h4>
                  <h4>{gabarite}</h4>
                </div>
              )}
            </div>
            {cart.step === 1 && <CartItems />}
            {cart.step === 2 && <CartOrder />}
          </>
        ) : (
          <h2 className="cart-title">{wait}</h2>
        )}
      </Container>
      {cart.error && <div className="server-error">Ошибка {cart.error}</div>}
      <Footer />
    </>
  );
}

export default Cart;
