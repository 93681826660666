import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchHook = () => {
  const [info, setInfo] = useState<any>();
  const [state, setState] = useState({
    isPressedShowHistory: false,
  });
  const [valueSearch, setValueSearch] = useState<string>('');
  const [historyArray, setHistoryArray] = useState<any>([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idQuery: string | null = queryParams.get('id');

  useEffect(() => {
    const array = localStorage.getItem('historyOfSearch');
    if (array != null) {
      setHistoryArray(JSON.parse(array));
    }
  }, [idQuery]);

  const toggleHistory = () => {
    setState({
      isPressedShowHistory: !state.isPressedShowHistory,
    });
  };

  const handleChange = (value: string) => {
    setValueSearch(value);
    setInfo(value);
  };

  const handleChooseOneFromHistory = (item: string) => {
    setValueSearch(item);
    setInfo(item);
    setState({
      isPressedShowHistory: false,
    });
  };

  const handleEmptySearchHistory = () => {
    setState({
      isPressedShowHistory: false,
    });
  };

  return {
    handleChange,
    valueSearch,
    info,
    toggleHistory,
    state,
    historyArray,
    handleChooseOneFromHistory,
    handleEmptySearchHistory,
  };
};
