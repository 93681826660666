export const LoadingComponent = () => {
  return (
    <div className="text-center mt-5">
      <div
        className="spinner-border"
        role="status"
        style={{ width: '3rem', height: '3rem' }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
