import React from "react";
import Pagination from "react-bootstrap/Pagination";

type Props = {
  count: Array<number>;
  active: number;
  onSelect: Function;
};

function PaginationComponent(props: Props) {
  const { count, onSelect } = props;
  const pageChanged = e => {
    onSelect(e.target.text);
  };
  const pageFirst = () => {
    onSelect(1);
  };
  const pageLast = () => {
    onSelect(count.length);
  };

  return (
    <>
      <Pagination>
        <Pagination.First onClick={pageFirst} />

        {count.map((item, index) => {
          return (
            <Pagination.Item key={index} onClick={pageChanged}>
              {item}
            </Pagination.Item>
          );
        })}

        <Pagination.Last onClick={pageLast} />
      </Pagination>
    </>
  );
}

export default PaginationComponent;
// active
