import connect from "react-redux-connect-lifecycle";
import Page from "../Page";
//import { getRegisterSelector } from "../reducer";

const mapDispatchToProps = dispatch => ({
  onComponentDidMount: props => {
    // dispatch(getTargetRegion());
  },
  onComponentWillUnmount: props => {
    console.log("component register will unmount");
  },
});

//const mapStateToProps = state => getRegisterSelector(state);

export default connect(
  //  mapStateToProps,
  mapDispatchToProps
)(Page);

