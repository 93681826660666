import React from "react";
import { Row, Col } from "react-bootstrap";

// importing News components styles
import "../../styles/mainpage/news.scss";

// setting News parameters
type NewsProps = {
  newsTitle: string;
  newsContent: string;
  newsDate: string;
};

export const News = ({ newsTitle, newsContent, newsDate }: NewsProps) => (
  <>
    <Row>
      <Col className="news-headline">
        <h4> </h4>
        <h3 className="margin-bottom-5">
          <b>{new Date(newsDate).toLocaleDateString()}</b>:{newsTitle}{" "}
        </h3>
        <p>{newsContent}</p>
      </Col>
    </Row>
  </>
);

export default News;
