import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../../../components/Footer';
import CabinetNavigation from '../../../components/navigation/CabinetNavigation';
import { Switch, Route } from 'react-router-dom';
import MainCabinet from '../main_cabinet/container';
import Profile from '../profile/container';
import Orders from '../orders/Page';
import Invoices from '../invoices/Page';
import Refunds from '../refunds/Page';
import { ErrorPage } from '../profile/error';

const PersonalCabinet = () => {
  return (
    <div>
      {/*<Breadcrumbs />*/}
      <Container fluid>
        <Row className="justify-content-between">
          <Col xs={2} className="d-none d-lg-block border-right">
            <Row>
              <Col>
                <h3 className="mb-3 pl-3">Личный кабинет</h3>
                <CabinetNavigation />
              </Col>
            </Row>
          </Col>
          <Col lg={10} md={12} className="mt-10">
            <Row className="justify-content-center justify-content-lg-end mt-10">
              <Switch>
                <Route
                  render={() => <MainCabinet page="cabinet" />}
                  path="/cabinet/main"
                  exact
                />
                <Route
                  render={() => <Profile page="profile" />}
                  path="/cabinet/profile"
                  exact
                />
                <Route render={() => <Orders />} path="/cabinet/orders" exact />
                <Route
                  render={() => <Invoices />}
                  path="/cabinet/invoices"
                  exact
                />
                <Route
                  render={() => <Refunds />}
                  path="/cabinet/refunds"
                  exact
                />
                <Route component={ErrorPage} path="*" />
              </Switch>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default PersonalCabinet;
