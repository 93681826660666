import Form from 'react-bootstrap/Form';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export default function GetSettlements({ setNpCityRef }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [rawCities, setRawCities] = useState([]);

  const cities = useCitySearch(searchTerm);

  useEffect(() => {
    if (cities.length > 0) {
      setRawCities(cities);
    }
  }, [cities]);

  const delayedQuery = debounce((e) => setSearchTerm(e), 1000);

  const handleSearchTermChange = (e) => {
    if (e.target.value.trim().length > 2) {
      delayedQuery(e.target.value);
    }
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="custom-label">Город</Form.Label>
      <Combobox
        aria-label="Cities"
        onSelect={(name) => {
          setNpCityRef(rawCities.filter((item: any) => item.Present === name));
        }}
      >
        <ComboboxInput
          className="city-search-input"
          onChange={handleSearchTermChange}
          placeholder="Три символа для поиска"
        />
        {cities && (
          <ComboboxPopover className="shadow-popup">
            {cities.length > 0 ? (
              <ComboboxList>
                {cities.map((city: any, ind) => {
                  return <ComboboxOption key={ind} value={city?.Present} />;
                })}
              </ComboboxList>
            ) : (
              <span style={{ display: 'block', margin: 8 }}>
                Уточните запрос
              </span>
            )}
          </ComboboxPopover>
        )}
      </Combobox>
    </Form.Group>
  );
}

function useCitySearch(searchTerm) {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      fetchCities(searchTerm).then((cities) => {
        setCities(
          cities?.data[0]?.TotalCount > 0 ? cities.data[0].Addresses : []
        );
      });
    }
  }, [searchTerm]);

  return cities;
}

function fetchCities(value) {
  const requestURL = 'https://api.novaposhta.ua/v2.0/json/';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      apiKey: '2b832a405d1a044db2a379c4530f2730',
      modelName: 'Address',
      calledMethod: 'searchSettlements',
      methodProperties: {
        CityName: value,
        Limit: '500',
        Page: '1',
      },
    }),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(requestURL, options)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
