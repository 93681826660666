// @flow
//@ts-nocheck
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import rootReducer from '../reducers';
//import loggingMiddleware from './middleware/logging';
import rootSaga from '../sagas';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState: Object) => {
  const middleWares = composeWithDevTools(
    applyMiddleware(thunk, sagaMiddleware, logger)
  );
  // const store = createStore(persistedReducer, initialState, middleWares);
  const store = createStore(rootReducer, initialState, middleWares);
  // let persistor = persistStore(store, { manualPersist: true });
  sagaMiddleware.run(rootSaga);
  // return { store, persistor };
  return store;
};

export default configureStore;
