import { useEffect, useState } from 'react';
import { profileService } from './services';
import '../../../styles/cabinet/profile.scss';
import { PageItem } from './PageItem';

const Profile = () => {
  const [profile, setProfile] = useState<any>();
  const [currency, setCurrency] = useState<object>({});

  const handleFetchData = async () => {
    const { data } = await profileService.getInfoAboutUser();
    const response = await profileService.getInfoCurrency();
    setCurrency(response);
    console.log(data);
    console.log(response);
    setProfile(data);
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="profilePage">
      {profile && <PageItem profile={profile} currency={currency} />}
    </div>
  );
};

export default Profile;
