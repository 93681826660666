export const GET_PAGE = "Page/GET_";
export const GET_ADMIN_NEWS = "Page/GET_ADMIN_NEWS";
export const GET_NEWS_SUCCESS = "Page/GET_ADMINNEWS_SUCCESS";
export const GET_NEWS_EDITED = "Page/GET_ADMINNEWS_EDITED";
export const GET_NEWS_EDITEDCLEAN = "Page/GET_ADMINNEWS_EDITED_CLEAN";
export const GET_NEWS_ERROR = "Page/GET_NEWS_ERROR";
export const GET_TARGET_SUCCESS = "Page/GET_TARGET_SUCCESS";
export const GET_TARGETOF_SUCCESS = "Page/GET_TARGETOF_SUCCESS";
export const GET_TARGET_ERROR = "Page/GET_TARGET_ERROR";
export const POST_NEWS = "Page/POST_NEWS";
export const DELETE_NEWS = "Page/DELETE_NEWS_ADMIN";
export const EDIT_NEWS = "Page/EDIT_NEWS_ADMIN";
export const PUT_PAGE = "Page/PUT_NEWS";
export const GET_SUCCESS = "Page/GET_SUCCESS_";
export const GET_ERROR = "Page/GET_ERROR_";
export const FETCH_CONTACTS = "PAGES/FETCH_CONTACTS";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_TARGET_REGION = "GET_REGION";
export const GET_TARGET_OFFICES = "GET_OFFICES";
export const GET_LANGUAGE = "PAGES/GET_LANGUAGES";
export const LANGUAGE = "GET_LANGUAGES";
