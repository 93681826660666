import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { sendFormOrdersProductSaga } from '../actions';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

type Props = {
  show: boolean;
  setShow: Function;
  modalData: {
    cOrder: string;
    orderNumber: string;
    name: string;
    maker: string;
  };
};

const ModalOrderProductForm = (props: Props) => {
  const { show, setShow, modalData } = props;
  const [comment, setComment] = useState('');
  const [radioValue, setRadioValue] = useState('');

  const dispatch = useDispatch();

  const radios = [
    { name: 'Отказаться от товара', value: '1', type: 'reject' },
    { name: 'Перезаказать товар', value: '2', type: 'reorder' },
    { name: 'Не перезаказывать товар', value: '3', type: 'notreorder' },
    { name: 'Задать вопрос менеджеру', value: '4', type: 'askquestion' },
  ];

  const send = () => {
    const data = {
      text: comment,
      cOrder: modalData.cOrder,
      orderNumber: modalData.orderNumber,
      type: radios[+radioValue - 1].type,
    };
    setComment('');
    setRadioValue('');
    console.log('send', data);
    dispatch(sendFormOrdersProductSaga(data));
    setShow(false);
  };

  const close = () => {
    setComment('');
    setRadioValue('');
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="info-modal product-options"
    >
      <Modal.Header closeButton>
        <Modal.Title className="info-modal_header">
          {radioValue ? radios[+radioValue - 1].name : 'Управление заказом'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
              className="radio-group-btn"
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <p className="mb-2">
          {modalData.name} ({modalData.maker})
        </p>
        <Form.Control
          className="comment"
          as="textarea"
          rows={3}
          placeholder="Комментарий..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-second" onClick={send} disabled={!radioValue}>
          Отправить
        </Button>
        <Button onClick={close} className="btn-main">
          Отменить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOrderProductForm;
