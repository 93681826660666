import { useEffect, useState } from 'react';

// importing Mainpage components
import Footer from '../../components/Footer';
import MapOffices from '../../components/map/map';
import Breadcrumbs from '../../components/navigation/Breadcrumbs';
import Offices from '../../components/contactspage/offices';
import getMainOffice from '../../utils/findmainoffice';

import {
  Row,
  Button,
  Modal,
  Col,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

import '../../styles/contacts/map.scss';
import Marker from '../../components/map/marker';
import LocationSearchInput from '../../components/Autocomplete';

import getToken from '../../utils/tokenchecker';
// https://maps.googleapis.com/maps/api/js?key=AIzaSyDzOjrwcI1rI0qh3SbWNVWQ0Yf-Lxu9ezE&libraries=places
import { useDispatch } from 'react-redux';

const buttonColor = {
  backgroundColor: '#962932',
  color: 'white',
  border: 'none',
  outline: 'none',
  button: 'focus {outline:0;}',
};

const mainOffice = {
  show: 'markerinfo active',
  city: 'г. Харьков (головной офис)',
  officeAdress: 'г. Харьков, пр-т Гагарина 201',
  latitude: 49.9316469,
  longitude: 36.26492,
  comments: null,
  contact: { name: '+38-098-800-800-1' },
  contacts: [
    {
      id: '26',
      type: 'Kyivstar',
      name: '+38-098-800-800-1',
      prefered: true,
    },
    {
      id: '27',
      type: 'Vodafone',
      name: '+38-050-557-03-37',
      prefered: false,
    },
    {
      id: '28',
      type: 'Email',
      name: 'alexander.rudakov@s-car.com.ua',
      prefered: false,
    },
  ],
};

function parseOffices(offices) {
  let retVal = [] as any;
  let confArr = [] as any;
  for (var i = 0; i < offices.length; i++) {
    if (i % 2 === 0) {
      confArr = [];
      confArr.push(offices[i]);
      retVal.push(confArr);
    } else {
      retVal[retVal.length - 1].push(offices[i]);
    }
  }
  return retVal;
}
const localizations = [
  {
    language: 'en',
  },
  {
    language: 'ru',
  },
  {
    language: 'uk',
  },
];
const localizationsModif = [
  {
    language: 'en',
    name: '',
    address: '',
    comments: null,
  },
  {
    language: 'ru',
    name: '',
    address: '',
    comments: null,
  },
  {
    language: 'uk',
    name: '',
    address: '',
    comments: null,
  },
];
function ModalOffice(props) {
  const contact = {
    id: null,
    type: { id: 'bd100d56-cc52-4286-8e6c-01e95a7648de', name: 'Kyivstar' },
    name: '',
    prefered: true,
  };

  const [center] = useState({ lat: 50.5, lng: 30.4 });
  const [position, setPosition] = useState({ lat: 50.5, lng: 30.4 });
  const [types, setTypes] = useState<any[]>([]);
  const [changed, isChanged] = useState(false);

  const [contacts, addContacts] = useState<any[]>([]);
  const [edited, isEdited] = useState(false);

  const setTitle = (value, language) => {
    for (let i = 0; i < localizationsModif.length; i++) {
      if (localizationsModif[i].language === language) {
        localizationsModif[i].name = value;
      }
    }
  };
  const setAddress = (value, language) => {
    for (let i = 0; i < localizationsModif.length; i++) {
      if (localizationsModif[i].language === language) {
        localizationsModif[i].address = value;
      }
    }
  };

  function addContact(cont) {
    addContacts((contacts) => contacts.concat(cont));
  }
  let tokenObj = getToken();

  useEffect(() => {
    (async () => {
      fetch('https://letters.s-car.com.ua/api/v1/Common/contacts/types', {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'ru',
          Authorization: `Bearer ${tokenObj.token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setTypes(data);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    })();
  }, [tokenObj.token]);

  const toggleMarker = (data: any) => {};

  function addCont(index, value) {
    const newArr = [...contacts];
    newArr[index].name = value;
    addContacts(newArr);
  }
  function setMarkerOnMap(coord) {
    setPosition(coord);
    isChanged(true);
  }
  function removeContact(i) {
    contacts.splice(i, 1);
    addContacts(contacts);
    isEdited(!edited);
  }
  function changeType(index, type) {
    const newArr = [...contacts];
    newArr[index].type = type;
    addContacts(newArr);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить офис
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Офис</h4>
        <Row>
          <Col xs="8">
            <div style={{ width: 'auto', height: 300 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyDzOjrwcI1rI0qh3SbWNVWQ0Yf-Lxu9ezE',
                }}
                defaultCenter={center}
                draggable={true}
                defaultZoom={5}
              >
                {changed === true && (
                  <Marker
                    lat={position.lat as number}
                    lng={position.lng as number}
                    color="blue"
                    isDraggable={true}
                    // onDragend={onMarkerDragEnd}
                    onClick={() => {
                      toggleMarker({
                        show: 'markerinfo active',
                      });
                    }}
                  />
                )}
              </GoogleMapReact>
            </div>
          </Col>
          {localizations.map((loc, index) => {
            return (
              <Col xs="4">
                <Form.Group>
                  <Form.Label>Название офиса</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Название"
                    onInput={(e) => setTitle(e.target.value, loc.language)}
                  />
                </Form.Group>

                <InputGroup className="mb-3">
                  <Form.Label>Адрес</Form.Label>
                  <LocationSearchInput
                    setMap={setMarkerOnMap}
                    setAddress={setAddress}
                    language={loc.language}
                  />
                </InputGroup>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Button variant="primary" onClick={() => addContact(contact)}>
            Добавить контакт
          </Button>
        </Row>

        {contacts.map((cont, index) => {
          return (
            <>
              <Row key={index}>
                <InputGroup>
                  <Form.Control as="select">
                    {types.map((type, index2) => {
                      return (
                        <option
                          key={index2}
                          value={type.id}
                          onClick={() => changeType(index, type)}
                        >
                          {' '}
                          {type.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <FormControl
                    placeholder="введите данные"
                    aria-label="Контакт"
                    aria-describedby="basic-addon2"
                    onChange={(e) => addCont(index, e.target.value)}
                  />
                  <InputGroup.Prepend>
                    <Button
                      variant="outline-secondary"
                      onClick={() => removeContact(index)}
                    >
                      Удалить
                    </Button>
                  </InputGroup.Prepend>
                </InputGroup>
              </Row>
            </>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onSave(
              localizationsModif[0].name,
              position,
              'address',
              contacts
            );
          }}
        >
          Сохранить
        </Button>
        <Button onClick={props.onHide}>Закрыть</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ContactsPage() {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [state, setState] = useState({
    data: mainOffice,
    isPressedMarker: true,
  });
  const [officesmap, setOficcesMap] = useState([]);
  const [offices, setOficces] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [reload, setReload] = useState(false);
  function onSaveData(title, position, address, contacts) {
    for (let i = 0; i < contacts.length; i++) {
      let typeEdited = contacts[i].type.id;
      contacts[i].typeId = typeEdited;
      contacts[i].type = null;
      if (i > 0) {
        contacts[i].prefered = false;
      }
    }

    let office = {
      title: title,
      position: position,
      address: address,
      contacts: contacts,
      localizations: localizationsModif,
    };

    dispatch({ type: 'ADDOFFICE', office });
    contacts = [];
    setModalShow(false);
    setReload(!reload);
    //window.location.reload();
  }

  useEffect(() => {
    if (localStorage.getItem('editmode') === 'true') {
      setShowEditor(true);
    } else setShowEditor(false);
  }, []);
  useEffect(() => {
    (async () => {
      let mip = await getMainOffice();

      fetch(
        'https://letters.s-car.com.ua/api/v1/Company/contacts?ip=' + mip.ip,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'ru',
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setState({ data: data, isPressedMarker: true });
        })
        .catch(function (error) {
          setState({ data: mainOffice, isPressedMarker: true });
        });

      fetch('https://letters.s-car.com.ua/api/v1/Company/view/offices', {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'ru',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data1) => {
          setOficces(parseOffices(data1));
          setOficcesMap(data1);
        })
        .catch(function (error) {
          setState({ data: mainOffice, isPressedMarker: true });
        });
    })();
  }, [reload]);

  return (
    <div>
      <Breadcrumbs />
      <ModalOffice
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSave={onSaveData}
      />
      <MapOffices offices={officesmap} />
      {showEditor && (
        <Row className="justify-content-md-center">
          {' '}
          <Col xs lg="2">
            <Button style={buttonColor} onClick={() => setModalShow(true)}>
              Add Office
            </Button>
          </Col>
        </Row>
      )}
      <Offices offices={offices} />
      <Footer />
    </div>
  );
}

export default ContactsPage;
