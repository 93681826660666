import {
  POST_REGISTER,
  REGISTER_SUCCESS,
  LOGIN,
  GLOGIN,
  LOGIN_SUCCESS,
  REGISTER_ERROR,
  LOGIN_ERROR,
} from '../const';

export function saveData(data: any) {
  return {
    type: POST_REGISTER,
    payload: data,
  };
}

export function login(data: any) {
  return {
    type: LOGIN,
    payload: data,
  };
}

export function googleLogin(data: any) {
  return {
    type: GLOGIN,
    payload: data,
  };
}

export function registerSuccess(data: any) {
  return {
    type: REGISTER_SUCCESS,
    data,
  };
}

export function loginSuccess(data: any) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}

export function registerError(data: any) {
  return {
    type: REGISTER_ERROR,
    data,
  };
}

export function loginError(data: string) {
  return {
    type: LOGIN_ERROR,
    payload: data,
  };
}
