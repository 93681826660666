import {
  GET_PAGE,
  GET_SUCCESS,
  PUT_PAGE,
  FETCH_CONTACTS,
  LANGUAGE,
  GET_ERROR
} from "../const";

export function getPageSuccess(page: string, value: any) {
  return {
    type: GET_SUCCESS + page,
    value
  };
}

export function getPage(id: string, showEditor: boolean) {
  return {
    type: GET_PAGE,
    id,
    showEditor
  };
}

export function savePage(content: any) {
  return {
    type: PUT_PAGE,
    content
  };
}

export function getContactsSuccess(contact: any) {
  return {
    type: FETCH_CONTACTS,
    contact
  };
}

export function getLanguageSuccess(language: any) {
  return {
    type: LANGUAGE,
    language
  };
}

export function getPageError(page: string, status: string, value: any) {
  return {
    type: GET_ERROR + page,
    status,
    value
  };
}
