import { call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
//import getToken from "../../../utils/tokenchecker";
import {
  registerSuccess,
  loginSuccess,
  registerError,
  loginError,
} from '../actions';
import { sagaCartGet } from '../../cart/actions';
import { POST_REGISTER, LOGIN, GLOGIN } from '../const';
import { API_REGISTER, API_LOGIN, API_GLOGIN } from '../../../utils/settings';

import getLanguage from '../../../utils/getLanguage';

export function* registerUser(action) {
  const data = action.payload;
  const requestURL = API_REGISTER;

  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
    },
  };
  try {
    const log = yield call(request, requestURL, options);
    yield put(registerSuccess(data));
    return log;
  } catch (err) {
    yield put(registerError(err));
  }
}

/**

 displayName  null
 token  null
 userName  null
 message  "user 123 not found..."
 isSuccess  false
 isUserNotFound  true
 isAccessDenied  false
 isAccountNotVerifiedByManager  false


 */

export function* login(action) {
  //
  // yield put(loginError(false))

  const data = action.payload;

  const requestURL = API_LOGIN;
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": 'GET,POST,OPTIONS',
      // "Access-Control-Allow-Headers": "DNT,User-Agent, X-Requested-With, If-Modified-Since, Cache-Control, Content-Type, Range",
      // "Access-Control-Max-Age": "1728000",
      // // accept: "application/json",
      // "Accept-Language": getLanguage().toLowerCase(),
      // "Content-Type": "application/json",
      // "Connection": "keep-alive",
      // "Host": "dev.webapi.s-car.com.ua",
      // "Origin": "https://dev2.s-car.com.ua",
      // "Referer": "https://dev2.s-car.com.ua/",
      // "Sec-Fetch-Dest": "empty",
      // "Sec-Fetch-Mode": "cors",
      // "Sec-Fetch-Site": "same-site"
    },
  };
  try {
    const log = yield call(request, requestURL, options);

    if (!log.isSuccess) {
      if (log.isUserNotFound) {
        yield put(loginError('Ошибка : Е-мейл или пароль не верный'));
        return;
      } else if (log.isAccountNotVerifiedByManager) {
        yield put(loginError('Менеджер не подтвердил заявку'));
        return;
      } else if (log.AccessDenied) {
        yield put(loginError('Доступ запрещен'));
        return;
      } else if (log.isAccountFailure) {
        yield put(loginError('Отказано в регистрации'));
        return;
      }
      // else {
      //   yield put(loginError('Ошибка : Е-мейл или пароль не верный'))
      //   return;
      // }
    }

    localStorage.setItem('token', log.token);
    localStorage.setItem('userId', log.userId);

    yield put(loginSuccess(data));
    yield put(sagaCartGet());
    return log;
  } catch (err) {
    yield put(registerError(err));
  }
}

export function* googleLogin(action) {
  //
  // yield put(loginError(false))

  const data = action.payload;

  const { credential } = action.payload;

  const requestURL = API_GLOGIN;
  const options = {
    method: 'POST',
    body: JSON.stringify({ idToken: credential }),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": 'GET,POST,OPTIONS',
      // "Access-Control-Allow-Headers": "DNT,User-Agent, X-Requested-With, If-Modified-Since, Cache-Control, Content-Type, Range",
      // "Access-Control-Max-Age": "1728000",
      // // accept: "application/json",
      // "Accept-Language": getLanguage().toLowerCase(),
      // "Content-Type": "application/json",
      // "Connection": "keep-alive",
      // "Host": "dev.webapi.s-car.com.ua",
      // "Origin": "https://dev2.s-car.com.ua",
      // "Referer": "https://dev2.s-car.com.ua/",
      // "Sec-Fetch-Dest": "empty",
      // "Sec-Fetch-Mode": "cors",
      // "Sec-Fetch-Site": "same-site"
    },
  };
  try {
    const log = yield call(request, requestURL, options);

    if (!log.isSuccess) {
      if (log.isUserNotFound) {
        yield put(loginError('Ошибка : Е-мейл или пароль не верный'));
        return;
      } else if (log.isAccountNotVerifiedByManager) {
        yield put(loginError('Менеджер не подтвердил заявку'));
        return;
      } else if (log.AccessDenied) {
        yield put(loginError('Доступ запрещен'));
        return;
      } else if (log.isAccountFailure) {
        yield put(loginError('Отказано в регистрации'));
        return;
      }
      // else {
      //   yield put(loginError('Ошибка : Е-мейл или пароль не верный'))
      //   return;
      // }
    }

    localStorage.setItem('token', log.token);
    localStorage.setItem('userId', log.userId);

    yield put(loginSuccess(data));
    yield put(sagaCartGet());
    return log;
  } catch (err) {
    yield put(registerError(err));
  }
}

export default function* aboutSaga() {
  yield takeLatest(POST_REGISTER, registerUser);
  yield takeLatest(LOGIN, login);
  yield takeLatest(GLOGIN, googleLogin);
}
