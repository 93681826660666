import React from "react";
import { Row, Col, Container } from "react-bootstrap";
// importing News components styles
import "../../styles/mainpage/news.scss";
// setting News parameters
type BillProps = {
  data: Array<string>;
  indexTable: number;
};

export const Bill = ({ data, indexTable }: BillProps) => (
  <Container>
    <Row style={{ height: 30 }}>
      {data.map((item, index) => {
        return (
          <Col key={index} style={{ marginRight: "auto", marginLeft: "auto" }}>
            {indexTable === -1 && (
              <span
                style={{ fontSize: 16, color: "#808080", fontWeight: "bold" }}
              >
                {item}
              </span>
            )}
            {indexTable > -1 && (
              <span style={{ fontSize: 16, color: "#808080" }}>{item}</span>
            )}
          </Col>
        );
      })}
    </Row>
  </Container>
);

export default Bill;
