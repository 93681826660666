//@ts-nocheck
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface IDataSearchById {
  name: string;
}

export interface IDataSearchByCarName {
  article: string;
  brand: string;
  code: string;
  description: string;
  info: string;
}

export interface IInfoAboutProduct {
  description: string;
  info: string;
  manufacturer: string;
  number: number;
  skuNoSymbols: string;
  price: number;
  wait: string;
  quantity: string;
}

const slice = (items) =>
  Object.values(
    items.reduce((acc, item) => {
      const skuNoSymbols = item.skuNoSymbols;
      if (!acc[skuNoSymbols]) acc[skuNoSymbols] = [];
      acc[skuNoSymbols].push({ ...item });
      return acc;
    }, {})
  );

export const useSearchInformation = () => {
  const [data, setData] = React.useState<IDataSearchById[]>();
  const [dataNameAuto, setDataNameAuto] =
    React.useState<IDataSearchByCarName[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [indexOfPage, setIndexOfPage] = React.useState<number>(0);
  const [infoAboutProduct, setInfoAboutProduct] = React.useState<
    IInfoAboutProduct | IInfoAboutProduct[] | undefined
  >();
  const [analogs, setAnalogs] = React.useState<
    IInfoAboutProduct[][] | IInfoAboutProduct[] | unknown[] | undefined
  >();
  const [analogsIndirect, setAnalogsIndirect] = React.useState<
    IInfoAboutProduct[][] | IInfoAboutProduct[] | unknown[] | undefined
  >();
  const [unverifiedAnalogs, setUnverifiedAnalogs] = React.useState<
    IInfoAboutProduct[][] | IInfoAboutProduct[] | unknown[] | undefined
  >();

  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const idQuery: string | null = queryParams.get('id');
  const nameQuery = queryParams.get('name');
  const code = queryParams.get('code');
  const token = localStorage.getItem('token');
  const custom = queryParams.get('custom');

  React.useEffect(() => {
    const arrayOfSearchHistory = JSON.parse(
      localStorage.getItem('historyOfSearch') as string
    );

    const searchOfHistory: any = [];

    if (!arrayOfSearchHistory) {
      searchOfHistory.push(idQuery);
      return localStorage.setItem(
        'historyOfSearch',
        JSON.stringify(searchOfHistory)
      );
    }
    if (arrayOfSearchHistory.length > 0 && arrayOfSearchHistory.length < 5) {
      arrayOfSearchHistory.push(idQuery);
      return localStorage.setItem(
        'historyOfSearch',
        JSON.stringify(arrayOfSearchHistory)
      );
    }
    if (arrayOfSearchHistory.length === 5) {
      arrayOfSearchHistory.shift();
      arrayOfSearchHistory.push(idQuery);
      return localStorage.setItem(
        'historyOfSearch',
        JSON.stringify(arrayOfSearchHistory)
      );
    }
  }, [idQuery]);

  React.useEffect(() => {
    setData([]);
    idQuery &&
      fetch(
        `https://dev.webapi.s-car.com.ua/api/products/search?textSearch=${idQuery}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'ru',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.length) {
            return setIndexOfPage(-1);
          }

          return setData(data);
        })
        .catch(handleError);

    setLoading(false);
  }, [idQuery]);

  React.useEffect(() => {
    setDataNameAuto([]);
    nameQuery &&
      fetch(
        `https://dev.webapi.s-car.com.ua/api/Products/searchByBrand?textSearch=${idQuery}&brand=${nameQuery}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'ru',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (!data.length) {
            return setIndexOfPage(-1);
          }

          return setDataNameAuto(data);
        })
        .catch(handleError);

    setLoading(false);
  }, [nameQuery]);

  React.useEffect(() => {
    if (!custom) {
      setInfoAboutProduct(undefined);
      code &&
        fetch(
          `https://dev.webapi.s-car.com.ua/api/Products/searchById?textSearch=${idQuery}&code=${code}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': 'ru',
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setAnalogs(data.analogs);
            setAnalogsIndirect(data.analogsIndirect);
            return setInfoAboutProduct(data.detailSearch);
          })
          .catch(handleError);
      setLoading(false);
    }
  }, [code]);

  React.useEffect(() => {
    setUnverifiedAnalogs([]);
    setAnalogs([]);
    setAnalogsIndirect([]);
    setInfoAboutProduct(undefined);
    custom &&
      fetch(
        `https://dev.webapi.s-car.com.ua/api/Products/searchToOrder?textSearch=${idQuery}&code=${code}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'ru',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUnverifiedAnalogs(slice(data.unverifiedAnalogs));
          setAnalogs(slice(data.analogs));
          setAnalogsIndirect(slice(data.analogsIndirect));
          return setInfoAboutProduct(data.detailSearch);
        })
        .catch(handleError);

    setLoading(false);
  }, [custom]);

  React.useEffect(() => {
    if (nameQuery && idQuery && code && custom) {
      return setIndexOfPage(3);
    } else if (nameQuery && idQuery && code) {
      return setIndexOfPage(2);
    } else if (nameQuery && idQuery) {
      return setIndexOfPage(1);
    } else if (!nameQuery && idQuery) {
      return setIndexOfPage(0);
    }
    return setIndexOfPage(-1);
  }, [nameQuery, idQuery, code, custom]);

  const handleError = () => {
    setLoading(false);
    history.push('/errorPage', null);
  };

  const handleSearchByNameAuto = (name: string) => {
    history.push(`/search?id=${idQuery}&name=${name}`);
  };

  const handleGetDetailsAboutProduct = (code: string) => {
    history.push(`/search?id=${idQuery}&name=${nameQuery}&code=${code}`);
  };

  const handleGetDetailsAboutProductBack = () => {
    history.push(`/search?id=${idQuery}&name=${nameQuery}&code=${code}`);
    fetch(
      `https://dev.webapi.s-car.com.ua/api/Products/searchById?textSearch=${idQuery}&code=${code}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'ru',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAnalogs(data.analogs);
        setAnalogsIndirect(data.analogsIndirect);
        return setInfoAboutProduct(data.detailSearch);
      })
      .catch(handleError);
    setLoading(false);
  };

  const handleGetDetailsAboutProducts = () => {
    history.push(
      `/search?id=${idQuery}&name=${nameQuery}&code=${code}&custom=${code}`
    );
  };

  return {
    data,
    loading,
    handleSearchByNameAuto,
    dataNameAuto,
    indexOfPage,
    handleGetDetailsAboutProduct,
    handleGetDetailsAboutProducts,
    handleGetDetailsAboutProductBack,
    infoAboutProduct,
    analogs: analogs?.sort((a, b) =>
      a[0]?.concern.toUpperCase() < b[0]?.concern.toUpperCase()
        ? -1
        : a[0]?.concern.toUpperCase() > b[0]?.concern.toUpperCase()
        ? 1
        : 0
    ),
    analogsIndirect: analogsIndirect?.sort((a, b) =>
      a[0]?.concern.toUpperCase() < b[0]?.concern.toUpperCase()
        ? -1
        : a[0]?.concern.toUpperCase() > b[0]?.concern.toUpperCase()
        ? 1
        : 0
    ),
    unverifiedAnalogs: unverifiedAnalogs?.sort((a, b) =>
      a[0]?.concern.toUpperCase() < b[0]?.concern.toUpperCase()
        ? -1
        : a[0]?.concern.toUpperCase() > b[0]?.concern.toUpperCase()
        ? 1
        : 0
    ),
  };
};
