import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
// importing Header components styles
import '../styles/header.scss';
import '../styles/language/language-switcher.scss';
import '../styles/margins.scss';

import Sidebar from './Sidebar';
import HeaderNavigation from './navigation/HeaderNavigation';
import Overlay from './Overlay';
import SearchForm from './search/search-form';
import LanguageSwitcher from './language/language-switcher';
import getLanguage from '../utils/getLanguage';
import getUser from '../utils/getUser';
// importing Header images
import logo from '../static/images/logo.svg';
import SearchFormMobile from './search/search-form-mobile';
// import getMainOffice from "../utils/findmainoffice";
// import getToken from '../utils/tokenchecker';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toStep } from '../pages/cart/actions';

function openNav() {
  (document.getElementById('sidenav') as HTMLInputElement).style.left = '0px';
  (document.getElementById('overlay') as HTMLInputElement).style.display =
    'block';
}

type Props = {
  contact: Object;
  rerender: boolean;
  isLogged: boolean;
};

interface DefaultRootState {
  cart: { count: number };
}

function Header(props: Props) {
  // const { contact, rerender } = props;
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  //  const [reren, setRerender] = useState(false);
  const [editmode, setEditmode] = useState(false);
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  const [lang, setLang] = useState('RU');

  const location = useLocation();
  const dispatch = useDispatch();

  const prodCount = useSelector((state: DefaultRootState) => state.cart.count);

  // const [user, setUser] = useState("User");
  /*
      useEffect(() => {
        setRerender(rerender);
        forceUpdate();
      }, [rerender, forceUpdate]);
    */
  // useEffect(() => {
  //   (async () => {
  //     let tokenObj = getToken();
  //     console.log('aa', tokenObj);

  //     if (tokenObj.valid) {
  //       fetch('http://letters.s-car.com.ua/api/v1/Currencies/cross', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Accept-Language': 'ru',
  //           Authorization: `Bearer ${tokenObj.token}`,
  //         },
  //       })
  //         .then((response) => {
  //           return response.json();
  //         })
  //         .then((course: any) => {
  //           setMessage(course.message);
  //         })
  //         .catch(function (error) {
  //           setMessage('');
  //         });
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    setLang(getLanguage());
    // setUser(getUser());
  }, []);
  useEffect(() => {
    if (localStorage.getItem('editmode') === 'true') {
      setEditmode(true);
    } else setEditmode(false);
  }, [localStorage.getItem('editmode')]); //rerender

  const Logout = () => {
    localStorage.setItem('editmode', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('isLogged');
    window.location.href = '/';
  };

  return (
    <header>
      <Overlay />
      <Sidebar />
      <Container fluid className="TopNavigation">
        <Container>
          <Row className="justify-content-between">
            <Col lg={7} className="d-none d-lg-block">
              <Row>
                <HeaderNavigation news={editmode} />
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-center justify-content-lg-end">
                <div className="font-medium d-none d-md-block d-lg-block TopNavigation-Hover">
                  <p>{props.contact}</p>
                </div>
                <div className="TopNavigation-Hover d-md-block d-lg-none d-xl-block">
                  {editmode && <p>{message}</p>}
                </div>
                {props.isLogged ? (
                  <div className="button_logout">
                    <button onClick={Logout}>Выйти</button>
                  </div>
                ) : null}
                <div className="TopNavigation-Hover d-md-block d-lg-none d-xl-block">
                  {getUser()}
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="MainNavigation">
        <Container>
          <Row className="justify-content-between">
            <Col className="max-width-300">
              <Row className="justify-content-start">
                <div className="margin-right-20 margin-right-mobile-10">
                  <div className="newicon mainmenu-white" onClick={openNav} />
                </div>
                <div className="align-self-center">
                  <Link to="/">
                    <img alt="logo" src={logo} className="logo" />
                  </Link>
                </div>
              </Row>
            </Col>
            <Col className="align-self-center d-none d-lg-block">
              <Row className="justify-content-center">
                <div>
                  <SearchForm />
                </div>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-end">
                <div className="d-flex margin-right-10 d-lg-none">
                  <SearchFormMobile />
                </div>
                <NavLink to={props.isLogged ? '/cabinet/profile' : '/register'}>
                  <div className="d-flex margin-left-20 margin-right-20 hover-icon-text">
                    <div className="span padding-right-10">
                      <div className="newicon profile" />
                    </div>
                    <div className="span align-self-center d-none d-lg-block">
                      <b className="bold-16px">Personal</b>
                      <br />
                      profile
                    </div>
                  </div>
                </NavLink>
                {location.pathname !== '/cart' ? (
                  <NavLink to="/cart">
                    <div className="d-flex margin-left-20 margin-right-20 hover-icon-text">
                      <div className="span padding-right-10">
                        <div className="newicon cart">
                          {prodCount ? (
                            <div className="cart-count">{prodCount}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="span align-self-center d-none d-lg-block">
                        <b className="bold-16px">Shopping</b>
                        <br />
                        cart
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <div onClick={() => dispatch(toStep(1))}>
                    <div className="d-flex margin-left-20 margin-right-20 hover-icon-text">
                      <div className="span padding-right-10">
                        <div className="newicon cart">
                          {prodCount ? (
                            <div className="cart-count">{prodCount}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="span align-self-center d-none d-lg-block">
                        <b className="bold-16px">Shopping</b>
                        <br />
                        cart
                      </div>
                    </div>
                  </div>
                )}

                <div className="align-self-center margin-left-20 d-none d-xl-block">
                  <LanguageSwitcher lang={lang} />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </header>
  );
}

export default Header;
