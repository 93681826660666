import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';

// const { store, persistor } = configureStore({});
const store = configureStore({});

// if (localStorage.getItem('token')) {
//   persistor.persist();
// } else {
//   persistor.pause();
//   // persistor.purge();
// }
// persistor.persist();

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
