import connect from "react-redux-connect-lifecycle";
////import { createStructuredSelector } from "reselect";
import Login from "../Login";
import { getLoginSelector } from "../reducer";
import { postLogin } from "../actions";
import { postRegister } from "../actions";
//import makeSelectIsLogged from "../selector";
const mapStateToProps = state => getLoginSelector(state);
/*
const mapStateToProps = createStructuredSelector({
  landing: makeSelectLanding(),
});
*/

const mapDispatchToProps = dispatch => ({
  onComponentDidMount: props => {
    console.log("component Login did mount");
  },
  onComponentWillUnmount: props => {
    console.log("component Login will unmount");
  },
  setLogin: (login, pwd, lang, func) =>
    dispatch(postLogin(login, pwd, lang, func)),
  setRegister: (name, surname, phone, region, email, type, city, company, password) =>
    dispatch(postRegister(name, surname, phone, region, email, type, city, company, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);



