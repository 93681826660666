// check if and which token is valid
export default function getToken() {
  let accessExpired = localStorage.getItem('accessExpires')!;
  var accExpSec = Date.parse(accessExpired);
  let refrreshExpired = localStorage.getItem('refreshExpires')!;

  var refrExpSec = Date.parse(refrreshExpired);

  let retVal = { valid: false, token: '' };
  if (
    typeof accessExpired !== 'undefined' &&
    accessExpired !== null &&
    typeof refrreshExpired !== 'undefined' &&
    refrreshExpired !== null
  ) {
    var dateTime = new Date().getTime();
    console.log('token current time', dateTime);
    console.log('token difference ', accExpSec - dateTime);
    if (accExpSec - dateTime > 0) {
      retVal.valid = true;
      retVal.token = localStorage.getItem('accessToken')!;
    } else if (refrExpSec - dateTime > 0) {
      retVal.valid = true;
      retVal.token = localStorage.getItem('refreshToken')!;
    }
  }

  return retVal;
}

/**
 {"type":"Login/LOGIN_SUCCESS","value":{"id":"2",
 "name":"Admin",
 "accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjIiLCJyb2xlIjoiQWRtaW4iLCJUb2tlbiI6IkFjY2VzcyIsIm5iZiI6MTU5MjQ3OTQ4NiwiZXhwIjoxNTkyNTE1NDg2LCJpYXQiOjE1OTI0Nzk0ODZ9.9Uq2F_uvaN4A7REY5XpDE_MZmguTKksbz80EVW6Ctc0",
 "accessExpires":"2020-06-19T00:24:46",
 "refreshToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjIiLCJyb2xlIjoiQWRtaW4iLCJUb2tlbiI6IlJlZnJlc2giLCJuYmYiOjE1OTI0Nzk0ODYsImV4cCI6MTU5MjgyNTA4NiwiaWF0IjoxNTkyNDc5NDg2fQ.PnwKPBDyhKlvXlb7wgaxKl3MIqVExALUmvPgrSf45GM",
 "refreshExpires":"2020-06-22T14:24:46",
 "role":"Admin"}}

        localStorage.setItem("accessToken", action.value.accessToken);
        localStorage.setItem("refreshToken", action.value.refreshToken);
        localStorage.setItem("accessExpires", action.value.accessExpires);
        localStorage.setItem("refreshExpires", action.value.refreshExpires);

 *
 */

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function checkToken() {
  if (localStorage.getItem('token')) {
    const parseToken = parseJwt(localStorage.getItem('token'));

    if (new Date().getTime() > parseToken.exp * 1000) {
      localStorage.setItem('editmode', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('isLogged');
      window.location.href = '/';
    }
  }
}
