import { useState, useEffect, useMemo } from 'react';
import { Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalForm from './ModalComment';

type Props = {
  orders: [any];
  toOrderProductsFilterOrderNumber: Function;
  selectionByProduct: string;
  setSelectionByProduct: Function;
};

const Orders = (props: Props) => {
  const {
    orders,
    toOrderProductsFilterOrderNumber,
    selectionByProduct,
    setSelectionByProduct,
  } = props;

  const [paginationPages, setPaginationPages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const [time, setTime] = useState<number>(1);
  const [searchTime, setSearchTime] = useState<number>(0);
  const [searchOrderNumber, setSearchOrderNumber] = useState<string>('');
  const [orderStatuses, setOrderStatuses] = useState<any>({});

  const makeStatuses = () => {
    const statusesNewSet = new Set();
    orders.forEach((item) =>
      !item.status ? null : statusesNewSet.add(item.status)
    );
    const statusesArr = Array.from(statusesNewSet);
    const statuses = {};
    statusesArr.forEach((item: any) => {
      statuses[item] = false;
    });
    setOrderStatuses(statuses);
  };

  useEffect(() => {
    makeStatuses();
  }, []);

  const dropFilters = () => {
    setSearchOrderNumber('');
    setTimeHandler(100);
    makeStatuses();
  };

  useEffect(() => {
    if (selectionByProduct) {
      dropFilters();
      setSearchOrderNumber(selectionByProduct);
    }
  }, [selectionByProduct]);

  const setTimeHandler = (monthValue) => {
    if (monthValue === 100) {
      setTime(monthValue);
      setSearchTime(0);
      return;
    }
    setTime(monthValue);
    const currDate = new Date();
    currDate.setMonth(currDate.getMonth() - monthValue);
    const selectedDateTimeStamp = currDate.getTime();
    setSearchTime(selectedDateTimeStamp);
  };

  const filteredIsChecked = useMemo(() => {
    const isChecked: string[] = [];

    Object.entries(orderStatuses).map(([key, value]) => {
      if (value) {
        isChecked.push(key);
      }
    });

    return isChecked;
  }, [orderStatuses]);

  const callbackFilter = (el) => {
    if (!filteredIsChecked.length) {
      return true;
    }

    for (let i = 0; i < filteredIsChecked.length; i++) {
      if (filteredIsChecked[i] === el.status) {
        return true;
      }
    }

    return false;
  };

  const filteredOrders = useMemo(() => {
    const filteredData = orders
      .filter((orders) => {
        return orders.number.includes(searchOrderNumber);
      })
      .filter((orders) => {
        return new Date(orders.date).getTime() >= searchTime;
      })
      .filter(callbackFilter);
    setPage(1);

    return filteredData;
  }, [searchOrderNumber, searchTime, orderStatuses]);

  const paginationOrders = useMemo(() => {
    let pages: any[] = [];
    for (
      let number = 1;
      number <= Math.ceil(filteredOrders.length / itemsPerPage);
      number++
    ) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => setPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPaginationPages([...pages]);

    if (pages.length < page) {
      setPage(pages.length);
    }

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const currentData = filteredOrders.slice(start, end);

    return currentData;
  }, [filteredOrders, page, itemsPerPage]);

  useEffect(() => {
    setTimeHandler(1);
  }, []);

  return (
    <>
      <ModalForm show={show} setShow={setShow} modalData={modalData} />
      <Row>
        <Form.Group className="mb-3 col-12 d-flex align-items-center">
          <span style={{ marginRight: '0.75rem' }}>Статус заказа:</span>
          {Object.entries(orderStatuses).map(([key, value], ind) => {
            return (
              <Form.Check
                key={ind}
                inline
                label={key}
                name={`status-${ind + 1}`}
                type="checkbox"
                // @ts-ignore:next-line
                checked={value}
                id={`statusId-${ind + 1}`}
                onChange={() =>
                  setOrderStatuses({ ...orderStatuses, [key]: !value })
                }
              />
            );
          })}
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6">
          <Form.Control
            placeholder="Введите номер заказа"
            onChange={(e) => setSearchOrderNumber(e.target.value)}
            value={searchOrderNumber}
            // @ts-ignore:next-line
            disabled={selectionByProduct}
            className="rounded-0"
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setTimeHandler(+e.target.value)}
            value={time}
          >
            <option value={1}>1 месяц</option>
            <option value={3}>3 месяца</option>
            <option value={6}>6 месяцев</option>
            <option value={12}>Год</option>
            <option value={100}>Всё время</option>
          </Form.Control>
        </Form.Group>
        {selectionByProduct && (
          <div className="mb-3 col-6">
            Информация по заказу: {selectionByProduct}
            <span
              className="selection-by text-d-underline"
              onClick={() => {
                setSearchOrderNumber('');
                setSelectionByProduct('');
              }}
            >
              Показать все заказы
            </span>
          </div>
        )}
      </Row>
      <Table striped bordered hover responsive className="document-table">
        <thead>
          <tr>
            <th>Номер заказа</th>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Cтатус</th>
            <th>Вариант поулчения</th>
            <th className="word-wrap">Инфо доставки</th>
            <th>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          {paginationOrders.map((order, ind) => {
            return (
              <tr key={ind}>
                <td
                  onClick={() => toOrderProductsFilterOrderNumber(order.number)}
                  className="order-number-link text-d-underline"
                >
                  {order.number}
                </td>
                <td>{new Date(order.date).toLocaleDateString()}</td>
                <td>
                  {order.sum} {order.currency}
                </td>
                <td>{order.status}</td>
                <td>{order.deliveryType}</td>
                <td className={order.info ? 'p-0 word-wrap' : 'word-wrap'}>
                  {order.info ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {order.deliveryPoint}
                        </Tooltip>
                      }
                    >
                      <div className="table-popover justify-content-center text-d-underline custom-popover">
                        {order.info}
                      </div>
                    </OverlayTrigger>
                  ) : (
                    order.deliveryPoint
                  )}
                </td>
                <td
                  onClick={() => {
                    setModalData(order.deliveryComments);
                    setShow(true);
                  }}
                >
                  {order.deliveryComments && (
                    <div className="comment">{order.deliveryComments}</div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex overflow-auto flex-wrap">
        <Form.Group
          style={{ marginRight: '0.75rem' }}
          className="flex-shrink-0 mt-3"
        >
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setItemsPerPage(+e.target.value)}
            value={itemsPerPage}
          >
            <option value={10}>10 строк</option>
            <option value={15}>15 строк</option>
            <option value={20}>20 строк</option>
            <option value={30}>30 строк</option>
            <option value={50}>50 строк</option>
          </Form.Control>
        </Form.Group>

        {paginationPages.length > 1 && (
          <Pagination className="mt-3">{paginationPages}</Pagination>
        )}
      </div>
    </>
  );
};

export default Orders;
