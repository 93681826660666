import produce from "immer";

import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  REGISTER_ERROR,
  LOGIN_ERROR
} from "../const";

export const initialState = {
  isLogged: false,
  errorData: "",
  notificationData: '',
  successRegister: false,
  successLogged: false,
  isErrorLogin: false,
  loginErrorMessage: ''
};

export const getRegisterSelector = state => ({ ...state.register });

const registersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REGISTER_SUCCESS:
        draft.successRegister = true;
        draft.notificationData = "Профиль отправлен администратору";
        break;
      case LOGIN_SUCCESS:
        draft.successLogged = true;
        localStorage.setItem("isLogged", "1");
        draft.isLogged = true;
        break;
      case REGISTER_ERROR:
        draft.errorData = "Ошибка";
        break;
      case LOGIN_ERROR:
          draft.isErrorLogin = true;
          draft.loginErrorMessage = action.payload;
        break;
      default:
        return state;
    }
  });

export default registersReducer;
