import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";

type ContactBlockProps = {
  name: string;
  comments: string;
  contacts: [];
  address: string;
  id: string;
};

const ContactBlock = ({
  name,
  contacts,
  address,
  id
}: ContactBlockProps) => {
  const [showEditor, setShowEditor] = useState(false);
  const dispatch = useDispatch();
  const deleteOffice = id => {
    dispatch({ type: "DELETEOFFICE", id });
  };
  useEffect(() => {
    if (localStorage.getItem("editmode") === "true") {
      setShowEditor(true);
    } else setShowEditor(false);
  }, []);

  return (
    <Col xs={12} md={6} lg={6} className="ContactBlock">
      <Row className="margin-bottom-20">
        <h2 className="text-uppercase">{name}</h2>
        {showEditor === true && (
          <h2
            style={{ color: "red", marginRight: 0, marginTop: -20 }}
            onClick={() => deleteOffice(id)}
          >
            Х
          </h2>
        )}
      </Row>
      <Row className="margin-bottom-10">
        <p>
          <b>{address && address}</b>
        </p>
      </Row>
      <Row className="ContactInfo">
        <Col xs={12} md={12} lg={6}>
          {contacts.map((contact, index) => (
            <Row key={contact["id"]}>
              <div className="span">
                <div className="contacticon" id="iconMessage" />
              </div>
              <div className="span margin-left-15 align-self-center">
                <p className="gray-12px">{contact["type"]}</p>
                <p>{contact["name"]}</p>
              </div>
            </Row>
          ))}
        </Col>
      </Row>
    </Col>
  );
};

export default ContactBlock;
