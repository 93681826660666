import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

type Props = {
  lang: string;
};
function LanguageSwitcher({ lang }: Props) {
  const [value, setValue] = useState("");
  const handleSelect = e => {
    setValue(e);
    localStorage.setItem("lang", e);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-custom-components">
        {value.length > 0 && value}
        {value.length === 0 && lang}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="RU" onSelect={handleSelect}>
          RU
        </Dropdown.Item>
        <Dropdown.Item eventKey="EN" onSelect={handleSelect}>
          EN
        </Dropdown.Item>
        <Dropdown.Item eventKey="DE" onSelect={handleSelect}>
          DE
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSwitcher;

/// [{"id":"en","name":"АНГ"},{"id":"ru","name":"РУС"},{"id":"uk","name":"УКР"}],
