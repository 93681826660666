import connect from "react-redux-connect-lifecycle";
import Page from "../Page";
import { getSelector } from "../reducer";
import { getPage, savePage } from "../actions";

const mapDispatchToProps = dispatch => ({
  onComponentDidMount: props => {
    dispatch(getPage(props.page, props.showEditor));
  },
  onComponentWillUnmount: props => {
    console.log(props.page + "component will unmount");
  },
  onSavePage: content => dispatch(savePage(content))
});

const mapStateToProps = (state, ownProps) => getSelector(state, ownProps.page);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
