import connect from "react-redux-connect-lifecycle";
import Page from "../Page";
import { getNewsSelector } from "../reducer";
import {
  deleteNews,
  savePage,
  saveNews,
  getTargetOffives,
  getTargetRegion,
  getAdminNews,
  editNews,
  cleanEditedNews
} from "../actions";
// import getMainOffice from "../../../utils/findmainoffice";

const mapDispatchToProps = dispatch => ({
  onComponentDidMount: async props => {
    // dispatch(getAdminNews(0))
    dispatch(getTargetOffives());
    dispatch(getTargetRegion());
  },
  onComponentWillUnmount: props => {
    console.log("component NEWS will unmount");
  },
  onSavePage: (id, content) => dispatch(savePage(id, content)),
  onDelete: id => dispatch(deleteNews("ru", id)),
  onEdit: id => dispatch(editNews(id)),
  saveData: data => dispatch(saveNews(data)),
  getAdminNews: page => dispatch(getAdminNews(page)),
  loadNext: page => dispatch(getAdminNews(page)),
  cleanEdited: () => dispatch(cleanEditedNews())
  //getTargetOf:() => dispatch(getTargetOffives()),
  //getTargetReg:() => dispatch(getTargetRegion()),
});

const mapStateToProps = state => getNewsSelector(state);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
