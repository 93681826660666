import { useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { sagaCartRemove, sagaCartUpdate, cartItemChecked } from './actions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import { LightboxComp } from '../../components/lightbox';

interface DefaultRootState {
  cart: {
    products: [any];
    selectedProducts: [any];
    sum: number;
    selectedSum: number;
    error: any;
    loading: boolean;
  };
}

function CartItems() {
  const cart = useSelector((state: DefaultRootState) => state.cart);
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(false);
  const [runLightbox, setRunLightbox] = useState<any>([]);

  const dispatch = useDispatch();
  const variants = {
    modal: {
      stock: 'stock',
      comment: 'comment',
      calc_transport: 'calc_transport',
      calc_summ: 'calc_summ',
      prod_info: 'prod_info',
    },
    checkbox: {
      gabarite: 'gabarite',
      prod_check: 'prod_check',
      ckeck_all: 'check_all',
    },
  };

  const getDeliveryDate = (days: number) => {
    const currentDate = new Date();

    for (let i = 1; i <= days; i++) {
      if (currentDate.getDay() === 6) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
      if (currentDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return currentDate.toLocaleDateString();
  };

  const handleClose = () => {
    setModalData({});
    setShow(false);
  };
  const handleShow = (data, variant) => {
    setModalData({ data, variant });
    setShow(true);
  };

  const ModalInfo = ({ show, setShow, handleClose }) => {
    console.log('dd', modalData);

    const CalcTransport = () => {
      return (
        <div className="mt-1 mb-3 info-modal_grid-table">
          <p className="font-weight-bold">Параметр</p>
          <p className="font-weight-bold">Значение</p>
          <p>Транспорт за 1кг: </p>
          <p>{modalData.data.transportPerKg}</p>
          {modalData.data.weightSharp !== 0 ? (
            <>
              <p>Вес точный: </p>
              <p>{modalData.data.weightSharp}</p>
            </>
          ) : (
            <>
              <p>Вес ориентировочный: </p>
              <p>{modalData.data.weightAprox}</p>
            </>
          )}
          <p>Стоимость: </p>
          <p>
            {modalData.data.weightSharp
              ? (
                  modalData.data.transportPerKg * modalData.data.weightSharp
                ).toFixed(2)
              : (
                  modalData.data.transportPerKg * modalData.data.weightAprox
                ).toFixed(2)}
          </p>
        </div>
      );
    };

    const CalcSumm = () => {
      return (
        <div className="mt-1 mb-3 info-modal_grid-table">
          <p className="font-weight-bold">Параметр</p>
          <p className="font-weight-bold">Значение</p>
          <p>Транспорт за 1кг: </p>
          <p>{modalData.data.transportPerKg}</p>
          {modalData.data.weightSharp !== 0 ? (
            <>
              <p>Вес точный: </p>
              <p>{modalData.data.weightSharp}</p>
            </>
          ) : (
            <>
              <p>Вес ориентировочный: </p>
              <p>{modalData.data.weightAprox}</p>
            </>
          )}
          <p>Транспортировка за ед.: </p>
          <p>
            {modalData.data.weightSharp
              ? (
                  modalData.data.transportPerKg * modalData.data.weightSharp
                ).toFixed(2)
              : (
                  modalData.data.transportPerKg * modalData.data.weightAprox
                ).toFixed(2)}
          </p>
          <p>Цена:</p>
          <p>{modalData.data.price}</p>
          <p>Цена с транспортом:</p>
          <p>
            {(
              modalData.data.price +
              Number(
                modalData.data.weightSharp
                  ? (
                      modalData.data.transportPerKg * modalData.data.weightSharp
                    ).toFixed(2)
                  : (
                      modalData.data.transportPerKg * modalData.data.weightAprox
                    ).toFixed(2)
              )
            ).toFixed(2)}
          </p>
          <p className="font-weight-bold mt-1">Всего:</p>
          <p className="font-weight-bold mt-1">
            {(
              (
                modalData.data.price +
                Number(
                  modalData.data.weightSharp
                    ? (
                        modalData.data.transportPerKg *
                        modalData.data.weightSharp
                      ).toFixed(2)
                    : (
                        modalData.data.transportPerKg *
                        modalData.data.weightAprox
                      ).toFixed(2)
                )
              ).toFixed(2) * modalData.data.count
            ).toFixed(2)}
          </p>
          {console.log('modalData', modalData)}
        </div>
      );
    };

    const Stock = () => {
      return (
        <>
          <div className="mt-1 info-modal_grid-table">
            <p className="font-weight-bold">Место хранения</p>
            <p className="font-weight-bold">Наличие</p>
          </div>
          {modalData.data.storeAvailability.map((item, ind) => {
            return (
              <div key={ind} className="info-modal_grid-table">
                <p>
                  {item.store}
                  {item.isMain && ' (ваш склад)'}
                </p>
                <p>{item.quantity}</p>
              </div>
            );
          })}
        </>
      );
    };

    const Comment = () => {
      const [comment, setComment] = useState(modalData.data.comment);
      return (
        <Form.Control
          className="comment"
          as="textarea"
          rows={3}
          placeholder="Комментарий..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      );
    };

    const sendComment = (comment) => {
      dispatch(
        sagaCartUpdate({
          data: modalData.data,
          action: 'comment',
          comment,
        })
      );

      setModalData({});
      setShow(false);
    };

    const ProdInfo = () => {
      return (
        <>
          <div className="mt-1 info-modal_grid-table">
            <p className="font-weight-bold">Параметр</p>
            <p className="font-weight-bold">Значение</p>
          </div>

          <div className="mt-1 mb-3 info-modal_grid-table">
            <p>Артикул</p>
            <p>
              {modalData.data.skuNoSymbols ? modalData.data.skuNoSymbols : '-'}
            </p>
            <p>Наименование</p>
            <p>
              {modalData.data.description ? modalData.data.description : '-'}
            </p>
            <p>Производитель</p>
            <p>
              {modalData.data.manufacturer ? modalData.data.manufacturer : '-'}
            </p>
            {modalData.data.weightSharp === 0 &&
            modalData.data.weightAprox === 0 ? (
              <>
                <p>Вес </p>
                <p>
                  <span style={{ color: '#962932' }}>?</span>
                </p>
              </>
            ) : modalData.data.weightSharp !== 0 ? (
              <>
                <p>Вес точный: </p>
                <p>
                  {modalData.data.weightSharp}
                  <span style={{ color: '#962932' }}>*</span>
                </p>
              </>
            ) : (
              <>
                <p>Вес ориентировочный: </p>
                <p>
                  {modalData.data.weightAprox}
                  <span style={{ color: '#962932' }}>!</span>
                </p>
              </>
            )}
          </div>

          {modalData.data.weightSharp === 0 &&
          modalData.data.weightAprox === 0 ? (
            <>
              <p>
                <span className="c-main-red">? </span>
                Данные по весу товара отсутствуют, просьба уточнять вес и
                возможные затраты на транспорт у менеджера.
              </p>
            </>
          ) : modalData.data.weightSharp !== 0 ? (
            <>
              <p>
                <span className="c-main-red">* </span>
                Просим обратить ваше внимание, что в указанном весе детали не
                учтён вес общей упаковки, в которой доставляется товар, а для
                габаритных деталей кузова в целях сохранности при
                транспортировке упаковка изготавливается индивидуально, что
                также влияет на вес товара и стоимость его доставки. Для
                разъяснения и уточнения возникших вопросов просьба связаться с
                менеджером компании.
              </p>
            </>
          ) : (
            <>
              <p>
                <span className="c-main-red">! </span>
                Вес взят из открытых источников и нами не проверен, просьба
                уточнять вес и возможные затраты на транспорт у менеджера.
              </p>
            </>
          )}
        </>
      );
    };

    return (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="info-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="info-modal_header">
            {modalData.variant === variants.modal.comment &&
              'Комментарий к товару'}
            {modalData.variant === variants.modal.calc_transport &&
              'Формирование затрат на ед. товара'}
            {modalData.variant === variants.modal.calc_summ &&
              'Детализация расчёта цены'}
            {modalData.variant === variants.modal.stock &&
              'Наличие товара на складах'}
            {console.log('dd', modalData)}
            {modalData.variant === variants.modal.prod_info &&
              `${modalData.data.skuNoSymbols} ${modalData.data.manufacturer} ${modalData.data.description}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData.variant === variants.modal.comment && <Comment />}
          {modalData.variant === variants.modal.stock && <Stock />}
          {modalData.variant === variants.modal.calc_transport && (
            <CalcTransport />
          )}
          {modalData.variant === variants.modal.calc_summ && <CalcSumm />}
          {modalData.variant === variants.modal.prod_info && <ProdInfo />}
        </Modal.Body>
        <Modal.Footer>
          {modalData.variant === variants.modal.comment && (
            <Button
              className="btn-second"
              onClick={() =>
                sendComment(document.querySelector('.comment')?.textContent)
              }
            >
              Сохранить
            </Button>
          )}
          <Button onClick={handleClose} className="btn-main">
            {modalData.variant === variants.modal.comment
              ? 'Отменить'
              : 'Закрыть'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalBtn = ({ click, data, children, variant }) => {
    return (
      <button
        className={
          variant === variants.modal.comment
            ? 'border-0 bg-transparent w-100 h-100 comment-btn'
            : 'border-0 bg-transparent w-100 h-100'
        }
        onClick={() => click(data, variant)}
      >
        {children}
      </button>
    );
  };

  const Checkbox = ({ data, variant }) => {
    let initial;
    if (variant === variants.checkbox.gabarite) {
      initial = data.gabarite;
    } else if (variant === variants.checkbox.prod_check) {
      const searchId = cart.selectedProducts.filter((item) => item === data.id);

      initial = searchId.length ? true : false;
    } else if (variant === variants.checkbox.ckeck_all) {
      initial =
        cart.products.length === cart.selectedProducts.length ? true : false;
    }

    const [checked, setChecked] = useState(initial);

    const onChange = () => {
      setChecked((prev) => !prev);
      if (variant === variants.checkbox.gabarite) {
        dispatch(
          sagaCartUpdate({ data: data, action: 'gabarite', check: !checked })
        );
      }
      if (variant === variants.checkbox.prod_check) {
        dispatch(cartItemChecked(data.id));
      }
      if (variant === variants.checkbox.ckeck_all) {
        dispatch(cartItemChecked('check_all'));
      }
    };
    return (
      <label className="checkbox-label">
        <input type="checkbox" checked={checked} onChange={onChange} />
      </label>
    );
  };

  const CountArea = ({ prod }) => {
    const [count, setCount] = useState(prod.count);
    let quantity = null;
    if (prod.storeAvailability) {
      quantity = prod.storeAvailability.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
    }

    const countChecker = (num) => {
      console.log('nnn', Number(num));
      if (quantity) {
        if (Number(num) > quantity) {
          setCount(Number(quantity));
        } else {
          setCount(num);
        }
      }
    };

    const blurHandler = (num) => {
      if (Number(num) > 0) {
        dispatch(
          sagaCartUpdate({ data: prod, action: 'count', count: Number(num) })
        );
      } else {
        setCount(prod.count);
      }
    };

    return (
      <>
        <button
          className="plus-minus-btn"
          onClick={() =>
            dispatch(
              sagaCartUpdate({
                data: prod,
                action: 'count',
                count: Number(count) - 1,
              })
            )
          }
          disabled={Number(count) === 1 || Number(count) === 0}
        >
          -
        </button>
        <input
          style={{
            width: '60px',
            textAlign: 'center',
            marginRight: '6px',
          }}
          type="number"
          className="no-spin"
          min={1}
          max={quantity ? quantity : 99999}
          value={count}
          onChange={(e) => countChecker(e.target.value)}
          onBlur={(e) => blurHandler(e.target.value)}
        />
        <button
          className="plus-minus-btn"
          onClick={() =>
            dispatch(
              sagaCartUpdate({
                data: prod,
                action: 'count',
                count: Number(count) + 1,
              })
            )
          }
          disabled={Number(count) === quantity}
        >
          +
        </button>
      </>
    );
  };

  return (
    <>
      <div className="cart-page">
        <Table bordered hover responsive className="h-100">
          <thead>
            <tr>
              <th scope="col" className="align-middle p-0">
                <Checkbox data={null} variant={variants.checkbox.ckeck_all} />
              </th>
              <th scope="col" className="align-middle">
                Артикул
              </th>
              <th scope="col" className="align-middle">
                Производитель
              </th>
              <th scope="col" className="align-middle">
                Наименование
              </th>
              <th scope="col" className="align-middle p-0">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">Отметка о габарите</Tooltip>
                  }
                >
                  <div className="table-popover">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001 6.971 2.789Zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z"
                      />
                    </svg>
                  </div>
                </OverlayTrigger>
              </th>
              <th scope="col" className="align-middle">
                Наличие нашего склада
              </th>
              <th scope="col" className="align-middle">
                Дата поставки
              </th>

              <th scope="col" className="align-middle p-0">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Ограничения возврата или условия возврата
                    </Tooltip>
                  }
                >
                  <div className="table-popover justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                  </div>
                </OverlayTrigger>
              </th>
              <th scope="col" className="align-middle">
                Транс. затраты
              </th>
              <th scope="col" className="align-middle">
                Цена {cart.products[0]?.currency}
              </th>
              <th scope="col" className="align-middle">
                Кол-во
              </th>
              <th scope="col" className="align-middle">
                Сумма {cart.products[0]?.currency}
              </th>
              <th scope="col" className="align-middle">
                Комментарий
              </th>
              <th scope="col" className="border-color"></th>
            </tr>
          </thead>
          <tbody>
            {cart.products.map((prod: any, ind) => {
              return (
                <tr key={ind}>
                  <td style={{ padding: 0 }}>
                    {/* checkbox */}
                    <Checkbox
                      data={prod}
                      variant={variants.checkbox.prod_check}
                    />
                  </td>
                  <td>
                    {/* artikul */}
                    <ModalBtn
                      click={handleShow}
                      data={prod}
                      variant={variants.modal.prod_info}
                    >
                      <span style={{ color: 'blue' }}>{prod.skuNoSymbols}</span>
                    </ModalBtn>
                  </td>
                  <td>
                    {/* proizvoditel */}
                    {prod.manufacturer}
                  </td>
                  <td>
                    {/* naimenovanie */}
                    {prod.description ? prod.description : 'Отсутствует'}
                    <button
                      className="btn btn-sm icon-modal-btn"
                      disabled={!prod?.images.length}
                      onClick={() => setRunLightbox([...prod.images])}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={prod.images.length ? 'currentColor' : '#ccc'}
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                        <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                      </svg>
                    </button>
                  </td>
                  <td style={{ padding: 0 }}>
                    {/* gabarit */}
                    <Checkbox
                      data={prod}
                      variant={variants.checkbox.gabarite}
                    />
                  </td>
                  <td>
                    {/* nalichie */}
                    {prod.storeAvailability !== null ? (
                      <ModalBtn
                        click={handleShow}
                        data={prod}
                        variant={variants.modal.stock}
                      >
                        <span style={{ color: 'blue' }}> {prod.quantity} </span>
                      </ModalBtn>
                    ) : (
                      prod.quantity
                    )}
                  </td>
                  <td>
                    {/* data postav */}
                    {/* {prod.item.wait} */}
                    {'   '}
                    {/* {date.setDate(date.getDate() + prod.item.wait)}
                      {date.toLocaleDateString()} */}
                    {getDeliveryDate(prod.wait)}
                  </td>
                  <td className="text-center">
                    {/* uslov vozvrata */}
                    {prod.isNotReturn === false ? (
                      '-'
                    ) : (
                      // <OverlayTrigger
                      //   placement="right"
                      //   overlay={
                      //     <Tooltip id="tooltip-disabled">
                      //       Возврат не ограничен
                      //     </Tooltip>
                      //   }
                      // >
                      //   <div className="table-popover justify-content-center">
                      //     <svg
                      //       xmlns="http://www.w3.org/2000/svg"
                      //       width="20"
                      //       height="20"
                      //       fill="currentColor"
                      //       viewBox="0 0 16 16"
                      //     >
                      //       <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                      //     </svg>
                      //   </div>
                      // </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Возврат ограничен
                          </Tooltip>
                        }
                      >
                        <div className="table-popover justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="var(--main-red)"
                              d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"
                            />
                          </svg>
                        </div>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td className="text-center">
                    {/* transp zatrat */}
                    {/* trprkg: {prod.item.transportPerKg} - 
                      aprox: {prod.item.weightAprox} - 
                      sharp: {prod.item.weightSharp} - */}
                    {prod.toOrder &&
                    prod.transportPerKg !== 0 &&
                    prod.transportPerKg &&
                    (prod.weightAprox !== 0 || prod.weightSharp !== 0) ? (
                      <ModalBtn
                        click={handleShow}
                        data={prod}
                        variant={variants.modal.calc_transport}
                      >
                        <span style={{ color: 'blue' }}>
                          {prod.weightSharp
                            ? (
                                Number(
                                  (
                                    prod.transportPerKg * prod.weightSharp
                                  ).toFixed(2)
                                ) * prod.count
                              ).toFixed(2)
                            : (
                                Number(
                                  (
                                    prod.transportPerKg * prod.weightAprox
                                  ).toFixed(2)
                                ) * prod.count
                              ).toFixed(2)}
                          <span style={{ color: 'rgb(150, 41, 50)' }}>!</span>
                        </span>
                      </ModalBtn>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {/* cena */}
                    {prod.price}
                  </td>
                  <td>
                    {/* k-vo */}
                    <CountArea prod={prod} />
                  </td>
                  <td>
                    {/* suma */}
                    {prod.toOrder &&
                    prod.transportPerKg !== 0 &&
                    prod.transportPerKg &&
                    (prod.weightAprox !== 0 || prod.weightSharp !== 0) ? (
                      <ModalBtn
                        click={handleShow}
                        data={prod}
                        variant={variants.modal.calc_summ}
                      >
                        <span
                          // style={{ color: prod.gabarite ? 'blue' : 'inherit' }}
                          style={{ color: 'blue' }}
                        >
                          ({/* {prod.gabarite && '('} */}
                          {prod.weightSharp
                            ? (
                                (
                                  prod.transportPerKg * prod.weightSharp +
                                  prod.price
                                ).toFixed(2) * prod.count
                              ).toFixed(2)
                            : (
                                (
                                  prod.transportPerKg * prod.weightAprox +
                                  prod.price
                                ).toFixed(2) * prod.count
                              ).toFixed(2)}
                          ){/* {prod.gabarite && ')'} */}
                          <span style={{ color: 'rgb(150, 41, 50)' }}>!</span>
                          {/* {prod.gabarite && (
                          <span style={{ color: 'rgb(150, 41, 50)' }}>!</span>
                        )} */}
                        </span>
                      </ModalBtn>
                    ) : (
                      <span
                        style={{ color: prod.gabarite ? 'blue' : 'inherit' }}
                      >
                        {/* ({(prod.price * prod.count).toFixed(2)}) */}
                        {prod.gabarite && '('}
                        {(prod.price * prod.count).toFixed(2)}
                        {prod.gabarite && ')'}
                        {prod.gabarite && (
                          <span style={{ color: 'rgb(150, 41, 50)' }}>!</span>
                        )}
                      </span>
                    )}
                  </td>
                  <td style={{ padding: 0 }}>
                    {/* comment */}
                    <ModalBtn
                      click={handleShow}
                      data={prod}
                      variant={variants.modal.comment}
                    >
                      <span>{prod.comment}</span>
                    </ModalBtn>
                  </td>
                  <td className="del-item border-color">
                    <button
                      type="button"
                      className="close close-item h-100"
                      onClick={() => dispatch(sagaCartRemove([prod.id]))}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {show && (
        <ModalInfo show={show} setShow={setShow} handleClose={handleClose} />
      )}
      <LightboxComp runLightbox={runLightbox} setRunLightbox={setRunLightbox} />
    </>
  );
}

export default CartItems;
