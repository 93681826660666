import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useCallback, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRefundsSaga, getRefundsProductsSaga } from './actions';
import { LoadingComponent } from '../../../components/LoadingComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Refunds from './component/Refunds';
import Products from './component/Products';
import '../../../styles/cabinet/tables-pages.scss';

interface DefaultRootState {
  profileRefunds: {
    refunds: [any];
    refundsProducts: [any];
    isGetRefunds: boolean;
    isGetRefundsProducts: boolean;
    loading: boolean;
    error: boolean;
  };
}

const MemoRefunds = memo(Refunds);
const MemoProducts = memo(Products);

const RefundsPage = () => {
  const [tab, setTab] = useState<string>('refunds');
  const [selectionByOrder, setSelectionByOrder] = useState<string>('');
  const [selectionByProduct, setSelectionByProduct] = useState<string>('');

  const refunds = useSelector(
    (state: DefaultRootState) => state.profileRefunds
  );
  const dispatch = useDispatch();

  const toOrderFilterOrderNumber = useCallback((num) => {
    setTab('refunds');
    setSelectionByProduct(num);
  }, []);

  const toOrderProductsFilterOrderNumber = useCallback((num) => {
    setTab('refundsProducts');
    setSelectionByOrder(num);
  }, []);

  useEffect(() => {
    if (!refunds.isGetRefunds) {
      dispatch(getRefundsSaga());
    }
    if (!refunds.isGetRefundsProducts) {
      dispatch(getRefundsProductsSaga());
    }
  }, []);

  return (
    <Container fluid className="profile-orders">
      <Row className="justify-content-between mb-2">
        <Col>
          <h2 className="text-uppercase">Возвраты</h2>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col>
          <Tabs
            id="controlled-tab"
            activeKey={tab}
            onSelect={(k: any) => setTab(k)}
            className="mb-3"
          >
            <Tab eventKey="refunds" title="Возвраты">
              {!refunds.loading ? (
                <>
                  {refunds.refunds.length ? (
                    <MemoRefunds
                      refunds={refunds.refunds}
                      toOrderProductsFilterOrderNumber={
                        toOrderProductsFilterOrderNumber
                      }
                      selectionByProduct={selectionByProduct}
                      setSelectionByProduct={setSelectionByProduct}
                    />
                  ) : (
                    refunds.error ? 
                    <h3>Ошибка запроса</h3>
                    :
                    <h3>Возвратов нет</h3>
                  )}
                </>
              ) : (
                <LoadingComponent />
              )}
            </Tab>
            <Tab eventKey="refundsProducts" title="Товары">
              {!refunds.loading ? (
                <>
                  {refunds.refundsProducts.length ? (
                    <MemoProducts
                      products={refunds.refundsProducts}
                      selectionByOrder={selectionByOrder}
                      setSelectionByOrder={setSelectionByOrder}
                      orderNumLinkHandler={toOrderProductsFilterOrderNumber}
                      toOrderFilterOrderNumber={toOrderFilterOrderNumber}
                    />
                  ) : (
                    refunds.error ? 
                    <h3>Ошибка запроса</h3>
                    :
                    <h3>Возвратов нет</h3>
                  )}
                </>
              ) : (
                <LoadingComponent />
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default RefundsPage;
