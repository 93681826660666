//@ts-nocheck
import React, { useState } from 'react';
import { Col, Table } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {
  IDataSearchByCarName,
  IDataSearchById,
  IInfoAboutProduct,
  useSearchInformation,
} from './searchInformation.hook';
import { LoadingComponent } from '../../components/LoadingComponent';
import { LightboxComp } from '../../components/lightbox';
import plus from '../../static/images/icons/plus.png';
import minus from '../../static/images/icons/minus.png';
import { useDispatch, useSelector } from 'react-redux';
import { sagaCartAdd, addToCartMessClose } from '../cart/actions';
import '../../styles/searchInformation/searchInformation.scss';

const AddToCartMess = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  return (
    <Toast
      onClose={() => dispatch(addToCartMessClose())}
      show={cart.showMess}
      delay={2000}
      autohide
      className="toast-addtocart"
    >
      <Toast.Body>{cart.addToCartMess}</Toast.Body>
    </Toast>
  );
};

export const SearchInformation = () => {
  const {
    data,
    loading,
    handleSearchByNameAuto,
    dataNameAuto,
    indexOfPage,
    handleGetDetailsAboutProduct,
    handleGetDetailsAboutProducts,
    handleGetDetailsAboutProductBack,
    infoAboutProduct,
    analogs,
    analogsIndirect,
    unverifiedAnalogs,
  } = useSearchInformation();

  const dispatch = useDispatch();

  const defaultOrderTime = 3;
  let [time, setTime] = useState(defaultOrderTime);

  // eslint-disable-next-line
  const [showDevComm, setShowDevComm] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [runLightbox, setRunLightbox] = useState([]);

  const handleClose = () => {
    setShow(false);
    setModalData(false);
  };

  const handleShow = (data) => {
    setModalData(data);
    setShow(true);
  };

  const ModalInfo = ({ show, setShow, handleClose, modalData }) => {
    if (modalData?.modalPrice) {
      const {
        description,
        skuNoSymbols,
        price,
        transportPerKg,
        weightSharp,
        weightAprox,
      } = modalData;

      return (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="info-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="info-modal_header">
              {`${description} ${skuNoSymbols}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-1 mb-3 info-modal_grid-table">
              <p className="font-weight-bold">Параметр</p>
              <p className="font-weight-bold">Значение</p>
              <p>Цена: </p>
              <p>{price}</p>
              {weightSharp === 0 && weightAprox === 0 ? (
                <>
                  <p>Вес: </p>
                  <p>
                    <span className="c-main-red">?</span>
                  </p>
                </>
              ) : weightSharp !== 0 ? (
                <>
                  <p>Вес точный: </p>
                  <p>
                    {weightSharp}
                    <span className="c-main-red">*</span>
                  </p>
                </>
              ) : (
                <>
                  <p>Вес ориентировочный: </p>
                  <p>
                    {weightAprox}
                    <span className="c-main-red">!</span>
                  </p>
                </>
              )}
              <p>Транспорт за 1кг: </p>
              <p>{transportPerKg}</p>
              {weightSharp === 0 && weightAprox === 0 ? (
                <>
                  <p>Транспорт итого: </p>
                  <p>0</p>
                </>
              ) : weightSharp !== 0 ? (
                <>
                  <p>Транспорт итого: </p>
                  <p>{(transportPerKg * weightSharp).toFixed(2)}</p>
                </>
              ) : (
                <>
                  <p>Транспорт итого: </p>
                  <p>{(transportPerKg * weightAprox).toFixed(2)}</p>
                </>
              )}
              {weightSharp === 0 && weightAprox === 0 ? (
                <>
                  <p>Цена с транспортом: </p>
                  <p>{price}</p>
                </>
              ) : weightSharp !== 0 ? (
                <>
                  <p>Цена с транспортом: </p>
                  <p>{(price + transportPerKg * weightSharp).toFixed(2)}</p>
                </>
              ) : (
                <>
                  <p>Цена с транспортом: </p>
                  <p>{(price + transportPerKg * weightAprox).toFixed(2)}</p>
                </>
              )}
            </div>
            <>
              {weightSharp === 0 && weightAprox === 0 ? (
                <>
                  <p>
                    <span className="c-main-red">? </span>
                    Данные по весу товара отсутствуют, просьба уточнять вес и
                    возможные затраты на транспорт у менеджера.
                  </p>
                </>
              ) : weightSharp !== 0 ? (
                <>
                  <p>
                    <span className="c-main-red">* </span>
                    Просим обратить ваше внимание, что в указанном весе детали
                    не учтён вес общей упаковки, в которой доставляется товар, а
                    для габаритных деталей кузова в целях сохранности при
                    транспортировке упаковка изготавливается индивидуально, что
                    также влияет на вес товара и стоимость его доставки. Для
                    разъяснения и уточнения возникших вопросов просьба связаться
                    с менеджером компании.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <span className="c-main-red">! </span>
                    Вес взят из открытых источников и нами не проверен, просьба
                    уточнять вес и возможные затраты на транспорт у менеджера.
                  </p>
                </>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} className="btn-main">
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      const { skuNoSymbols, concern, description, weightSharp, weightAprox } =
        modalData;

      return (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="info-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="info-modal_header">
              {`${skuNoSymbols} ${concern} ${description}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Другая кодировка запрошеной детали</p>
            <div className="mt-1 mb-3 info-modal_grid-table">
              <p className="font-weight-bold">Артикул</p>
              <p className="font-weight-bold">Комментарий</p>
              <p>{skuNoSymbols || ''}</p>
              <p></p>
            </div>
            {weightSharp ? (
              <>
                <p>
                  Вес точный:
                  <span className="font-weight-bold"> {weightSharp} </span>
                  кг
                  <span className="c-main-red"> *</span>
                </p>
                <p>
                  <span className="c-main-red">* </span>
                  Просим обратить ваше внимание, что в указанном весе детали не
                  учтён вес общей упаковки, в которой доставляется товар, а для
                  габаритных деталей кузова в целях сохранности при
                  транспортировке упаковка изготавливается индивидуально, что
                  также влияет на вес товара и стоимость его доставки. Для
                  разъяснения и уточнения возникших вопросов просьба связаться с
                  менеджером компании.
                </p>
              </>
            ) : (
              <>
                <p>
                  Вес ориентировочный:
                  <span className="font-weight-bold"> {weightAprox} </span>
                  кг
                  <span className="c-main-red"> *</span>
                </p>
                <p>
                  <span className="c-main-red">* </span>
                  Вес взят из открытых источников и нами не проверен, просьба
                  уточнять вес и возможные затраты на транспорт у менеджера.
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} className="btn-main">
              Закрыть
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  const ModalBtn = ({ click, data, children }) => {
    if (data?.modalPrice) {
      return (
        <button className="border-0 bg-transparent" onClick={() => click(data)}>
          {children}
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-link btn-sm icon-modal-btn"
          onClick={() => click(data)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </button>
      );
    }
  };

  const BuyArea = (item) => {
    const [count, setCount] = useState(1);
    item.item.count = count;

    const buyHandler = () => {
      dispatch(sagaCartAdd(item));
    };

    return (
      <>
        <input
          className="add_to_cart_input"
          type="number"
          min={item.item.quantity !== '0' ? 1 : null}
          max={!isNaN(item.item.quantity) ? parseInt(item.item.quantity) : null}
          defaultValue={item.item.quantity === '0' ? null : count}
          disabled={item.item.quantity === '0'}
          onChange={(e) => setCount(Number(e.target.value))}
        />
        шт
        <Button
          className="add_to_cart_btn"
          onClick={() => buyHandler()}
          disabled={
            count <= 0 ||
            item.item.quantity === '0' ||
            count > parseInt(item.item.quantity)
          }
        >
          <svg
            className={item.item?.quantity === 0 ? 'disable' : ''}
            width="30"
            height="25"
            viewBox="0 0 30 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.7812 15.5158H22.4846C22.7991 15.5158 23.0751 15.3078 23.1602 15.0057L25.9729 5.16169C26.0333 4.94949 25.9919 4.72159 25.8588 4.54519C25.7255 4.36939 25.5182 4.26559 25.2971 4.26559H8.158L7.6554 2.00389C7.584 1.68179 7.2983 1.45309 6.9688 1.45309H2.7031C2.3146 1.45309 2 1.76769 2 2.15619C2 2.54499 2.3146 2.85939 2.7031 2.85939H6.4044L8.9435 14.2852C8.1965 14.61 7.6719 15.3536 7.6719 16.2189C7.6719 17.382 8.6182 18.3283 9.7812 18.3283H22.4846C22.8733 18.3283 23.1877 18.0139 23.1877 17.6252C23.1877 17.2366 22.8733 16.9221 22.4846 16.9221H9.7812C9.394 16.9221 9.0781 16.6069 9.0781 16.2189C9.0781 15.8309 9.394 15.5158 9.7812 15.5158Z" />
            <path d="M9.07809 20.4377C9.07809 21.6009 10.0244 22.5471 11.1877 22.5471C12.3508 22.5471 13.2971 21.6009 13.2971 20.4377C13.2971 19.2746 12.3508 18.3283 11.1877 18.3283C10.0244 18.3283 9.07809 19.2746 9.07809 20.4377Z" />
            <path d="M18.9689 20.4377C18.9689 21.6009 19.9152 22.5471 21.0783 22.5471C22.2416 22.5471 23.1877 21.6009 23.1877 20.4377C23.1877 19.2746 22.2416 18.3283 21.0783 18.3283C19.9152 18.3283 18.9689 19.2746 18.9689 20.4377Z" />
          </svg>
        </Button>
      </>
    );
  };
  if (
    (indexOfPage === 0 && loading) ||
    (indexOfPage === 0 && !data) ||
    (indexOfPage === 0 && !data.length)
  ) {
    return <LoadingComponent />;
  }

  if (
    (indexOfPage === 1 && !dataNameAuto) ||
    (indexOfPage === 1 && !dataNameAuto.length)
  ) {
    return <LoadingComponent />;
  }

  if (indexOfPage === 2 && !infoAboutProduct) {
    return <LoadingComponent />;
  }

  if (indexOfPage === 3 && !infoAboutProduct) {
    return <LoadingComponent />;
  }

  const handleNotingDataArray = () => {
    return (
      <React.Fragment>
        <h3>По вашему запросу ничего не найдено...</h3>
        <p>Введите корректные данные</p>
      </React.Fragment>
    );
  };

  const TR = ({ index, value, name }) => {
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(false);

    const handleClose = () => {
      setShowModal(false);
      setModalData(false);
    };

    const handleShow = (data) => {
      setModalData(data);
      setShowModal(true);
    };

    return (
      <>
        {showModal && (
          <ModalInfo
            show={showModal}
            setShow={setShowModal}
            handleClose={handleClose}
            modalData={modalData}
          />
        )}
        {showDevComm && <span>Аналогі непрямі аналогі</span>}
        {value.filter((x) => x.wait <= time || time === 0).length ? (
          <tr key={index}>
            <td className="text-center align-middle">
              {show ? (
                <img
                  src={minus}
                  className="icon-plus"
                  alt="icon-plus"
                  onClick={(e) => setShow(false)}
                />
              ) : (
                <img
                  src={plus}
                  className="icon-minus"
                  alt="icon-minus"
                  onClick={(e) => setShow(true)}
                />
              )}
            </td>
            <td>{name[0]?.skuNoSymbols}</td>
            <td>{name[0]?.description}</td>
            <td></td>
            <td>{name[0]?.concern}</td>
            <td>от {Math.min(...name?.map((x) => x.price))}</td>
            <td>{name[0]?.quantity ? 'Есть' : 'Нет'}</td>
            <td>
              {Math.min(...name?.map((x) => x.wait))} -{' '}
              {Math.max(...name?.map((x) => x.wait))} дн.
            </td>
            <td></td>
          </tr>
        ) : null}
        {show &&
          value
            .filter((x) => x.wait <= time || time === 0)
            .map((x, index) => (
              <tr key={index}>
                <td></td>
                <td>{x?.skuNoSymbols}</td>
                <td>{x.description || 'Описание отсутствует'}</td>
                <td>
                  {(x?.weightAprox !== 0 || x?.weightSharp !== 0) && (
                    <>
                      <ModalBtn
                        click={handleShow}
                        data={{
                          skuNoSymbols: x?.skuNoSymbols,
                          concern: x?.concern,
                          description: x?.description,
                          weightSharp: x?.weightSharp,
                          weightAprox: x?.weightAprox,
                        }}
                      />
                    </>
                  )}
                </td>
                <td>{x.manufacturer || 'Нет информации'}</td>
                {x?.transportPerKg !== 0 ? (
                  <td>
                    {x?.transportPerKg !== 0 && x.price && (
                      <ModalBtn
                        click={handleShow}
                        data={{
                          modalPrice: true,
                          description: x?.description,
                          skuNoSymbols: x?.skuNoSymbols,
                          price: x?.originalPrice,
                          transportPerKg: x?.transportPerKg,
                          weightSharp: x?.weightSharp,
                          weightAprox: x?.weightAprox,
                        }}
                      >
                        {x?.weightSharp === 0 && x?.weightAprox === 0 ? (
                          <span className="text-d-underline">
                            <span className="c-string-blue">{`(${x.price} + T)`}</span>
                            <span className="c-main-red">?</span>
                          </span>
                        ) : x?.weightSharp !== 0 ? (
                          <span className="text-d-underline">
                            <span className="c-string-blue">{`(${x.price})`}</span>
                            <span className="c-main-red">*</span>
                          </span>
                        ) : (
                          <span className="text-d-underline">
                            <span className="c-string-blue">{`(${x.price})`}</span>
                            <span className="c-main-red">!</span>
                          </span>
                        )}
                      </ModalBtn>
                    )}
                  </td>
                ) : (
                  <td>{x.price || 'Информация отсутствует'}</td>
                )}
                <td>{x.quantity || 'Нет'}</td>
                <td>{x.wait || 'Нет информации'}</td>
                <td>
                  <BuyArea item={x} />
                </td>
              </tr>
            ))}
      </>
    );
  };

  const handleShowAnalogs = (
    array: IInfoAboutProduct[][] | IInfoAboutProduct[] | unknown[] | undefined
  ) => {
    if (!array || !array.length) {
      return <p>Нет информации</p>;
    }

    return (
      <Table striped bordered hover responsive>
        {showDevComm && <span>аналог у вікні конкретного продукту</span>}
        <thead>
          <tr>
            <th scope="col">Артикул</th>
            <th scope="col">Описание</th>
            <th scope="col">Инфо</th>
            <th scope="col">Производитель</th>
            <th scope="col">Цена</th>
            <th scope="col">Наличие</th>
            <th scope="col">Ожидание</th>
            <th scope="col">Количество</th>
          </tr>
        </thead>
        <tbody>
          {array.map((value, index) => {
            return (
              <tr key={index}>
                <td>{value?.skuNoSymbols}</td>
                <td>{value.description || 'Описание отсутствует'}</td>
                <td>
                  {(value?.weightAprox !== 0 || value?.weightSharp !== 0) && (
                    <ModalBtn
                      click={handleShow}
                      data={{
                        skuNoSymbols: value?.skuNoSymbols,
                        concern: value?.manufacturer,
                        description: value?.description,
                        weightSharp: value?.weightSharp,
                        weightAprox: value?.weightAprox,
                      }}
                    />
                  )}
                </td>
                <td>{value.manufacturer || 'Нет информации'}</td>
                <td>{value.sum || 'Информация отсутствует'}</td>
                <td>{value.number ?? 'Информация отсутствует'}</td>
                <td>{value.wait || 'Нет информации'}</td>
                <td>
                  <BuyArea item={value} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const handleShowAnalogsCustom = (
    array: IInfoAboutProduct[][] | IInfoAboutProduct[] | unknown[] | undefined
  ) => {
    if (!array || !array.length) {
      return <p>Нет информации</p>;
    }

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            <th scope="col">Артикул</th>
            <th scope="col">Описание</th>
            <th scope="col">Инфо</th>
            <th scope="col">Производитель</th>
            <th scope="col">Цена</th>
            <th scope="col">Наличие</th>
            <th scope="col">Ожидание</th>
            <th scope="col">Количество</th>
          </tr>
        </thead>
        <tbody>
          {array.map((value, index) => {
            return <TR key={index} value={value} name={value} index={index} />;
          })}
        </tbody>
      </Table>
    );
  };

  const handleInfoAboutProduct = () => {
    return (
      <>
        {showDevComm && <span>Вікно конкретного продукту</span>}
        <div className="text-right mb-3">
          <Button
            onClick={(e) => handleGetDetailsAboutProducts()}
            className="btn-second"
          >
            Под заказ
          </Button>
        </div>

        <Table striped bordered hover responsive>
          <thead>
            <tr test="sss">
              <th scope="col">Артикул</th>
              <th scope="col">Описание</th>
              <th scope="col">Инфо</th>
              <th scope="col">Производитель</th>
              <th scope="col">Цена</th>
              <th scope="col">Наличие</th>
              <th scope="col">Ожидание</th>
              <th scope="col">Количество</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(infoAboutProduct) ? (
              infoAboutProduct.map((x, ind) => (
                <tr key={ind}>
                  <td>{x?.skuNoSymbols}</td>
                  <td>{x?.description || 'Описание отсутствует'}</td>
                  <td>
                    {x?.info || 'Допольнительной информации нет'}
                    {(x?.weightAprox !== 0 || x?.weightSharp !== 0) && (
                      <ModalBtn
                        click={handleShow}
                        data={{
                          skuNoSymbols: x?.skuNoSymbols,
                          concern: x?.manufacturer,
                          description: x?.description,
                          weightSharp: x?.weightSharp,
                          weightAprox: x?.weightAprox,
                        }}
                      />
                    )}
                    <button
                      className="btn btn-sm icon-modal-btn"
                      onClick={() =>
                        infoAboutProduct?.images.length
                          ? setRunLightbox([...infoAboutProduct.images])
                          : null
                      }
                      style={
                        infoAboutProduct?.images.length
                          ? { cursor: 'pointer' }
                          : { cursor: 'default' }
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={
                          infoAboutProduct.images.length
                            ? 'currentColor'
                            : '#ccc'
                        }
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                        <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                      </svg>
                    </button>
                  </td>
                  <td>{x?.manufacturer || 'Нет информации'}</td>
                  <td>{x?.price || 'Информация отсутствует'}</td>
                  <td>{x?.quantity ?? 'Информация отсутствует'}</td>
                  <td>{x?.wait || 'Нет информации'}</td>
                  <td>
                    <BuyArea item={x} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{infoAboutProduct?.skuNoSymbols}</td>
                <td>
                  {infoAboutProduct?.description || 'Описание отсутствует'}
                </td>
                <td>
                  {(infoAboutProduct?.weightAprox !== 0 ||
                    infoAboutProduct?.weightSharp !== 0) && (
                    <ModalBtn
                      click={handleShow}
                      data={{
                        skuNoSymbols: infoAboutProduct?.skuNoSymbols,
                        concern: infoAboutProduct?.manufacturer,
                        description: infoAboutProduct?.description,
                        weightSharp: infoAboutProduct?.weightSharp,
                        weightAprox: infoAboutProduct?.weightAprox,
                      }}
                    />
                  )}
                  <button
                    className="btn btn-sm icon-modal-btn"
                    disabled={!infoAboutProduct?.images.length}
                    onClick={() => setRunLightbox([...infoAboutProduct.images])}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill={
                        infoAboutProduct.images.length ? 'currentColor' : '#ccc'
                      }
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                    </svg>
                  </button>
                </td>
                <td>{infoAboutProduct?.manufacturer || 'Нет информации'}</td>
                <td>{infoAboutProduct?.price || 'Информация отсутствует'}</td>
                <td>
                  {infoAboutProduct?.quantity ?? 'Информация отсутствует'}
                </td>
                <td>{infoAboutProduct?.wait || 'Нет информации'}</td>
                <td>
                  <BuyArea item={infoAboutProduct} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="mt-4">
          <h4 className="mb-2">Аналоги (заменители) для данного артикурла</h4>
          {handleShowAnalogs(analogs)}
        </div>
        <div className="mt-4">
          <h4 className="mb-2">Непрямые аналоги</h4>
          {handleShowAnalogs(analogsIndirect)}
        </div>
      </>
    );
  };

  const handleInfoAboutProducts = () => {
    return (
      <>
        {showDevComm && <span>Таблиця після натискання Під заказ</span>}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {Array.isArray(infoAboutProduct) &&
              infoAboutProduct.filter((x, i) => x.wait <= time || time === 0)
                .length > 10 ? (
                <th scope="col"></th>
              ) : null}
              <th scope="col">Артикул</th>
              <th scope="col">Описание</th>
              <th scope="col">Инфо</th>
              <th scope="col">Производитель</th>
              <th scope="col">Цена</th>
              <th scope="col">Наличие</th>
              <th scope="col">Ожидание</th>
              <th scope="col">Количество</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(infoAboutProduct) ? (
              <>
                {infoAboutProduct
                  .filter((x, i) => x.wait <= time || time === 0)
                  .filter((x, i) => i < 10)
                  .map((x, ind) => (
                    <tr key={ind}>
                      {infoAboutProduct.filter(
                        (x, i) => x.wait <= time || time === 0
                      ).length > 10 ? (
                        <td></td>
                      ) : null}
                      <td>{x?.skuNoSymbols}</td>
                      <td>{x?.description || 'Описание отсутствует'}</td>
                      <td>
                        {(x?.weightAprox !== 0 || x?.weightSharp !== 0) && (
                          <ModalBtn
                            click={handleShow}
                            data={{
                              skuNoSymbols: x?.skuNoSymbols,
                              concern: x?.concern,
                              description: x?.description,
                              weightSharp: x?.weightSharp,
                              weightAprox: x?.weightAprox,
                            }}
                          />
                        )}
                      </td>
                      <td>{x?.manufacturer || 'Нет информации'}</td>
                      {x?.transportPerKg !== 0 ? (
                        <td>
                          {x?.transportPerKg !== 0 && x.price && (
                            <ModalBtn
                              click={handleShow}
                              data={{
                                modalPrice: true,
                                description: x?.description,
                                skuNoSymbols: x?.skuNoSymbols,
                                price: x?.originalPrice,
                                transportPerKg: x?.transportPerKg,
                                weightSharp: x?.weightSharp,
                                weightAprox: x?.weightAprox,
                              }}
                            >
                              {x?.weightSharp === 0 && x?.weightAprox === 0 ? (
                                <span className="text-d-underline">
                                  <span className="c-string-blue">{`(${x.price} + T)`}</span>
                                  <span className="c-main-red">?</span>
                                </span>
                              ) : x?.weightSharp !== 0 ? (
                                <span className="text-d-underline">
                                  <span className="c-string-blue">{`(${x.price})`}</span>
                                  <span className="c-main-red">*</span>
                                </span>
                              ) : (
                                <span className="text-d-underline">
                                  <span className="c-string-blue">{`(${x.price})`}</span>
                                  <span className="c-main-red">!</span>
                                </span>
                              )}
                            </ModalBtn>
                          )}
                        </td>
                      ) : (
                        <td>{x.price || 'Информация отсутствует'}</td>
                      )}
                      <td>{x?.quantity ?? 'Информация отсутствует'}</td>
                      <td>{x?.wait || 'Нет информации'}</td>
                      <td>
                        <BuyArea item={x} />
                      </td>
                    </tr>
                  ))}
                {infoAboutProduct.filter((x, i) => x.wait <= time || time === 0)
                  .length > 10 ? (
                  <TR
                    value={infoAboutProduct
                      .filter((x, i) => x.wait <= time || time === 0)
                      .filter((x, i) => i >= 10)}
                    name={infoAboutProduct
                      .filter((x, i) => x.wait <= time || time === 0)
                      .filter((x, i) => i >= 10)}
                    index={0}
                  />
                ) : null}
              </>
            ) : (
              <tr>
                <td>{infoAboutProduct?.skuNoSymbols}</td>
                <td>
                  {infoAboutProduct?.description || 'Описание отсутствует'}
                </td>
                <td>
                  {(infoAboutProduct?.weightAprox !== 0 ||
                    infoAboutProduct?.weightSharp !== 0) && (
                    <ModalBtn
                      click={handleShow}
                      data={{
                        skuNoSymbols: infoAboutProduct?.skuNoSymbols,
                        concern: infoAboutProduct?.concern,
                        description: infoAboutProduct?.description,
                        weightSharp: infoAboutProduct?.weightSharp,
                        weightAprox: infoAboutProduct?.weightAprox,
                      }}
                    />
                  )}
                </td>
                <td>{infoAboutProduct?.manufacturer || 'Нет информации'}</td>
                <td>{infoAboutProduct?.price || 'Информация отсутствует'}</td>
                <td>
                  {infoAboutProduct?.quantity ?? 'Информация отсутствует'}
                </td>
                <td>{infoAboutProduct?.wait || 'Нет информации'}</td>
                <td>
                  <BuyArea item={infoAboutProduct} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="mt-4">
          <h4 className="mb-2">Аналоги (заменители) для данного артикурла</h4>
          {handleShowAnalogsCustom(analogs)}
        </div>
        <div className="mt-4">
          <h4 className="mb-2">Непрямые аналоги</h4>
          {handleShowAnalogsCustom(analogsIndirect)}
        </div>

        <div className="mt-4">
          <h4 className="mb-2">Непроверенные аналоги</h4>
          {handleShowAnalogsCustom(unverifiedAnalogs)}
        </div>
      </>
    );
  };

  const handleSearchByNameCar = () => {
    return (
      <>
        {showDevComm && <span>Вікно після виробників</span>}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">Артикул</th>
              <th scope="col">Описание</th>
              <th scope="col">Инфо</th>
              <th scope="col">Производитель</th>
              <th scope="col">Детализация...</th>
            </tr>
          </thead>
          <tbody>
            {dataNameAuto &&
              dataNameAuto.map((value: IDataSearchByCarName, index: number) => {
                return (
                  <tr
                    key={index}
                    className="ishovered"
                    onClick={() => handleGetDetailsAboutProduct(value.code)}
                  >
                    <th scope="row">{value.article}</th>
                    <td>{value.description}</td>
                    <td>{value.info}</td>
                    <td>{value.brand}</td>
                    <td>
                      <button className="btn btn-link btn-sm text-black-50">
                        Подробнее...
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </>
    );
  };

  const handleSearchById = () => {
    return (
      <>
        {showDevComm && <span>Виробники</span>}
        <Table striped bordered hover responsive>
          <tbody>
            {data &&
              data.map((value: IDataSearchById, index: number) => {
                return (
                  <tr
                    key={index}
                    className="ishovered"
                    onClick={() => handleSearchByNameAuto(value.name)}
                  >
                    <td style={{ padding: 7 }}>
                      <button className="btn btn-link btn-sm text-black-50">
                        {value.name}
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </>
    );
  };

  const mainHandleSearch = () => {
    switch (indexOfPage) {
      case 3: {
        return handleInfoAboutProducts();
      }
      case 2: {
        return handleInfoAboutProduct();
      }
      case 1: {
        return handleSearchByNameCar();
      }
      case 0: {
        return handleSearchById();
      }
      case -1: {
        return handleNotingDataArray();
      }
      default: {
        return handleNotingDataArray();
      }
    }
  };

  const handleSetHeader = () => {
    switch (indexOfPage) {
      case 3: {
        return 'ПОД ЗАКАЗ';
      }
      case 1: {
        return 'ДЕТАЛИЗАЦИЯ ПОИСКА';
      }
      case 0: {
        return `ПРОИЗВОДИТЕЛИ: ${data?.length || 0}`;
      }
      case -1: {
        return 'РЕЗУЛЬТАТ ПОИСКА';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <>
      <div className="container d-flex justify-content-center">
        <div
          style={indexOfPage === 0 ? { minWidth: '260px' } : { width: '100%' }}
        >
          <Col>
            <div className="search-header">
              {indexOfPage === 3 && (
                <div
                  onClick={(e) => handleGetDetailsAboutProductBack()}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {'<< Вернуться к подбору по остаткам'}
                </div>
              )}
              {indexOfPage === 3 && (
                <Form.Group>
                  <Form.Control
                    as="select"
                    custom
                    className="rounded-0"
                    onChange={(e) => setTime(+e.target.value)}
                    value={time}
                  >
                    <option value={0}>ALL</option>
                    <option value={1}>1-го дня</option>
                    <option value={2}>2-х дней</option>
                    <option value={3}>3-х дней</option>
                    <option value={7}>7-х дней</option>
                    <option value={10}>10-и дней</option>
                    <option value={14}>14-ти дней</option>
                    <option value={21}>21-го дня</option>
                    <option value={30}>30-ти дней</option>
                  </Form.Control>
                </Form.Group>
              )}
            </div>
            <h4 className="mb-2">{handleSetHeader()}</h4>
            {mainHandleSearch()}
          </Col>
        </div>
      </div>

      {show && (
        <ModalInfo
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          modalData={modalData}
        />
      )}
      <AddToCartMess />
      <LightboxComp runLightbox={runLightbox} setRunLightbox={setRunLightbox} />
    </>
  );
};
