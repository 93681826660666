import { useState, useEffect, useMemo } from 'react';
import { Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import ModalForm from './ModalComment';

type Props = {
  refunds: [any];
  toOrderProductsFilterOrderNumber: Function;
  selectionByProduct: string;
  setSelectionByProduct: Function;
};

const Refunds = (props: Props) => {
  const {
    refunds,
    toOrderProductsFilterOrderNumber,
    selectionByProduct,
    setSelectionByProduct,
  } = props;

  const [paginationPages, setPaginationPages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const [time, setTime] = useState<number>(1);
  const [searchTime, setSearchTime] = useState<number>(0);
  const [searchRefundNumber, setSearchRefundNumber] = useState<string>('');
  const [refundStatuses, setRefundStatuses] = useState<any>({});

  const makeStatuses = () => {
    const statusesNewSet = new Set();
    refunds.forEach((item) =>
      !item.status ? null : statusesNewSet.add(item.status)
    );
    const statusesArr = Array.from(statusesNewSet);
    const statuses = {};
    statusesArr.forEach((item: any) => {
      statuses[item] = false;
    });
    setRefundStatuses(statuses);
  };

  useEffect(() => {
    makeStatuses();
  }, []);

  const dropFilters = () => {
    setSearchRefundNumber('');
    setTimeHandler(100);
    makeStatuses();
  };

  useEffect(() => {
    if (selectionByProduct) {
      dropFilters();
      setSearchRefundNumber(selectionByProduct);
    }
  }, [selectionByProduct]);

  const setTimeHandler = (monthValue) => {
    if (monthValue === 100) {
      setTime(monthValue);
      setSearchTime(0);
      return;
    }
    setTime(monthValue);
    const currDate = new Date();
    currDate.setMonth(currDate.getMonth() - monthValue);
    const selectedDateTimeStamp = currDate.getTime();
    setSearchTime(selectedDateTimeStamp);
  };

  const filteredIsChecked = useMemo(() => {
    const isChecked: string[] = [];

    Object.entries(refundStatuses).map(([key, value]) => {
      if (value) {
        isChecked.push(key);
      }
    });

    return isChecked;
  }, [refundStatuses]);

  const callbackFilter = (el) => {
    if (!filteredIsChecked.length) {
      return true;
    }

    for (let i = 0; i < filteredIsChecked.length; i++) {
      if (filteredIsChecked[i] === el.status) {
        return true;
      }
    }

    return false;
  };

  const filteredRefunds = useMemo(() => {
    const filteredData = refunds
      .filter((refunds) => {
        return refunds.number.includes(searchRefundNumber);
      })
      .filter((refunds) => {
        return new Date(refunds.date).getTime() >= searchTime;
      })
      .filter(callbackFilter);
    setPage(1);

    return filteredData;
  }, [searchRefundNumber, searchTime, refundStatuses]);

  const paginationRefunds = useMemo(() => {
    let pages: any[] = [];
    for (
      let number = 1;
      number <= Math.ceil(filteredRefunds.length / itemsPerPage);
      number++
    ) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => setPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPaginationPages([...pages]);

    if (pages.length < page) {
      setPage(pages.length);
    }

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const currentData = filteredRefunds.slice(start, end);

    return currentData;
  }, [filteredRefunds, page, itemsPerPage]);

  useEffect(() => {
    setTimeHandler(1);
  }, []);

  return (
    <>
      <ModalForm show={show} setShow={setShow} modalData={modalData} />
      <Row>
        <Form.Group className="mb-3 col-12 d-flex align-items-center">
          <span style={{ marginRight: '0.75rem' }}>Статус заказа:</span>
          {Object.entries(refundStatuses).map(([key, value], ind) => {
            return (
              <Form.Check
                key={ind}
                inline
                label={key}
                name={`status-${ind + 1}`}
                type="checkbox"
                // @ts-ignore:next-line
                checked={value}
                id={`statusId-${ind + 1}`}
                onChange={() =>
                  setRefundStatuses({ ...refundStatuses, [key]: !value })
                }
              />
            );
          })}
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6">
          <Form.Control
            placeholder="Введите номер возврата"
            onChange={(e) => setSearchRefundNumber(e.target.value)}
            value={searchRefundNumber}
            // @ts-ignore:next-line
            disabled={selectionByProduct}
            className="rounded-0"
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setTimeHandler(+e.target.value)}
            value={time}
          >
            <option value={1}>1 месяц</option>
            <option value={3}>3 месяца</option>
            <option value={6}>6 месяцев</option>
            <option value={12}>Год</option>
            <option value={100}>Всё время</option>
          </Form.Control>
        </Form.Group>
        {selectionByProduct && (
          <div className="mb-3 col-6">
            Отбор по возврату: {selectionByProduct}
            <span
              className="selection-by text-d-underline"
              onClick={() => {
                setSearchRefundNumber('');
                setSelectionByProduct('');
              }}
            >
              Показать все возвраты
            </span>
          </div>
        )}
      </Row>
      <Table striped bordered hover responsive className="document-table">
        <thead>
          <tr>
            <th>Номер возврата</th>
            <th>Дата возврата</th>
            <th>Сумма с учётом удержаний</th>
            <th>Зачтено в баланс</th>
            <th>Cтатус возврата</th>
            <th>Склад</th>
            <th>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          {paginationRefunds.map((refund, ind) => {
            return (
              <tr key={ind}>
                <td
                  onClick={() =>
                    toOrderProductsFilterOrderNumber(refund.number)
                  }
                  className="order-number-link text-d-underline"
                >
                  {refund.number}
                </td>
                <td>{new Date(refund.date).toLocaleDateString()}</td>
                <td>
                  {refund.sum} {refund.currency}
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <input
                      type="checkbox"
                      checked={refund.acceptedInBalance}
                      disabled
                    />
                  </div>
                </td>
                <td>{refund.status}</td>
                <td>{refund.storage}</td>
                <td
                  onClick={() => {
                    if (refund.comment) {
                      setModalData(refund.comment);
                      setShow(true);
                    }
                  }}
                >
                  {refund.comment && (
                    <div className="comment">{refund.comment}</div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex overflow-auto flex-wrap">
        <Form.Group
          style={{ marginRight: '0.75rem' }}
          className="flex-shrink-0 mt-3"
        >
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setItemsPerPage(+e.target.value)}
            value={itemsPerPage}
          >
            <option value={10}>10 строк</option>
            <option value={15}>15 строк</option>
            <option value={20}>20 строк</option>
            <option value={30}>30 строк</option>
            <option value={50}>50 строк</option>
          </Form.Control>
        </Form.Group>

        {paginationPages.length > 1 && (
          <Pagination className="mt-3">{paginationPages}</Pagination>
        )}
      </div>
    </>
  );
};

export default Refunds;
