import produce from 'immer';
import { LOGIN_SUCCESS, LOGIN_ERROR } from '../const';
export const initialState = {
  error: false,
  errorText: '',
  isLoading: true,
  user: { role: 'User', name: 'User' },
  isLogged: false,
};

export const getLoginSelector = (state) => ({ ...state.login });

const loginReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_SUCCESS:
        draft.isLogged = true;
        draft.isLoading = false;

        localStorage.setItem('accessToken', action.value.accessToken);
        localStorage.setItem('refreshToken', action.value.refreshToken);
        localStorage.setItem('accessExpires', action.value.accessExpires);
        localStorage.setItem('refreshExpires', action.value.refreshExpires);
        draft.user = action.value;
        if (draft.user.role === 'Admin' && draft.user.name === 'Admin') {
          // localStorage.setItem("editmode", "true");
          localStorage.setItem('user', draft.user.role);
        }

        break;
      case LOGIN_ERROR:
        draft.errorText = action.value.status;
        break;
      default:
        return state;
    }
  });

export default loginReducer;
