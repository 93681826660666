import {
  GET_REFUNDS,
  GET_REFUNDS_SAGA,
  REFUNDS_LOADING,
  GET_REFUNDS_PRODUCTS_SAGA,
  GET_REFUNDS_PRODUCTS,
  SET_ERROR,
} from '../const';

export function getRefunds(data) {
  return {
    type: GET_REFUNDS,
    payload: data,
  };
}

export function getRefundsSaga() {
  return {
    type: GET_REFUNDS_SAGA,
  };
}

export function getRefundsProductsSaga() {
  return {
    type: GET_REFUNDS_PRODUCTS_SAGA,
  };
}

export function refundsLoading(data) {
  return {
    type: REFUNDS_LOADING,
    payload: data,
  };
}

export function getRefundsProducts(data) {
  return {
    type: GET_REFUNDS_PRODUCTS,
    payload: data,
  };
}

export function setError() {
  return {
    type: SET_ERROR,
  };
}
