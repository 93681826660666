import React, { useState, useRef, useEffect } from "react";
// reactjs draftjs
import {
  EditorState,
  ContentState,
  convertToRaw
} from "../../../../node_modules/draft-js";
import { Editor } from "../../../../node_modules/react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// word with edited data
import draftToHtml from "../../../../node_modules/draftjs-to-html";
import htmlToDraft from "../../../../node_modules/html-to-draftjs";
import ReactHtmlParser from "../../../../node_modules/react-html-parser";
import { Button } from "react-bootstrap";
type Props = {
  id: string;
  text: string;
  lang: string;
  onChanged: Function;
  onEdited: Function;
};

const EditorComponent = (props: Props) => {
  const { text, lang, onChanged, onEdited } = props;
  const [editorState, setEditorState] = useState(null as any);
  // const [contentState, setContentState] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const domEditor = useRef(null);

  /* const clickEditor = () => {
     setShowEditor(!showEditor);
   };
 */
  useEffect(() => {
    const blocksFromHtml = htmlToDraft(text);
    const { contentBlocks, entityMap } = blocksFromHtml;
    /* setContentState(
       ContentState.createFromBlockArray(contentBlocks, entityMap)
     );
 */
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap)
      )
    );
  }, [text]);

  useEffect(() => {
    if (localStorage.getItem("editmode") === "true") {
      setShowButton(true);
    } else setShowButton(false);
  }, []);

  const onEditorStateChange = edState => {
    setEditorState(edState);
    onChanged(
      lang,
      draftToHtml(convertToRaw(editorState!.getCurrentContent()))
    );
  };

  const onContentStateChange = conState => {
    // setContentState(conState);
    onEdited(true);
    // editorResult: contentState,
  };

  /* const saveData = () => {
     if (editorState == null) {
     } else {
       const textToSave = draftToHtml(
         convertToRaw(editorState!.getCurrentContent())
       );
       // onSavePage('about', textToSave)
     }
   };*/
  const buttonColor = {
    backgroundColor: "#962932",
    color: "white",
    border: "none",
    outline: "none",
    button: "focus {outline:0;}"
  };

  return (
    <>
      {showButton && (
        <div>
          <Button
            style={buttonColor}
            onClick={() => setShowEditor(!showEditor)}
          >
            Edit {lang}
          </Button>
        </div>
      )}
      {showEditor && (
        <Editor
          ref={domEditor}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          onEditorStateChange={onEditorStateChange}
          onContentStateChange={onContentStateChange}
          ariaLabel={"Русский"}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true }
            // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
          }}
        />
      )}
      <div style={{ height: "20px" }} />
      {!showEditor && ReactHtmlParser(text)}
    </>
  );
};

export default EditorComponent;
