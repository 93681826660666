import { call, put, takeLatest } from 'redux-saga/effects';
import { getInvoices, invoicesLoading, getInvoicesProducts, setError } from '../actions';
import {
  GET_INVOICES_SAGA,
  GET_INVOICES_PRODUCTS_SAGA,
  SEND_FORM_INVOICE_PRODUCTS_SAGA,
} from '../const';
import getLanguage from '../../../../utils/getLanguage';

const API = process.env.REACT_APP_API_URL;
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

async function request(url, options) {
  const response = await fetch(url, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return { status: response.status, msg: false };
  }
}

export function* sendFormInvoiceProductsSaga(action) {
  const data = action.payload;

  // { name: 'Запрос на возврат', value: '1', type: 'returnrequest' },
  // { name: 'Запрос на реализацию', value: '2', type: 'implementationrequest' },
  // { name: 'Задать вопрос менеджеру', value: '3', type: 'askquestion' },
  // { name: 'Запрос о рекламации', value: '4', type: 'complaintrequest' },

  const sufixUrl = {
    returnrequest: 'Order/returnrequest',
    implementationrequest: 'Order/implementationrequest',
    complaintrequest: 'Order/complaintrequest',
    askquestion: 'Order/askquestion',
  };

  const requestURL = API + sufixUrl[data.type];
  const options = {
    method: 'POST',
    body: JSON.stringify({
      text: data.text,
      cOrder: data.cOrder,
      orderNumber: data.orderNumber,
    }),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(setError());
    }
  } catch (err) {
    yield put(setError());
  }
}

export function* getInvoicesSaga() {
  yield put(invoicesLoading(true));

  const requestURL = `${API}Order/invoices`;
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(invoicesLoading(false));
      yield put(setError());
    } else {
      yield put(invoicesLoading(false));
      yield put(getInvoices(prod));
    }
  } catch (err) {
    yield put(invoicesLoading(false));
    yield put(setError());
  }
}

export function* getInvoicesProductsSaga() {
  yield put(invoicesLoading(true));

  const requestURL = `${API}Order/invoices/goods`;
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(invoicesLoading(false));
      yield put(setError());
    } else {
      yield put(invoicesLoading(false));
      yield put(getInvoicesProducts(prod));
    }
  } catch (err) {
    yield put(invoicesLoading(false));
    yield put(setError());
  }
}

export default function* aboutSaga() {
  yield takeLatest(GET_INVOICES_SAGA, getInvoicesSaga);
  yield takeLatest(GET_INVOICES_PRODUCTS_SAGA, getInvoicesProductsSaga);
  yield takeLatest(
    SEND_FORM_INVOICE_PRODUCTS_SAGA,
    sendFormInvoiceProductsSaga
  );
}
