import produce from 'immer';
import { GET_REFUNDS, REFUNDS_LOADING, GET_REFUNDS_PRODUCTS, SET_ERROR } from '../const';

export const initialState = {
  refunds: [],
  refundsProducts: [],
  isGetRefunds: false,
  isGetOrdersRefunds: false,
  loading: false,
  error: false,
};

const profileRefundsReducer = (state = initialState, action) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case GET_REFUNDS:
        if (Array.isArray(action.payload)) {
          draft.refunds = action.payload.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          draft.isGetRefunds = true;
        }
        break;
      case REFUNDS_LOADING:
        draft.loading = action.payload;
        break;
      case GET_REFUNDS_PRODUCTS:
        if (Array.isArray(action.payload)) {
          draft.refundsProducts = action.payload.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          draft.isGetRefundsProducts = true;
        }
        break;
      case SET_ERROR:
        draft.error = true;
        break;
      default:
        return state;
    }
  });

export default profileRefundsReducer;
