import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersSaga, getOrdersProductsSaga } from './actions';
import { LoadingComponent } from '../../../components/LoadingComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState } from 'react';
import Orders from './component/Orders';
import Products from './component/Products';
import { memo } from 'react';
import '../../../styles/cabinet/tables-pages.scss';
import { useLocation } from 'react-router-dom';

interface DefaultRootState {
  profileOrders: {
    orders: [any];
    ordersProducts: [any];
    isGetOrders: boolean;
    isGetOrdersProducts: boolean;
    loading: boolean;
    error: boolean;
  };
}

const MemoOrders = memo(Orders);
const MemoProducts = memo(Products);

const OrdersPage = () => {
  const [tab, setTab] = useState<string>('orders');
  const [selectionByOrder, setSelectionByOrder] = useState<string>('');
  const [selectionByProduct, setSelectionByProduct] = useState<string>('');

  const orders = useSelector((state: DefaultRootState) => state.profileOrders);
  const dispatch = useDispatch();

  const { search } = useLocation<any>();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const orderNumber = queryParams.get('orderNumber');
    if (orderNumber) {
      toOrderFilterOrderNumber(orderNumber);
    }
  }, []);

  const toOrderFilterOrderNumber = useCallback((num) => {
    setTab('orders');
    setSelectionByProduct(num);
  }, []);

  const toOrderProductsFilterOrderNumber = useCallback((num) => {
    setTab('products');
    setSelectionByOrder(num);
  }, []);

  useEffect(() => {
    if (!orders.isGetOrders) {
      dispatch(getOrdersSaga());
    }
    if (!orders.isGetOrdersProducts) {
      dispatch(getOrdersProductsSaga());
    }
  }, []);

  return (
    <Container fluid className="profile-orders">
      <Row className="justify-content-between mb-2">
        <Col>
          <h2 className="text-uppercase">Мои заказы</h2>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col>
          <Tabs
            id="controlled-tab"
            activeKey={tab}
            onSelect={(k: any) => setTab(k)}
            className="mb-3"
          >
            <Tab eventKey="orders" title="Заказы">
              {!orders.loading ? (
                <>
                  {orders.orders.length ? (
                    <MemoOrders
                      orders={orders.orders}
                      toOrderProductsFilterOrderNumber={
                        toOrderProductsFilterOrderNumber
                      }
                      selectionByProduct={selectionByProduct}
                      setSelectionByProduct={setSelectionByProduct}
                    />
                  ) : (
                    orders.error ? 
                    <h3>Ошибка запроса</h3>
                    :
                    <h3>Заказов нет</h3>
                  )}
                </>
              ) : (
                <LoadingComponent />
              )}
            </Tab>
            <Tab eventKey="products" title="Товары">
              {!orders.loading ? (
                <>
                  {orders.ordersProducts.length ? (
                    <MemoProducts
                      products={orders.ordersProducts}
                      selectionByOrder={selectionByOrder}
                      setSelectionByOrder={setSelectionByOrder}
                      orderNumLinkHandler={toOrderProductsFilterOrderNumber}
                      toOrderFilterOrderNumber={toOrderFilterOrderNumber}
                    />
                  ) : (
                    orders.error ? 
                    <h3>Ошибка запроса</h3>
                    :
                    <h3>Заказов нет</h3>
                  )}
                </>
              ) : (
                <LoadingComponent />
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default OrdersPage;
