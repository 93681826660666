import React, { useState, useEffect } from "react";
import {
  Row,
  Button,
  Modal,
  Col,
  Container,
  Form,
} from "react-bootstrap";
import { Base64 } from "js-base64";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/navigation/Breadcrumbs";

import NewsComponent from "../../components/News";
import PaginationComponent from "../../components/PaginationComponent";

type Props = {
  newsArr: any;
  totalPages: number;
  editednews: any;
  loadNext: Function;
  langArr: [];
  targetArr: [];
  targetOfArr: [];
  saveData: Function;
  onDelete: Function;
  getTargetOf: Function;
  getTargetReg: Function;
  getAdminNews: Function;
  onEdit: Function;
  cleanEdited: Function;
};
const buttonColor = {
  backgroundColor: "#962932",
  color: "white",
  border: "none",
  outline: "none",
  button: "focus {outline:0;}"
};
const News = (props: Props) => {
  const [modalShow, setModalShow] = useState(false);
  function ModalOffice(props) {
    const { langarr } = props;

    const [title, setTitle] = useState("");
    const [localizations, setLocalozation] = useState<any[]>([]);
    const [changedValue, setChanging] = useState(false);
    const [date, setDate] = useState(new Date());
    const [auth, setAuth] = useState(false);
    const [nonauth, setNonAuth] = useState(false);
    const [target, setTarget] = useState(0);
    const [targetError, setTargetError] = useState(false);
    const [authError, setAuthError] = useState(false);

    // targetOfArr auth  nonauth
    const [selectedtarget, setselectedTarget] = useState<any[]>([]);

    useEffect(() => {
      async function createLocalization() {
        let arr: Array<any> = [];
        for (let i = 0; i < langarr.length; i++) {
          let arrITem = {
            language: "",
            name: "",
            nameErr: true,
            annotation: "",
            annotationErr: true,
            contents: "",
            contentsErr: true
          };
          arrITem.language = await langarr[i].id;
          arr.push(arrITem);
        }
        setLocalozation(arr);
      }

      if (editednews) {
        setLocalozation(editednews.localizations);
        setTitle(editednews.name);
        setAuth(editednews.isAuthenticated);
        setNonAuth(editednews.isNotAuthenticated);
        setTarget(editednews.target);
        setselectedTarget(editednews.targets);
        setModalShow(true);
        setDate(editednews.date);
      } else {
        createLocalization();
      }
    }, [langarr]);

    function setValue(value, type, lang) {
      for (let i = 0; i < localizations.length; i++) {
        if (localizations[i].language === lang) {
          if (type === "contents") {
            value = Base64.encode(value);
          }
          let errField = type + "Err";
          localizations[i][type] = value;

          if (value.length > 0) {
            localizations[i][errField] = false;
          } else {
            localizations[i][errField] = true;
          }
        }
      }
      setLocalozation(localizations);
      setChanging(!changedValue);
    }
    function saveNews() {
      let selected: Array<string> = [];
      for (var i = 0; i < selectedtarget.length; i++) {
        selected.push(selectedtarget[i].value);
      }

      let retVal = {
        id: null,
        name: title,
        date: date,
        IsAuthenticated: auth,
        IsNotAuthenticated: nonauth,
        target: target,
        targets: selected,
        localizations: localizations
      };
      // targetOfArr auth  nonauth
      if (targetOfArr.length > 0 && (auth === true || nonauth === true)) {
        if (localizations.includes(true) === false) {
          saveData(retVal);
          setModalShow(false);
        } else {
          alert("Заполните все поля новости");
        }
      } else {
        if (targetOfArr.length === 0) {
          setTargetError(true);
        }
        if (auth === false && nonauth === false) {
          setAuthError(true);
        }
      }
      cleanEdited();
      // onSaveData();
    }

    const onChange = date => {
      setDate(date);
    };
    const authChange = e => {
      //alert(e.target.checked)
      setAuth(e.target.checked);
      setAuthError(false);
    };
    const nonauthChange = e => {
      //alert(e.target.checked)
      setNonAuth(e.target.checked);
      setAuthError(false);
    };
    //alert(e.target.checked)cleanEditedNews
    const groupChanged = e => {
      setTarget(Number.parseInt(e.target.value));
      setselectedTarget([]);
    };


    function handleMultiChange(option) {
      // let difference = selectedtarget.filter(x => !option.includes(x));
      setselectedTarget(option);
      setTargetError(false);
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onEntered={function () {
          console.log("onEntered ");
        }}
        onExit={function () {
          console.log("onExit    ");
          cleanEdited();
        }}
        onExiting={function () {
          console.log("onExiting ");
        }}
        onExited={function () {
          console.log("onExited  ");
        }}
        onEnter={() => console.log("onEnter   ")}
        onEntering={function () {
          console.log("onEntering");
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Добавить Новость
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12">
              <Form.Label>Имя новости</Form.Label>
              <Form.Control
                type="text"
                placeholder="Имя"
                value={title}
                onInput={e => setTitle(e.target.value)}
              />
              <div
                style={{
                  height: "2px",
                  width: "750px",
                  backgroundColor: "black",
                  marginTop: "20px"
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Form.Group>
                {localizations.map((local, index2) => (
                  <>
                    <div style={{ height: "2px", width: "300px" }} />
                    <div>
                      <span> Язык : {local.language}</span>
                    </div>
                    <Form.Label>
                      Короткое описание новости : {local.language}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Имя"
                      value={local.name}
                      onInput={e =>
                        setValue(e.target.value, "name", local.language)
                      }
                    />
                    {local.nameErr === true && (
                      <div>
                        <Form.Label>
                          <span style={{ color: "red" }}>заполните имя</span>
                        </Form.Label>
                      </div>
                    )}
                    <Form.Label>Аннотация : {local.language}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Анотация"
                      value={local.annotation}
                      onInput={e =>
                        setValue(e.target.value, "annotation", local.language)
                      }
                    />
                    {local.annotationErr === true && (
                      <div>
                        <Form.Label>
                          <span style={{ color: "red" }}>
                            заполните анотацию новости
                          </span>
                        </Form.Label>
                      </div>
                    )}
                    <Form.Label>Новость : {local.language}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={local.contents}
                      placeholder="Полный текст"
                      onInput={e =>
                        setValue(e.target.value, "contents", local.language)
                      }
                    />
                    {local.contentsErr === true && (
                      <div>
                        <Form.Label>
                          <span style={{ color: "red" }}>
                            заполните полный текст
                          </span>
                        </Form.Label>
                      </div>
                    )}
                    <div
                      style={{
                        height: "2px",
                        width: "750px",
                        backgroundColor: "black",
                        marginTop: "20px"
                      }}
                    />
                  </>
                ))}

                <Form.Label>Выбор даты </Form.Label>
                <DateTimePicker onChange={onChange} value={date} />

                <Form.Check
                  type="checkbox"
                  id="1"
                  label="для авторизованных"
                  checked={auth}
                  onChange={authChange}
                />

                <Form.Check
                  type="checkbox"
                  id="2"
                  checked={nonauth}
                  label="для неавторизованных"
                  onChange={nonauthChange}
                />
                {authError === true && (
                  <div>
                    <Form.Label>
                      <span style={{ color: "red" }}>
                        выберите один из чекбоксов
                      </span>
                    </Form.Label>
                  </div>
                )}

                <Form.Label>Целевая группа</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={groupChanged}
                  value={target}
                  style={{ marginBottom: "10px" }}
                >
                  <option key={0} value={0} />
                  <option key={1} value={1}>
                    All
                  </option>
                  <option key={2} value={2}>
                    Regions
                  </option>
                  <option key={3} value={3}>
                    Offices
                  </option>
                  <option key={4} value={4}>
                    Managers
                  </option>
                </Form.Control>
                {targetError === true && (
                  <div>
                    <Form.Label>
                      <span style={{ color: "red" }}>выберите группу</span>
                    </Form.Label>
                  </div>
                )}
                {target === 2 && (
                  <Select
                    isMulti
                    name="filter"
                    placeholder="select Value"
                    value={selectedtarget}
                    options={targetArr}
                    onChange={handleMultiChange}
                  />
                )}
                {target === 3 && (
                  <Select
                    isMulti
                    name="filter"
                    placeholder="select Value"
                    value={selectedtarget}
                    options={targetOfArr}
                    onChange={handleMultiChange}
                  />
                )}
                {targetError === true && (
                  <div>
                    <Form.Label>
                      <span style={{ color: "red" }}>выберите значение</span>
                    </Form.Label>
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={buttonColor}
            onClick={() => {
              saveNews();
              cleanEdited();
              setModalShow(false);
            }}
          >
            Сохранить
          </Button>
          <Button
            style={buttonColor}
            onClick={() => {
              props.onHide();
              cleanEdited();
              setModalShow(false);
            }}
          >
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const {
    newsArr,
    langArr,
    saveData,
    editednews,
    targetArr,
    targetOfArr,
    getAdminNews,
    totalPages,
    loadNext,
    onDelete,
    onEdit,
    cleanEdited
  } = props;
  const [showEditor, setShowEditor] = useState(false);
  //const [isChanged, setisChanged] = useState(false);
  //const [fixedArr, setFixedArr] = useState([] as any);

  useEffect(() => {
    if (localStorage.getItem("editmode") === "true") {
      setShowEditor(true);
      getAdminNews(0);
    } else setShowEditor(false);
  }, [getAdminNews]);

  const onContentChanged = (lang, text) => {
    //let hasValue = false;
    /*for (var i = 0; i < fixedArr.length; i = +1) {
      if (fixedArr[i].lang === lang) {
        fixedArr[i].editedText = text;
        hasValue = true;
        break;
      }
    }
    if (!hasValue) {
      fixedArr.push({ lang: lang, editedText: text });
    }*/
  };

  const onContentEdited = val => {
    onEdit(val);
  };
  const onNewsDeleted = id => {
    onDelete(id);
  };

  const [count, setCount] = useState<number[]>([]);
  let countArr: Array<number> = [];
  useEffect(() => {
    for (var i = 0; i < totalPages; i++) {
      countArr.push(i + 1);
    }
    setCount(countArr);
  }, [totalPages, countArr]);

  const onSelect = e => {
    loadNext(e);
  };
  return (
    <div>
      <Breadcrumbs />
      <Container>
        {totalPages > 1 && (
          <PaginationComponent count={count} active={0} onSelect={onSelect} />
        )}
        <ModalOffice
          show={modalShow}
          onHide={() => {
            cleanEdited();
            setModalShow(false);
          }}
          langarr={langArr}
        />
        {showEditor && (
          <Row>
            <Button
              style={buttonColor}
              onClick={() => setModalShow(!modalShow)}
            >
              Добавить новость
            </Button>
          </Row>
        )}
        {showEditor &&
          newsArr.map(function (item, index) {
            return (
              <NewsComponent
                key={item.id}
                item={item}
                onChanged={onContentChanged}
                onEdited={onContentEdited}
                onDelete={onNewsDeleted}
              />
            );
          })}
      </Container>
      <Footer />
    </div>
  );
};

export default News;
