import produce from "immer";
import {
  LANGUAGE,
  GET_ADMIN_NEWS,
  GET_NEWS_SUCCESS,
  POST_NEWS,
  GET_ERROR,
  FETCH_CONTACTS,
  GET_TARGET_SUCCESS,
  GET_TARGETOF_SUCCESS,
  GET_TARGET_ERROR,
  GET_NEWS_EDITED,
  GET_NEWS_EDITEDCLEAN
} from "../const";

var targetArrType: Array<any> = [];

export const initialState = {
  error: false,
  errorText: "",
  isLoading: true,
  goToEditState: false,
  contact: "",
  newsArr: [],
  langArr: [
    { id: "en", name: "АНГ" },
    { id: "ru", name: "РУС" },
    { id: "uk", name: "УКР" }
  ],
  langArrLocal: [],
  targetArr: targetArrType,
  targetOfArr: targetArrType,
  currentPage: 0,
  pageSize: 0,
  totalItems: 0,
  totalPages: 0,
  editednews: null
};

export const getNewsSelector = state => ({ ...state.newsadmin });

const newsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ADMIN_NEWS:
        console.log("GET_PAGE");

        break;
      case GET_NEWS_SUCCESS:
        if (action.value) {
          draft.isLoading = false;

          if (action.value.items !== null) {
            draft.newsArr = action.value.items;
            draft.currentPage = action.value.currentPage;
            draft.pageSize = action.value.pageSize;
            draft.totalItems = action.value.totalItems;
            draft.totalPages = action.value.totalPages;
          }
        } else {
          draft.isLoading = false;
        }
        break;
      case POST_NEWS:
        break;
      case GET_TARGET_SUCCESS:
        let retValArr: Array<any> = [];
        for (var i = 0; i < action.value.length; i++) {
          let retValItem = { label: "", value: "" };
          retValItem.label = action.value[i].name;
          retValItem.value = action.value[i].id;
          retValArr.push(retValItem);
        }
        console.log(JSON.stringify(retValArr));
        draft.targetArr = retValArr;
        break;
      case GET_TARGETOF_SUCCESS:
        let retValOfArr: Array<any> = [];
        for (var j = 0; j < action.value.length; j++) {
          let retValItem = { label: "", value: "" };
          retValItem.label = action.value[j].name;
          retValItem.value = action.value[j].id;
          retValOfArr.push(retValItem);
        }
        console.log(JSON.stringify(retValOfArr));
        draft.targetOfArr = retValOfArr;
        break;
      case GET_TARGET_ERROR:
        break;
      case FETCH_CONTACTS:
        draft.contact = action.contact.contact.name;
        break;
      case GET_ERROR:
        alert(action.value);
        break;
      case GET_NEWS_EDITED:
        let arr: Array<any> = [];
        for (let i = 0; i < action.value.localizations.length; i++) {
          let arrITem = {
            language: action.value.localizations[i].language,
            name: action.value.localizations[i].name,
            nameErr: true,
            annotation: action.value.localizations[i].annotation,
            annotationErr: true,
            contents: action.value.localizations[i].contents,
            contentsErr: true
          };
          arr.push(arrITem);
        }
        action.value.localizations = arr;
        draft.editednews = action.value;
        break;
      case GET_NEWS_EDITEDCLEAN:
        draft.editednews = null;
        break;
      case LANGUAGE:
        // draft.langArr = action.language;
        break;
      default:
        return state;
    }
  });

export default newsReducer;
