import React from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../../styles/mainpage/benefits.scss";
import Logo1 from "../../static/images/slider/1.png";
import Logo2 from "../../static/images/slider/2.png";
import Logo3 from "../../static/images/slider/3.png";

type MyProps = {};
type MyState = {
  first: boolean;
  second: boolean;
  third: boolean;
  width: number;
};
class MainSlider extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);
    this.state = {
      first: true,
      second: false,
      third: false,
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    // const self = this;
    const settings = {
      dots: false,
      fade: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 6000,
      autoplaySpeed: 6000,
      pauseOnHover: false,
      afterChange: function (index) {
        // self.startMorphing(index);
      },
      slidesToShow: 3,
      slidesToScroll: 3
    };
    // const { width } = this.state;
    //const isMobile = width <= 500;
    // 	this.toggleChecked();
    return (
      <Slider {...settings} className="main-slider">
        <div className="h-100 first">
          <Row>
            <Col className="align-self-center" style={{ height: 370 }}>
              <img src={Logo1} width="100%" alt="firstpage" />
            </Col>
          </Row>
        </div>
        <div className="h-100 first">
          <Row>
            <Col className="align-self-center" style={{ height: 370 }}>
              <img src={Logo2} width="100%" alt="firstpage" />
            </Col>
          </Row>
        </div>
        <div className="h-100 first">
          <Row>
            <Col className="align-self-center" style={{ height: 370 }}>
              <img src={Logo3} width="100%" alt="firstpage" />
            </Col>
          </Row>
        </div>
      </Slider>
    );
  }
}

export default MainSlider;
