import React from "react";
import { Container, Row } from "react-bootstrap";

import ContactBlock from "./contactblock";

const Offices = (props: any) => {
  return (
    <Container className="margin-top-70">
      {props.offices &&
        props.offices.map((office, index) => (
          <Row className="margin-bottom-50" key={index}>
            {office.map((oneOffice, index2) => (
              <ContactBlock
                key={index2}
                id={oneOffice.id}
                comments={oneOffice.comments}
                name={oneOffice.name}
                contacts={oneOffice.contacts}
                address={oneOffice.address}
              />
            ))}
          </Row>
        ))}
    </Container>
  );
};

export default Offices;
