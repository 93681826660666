import { useState, useEffect, useMemo } from 'react';
import { Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalForm from './ModalComment';

type Props = {
  products: [any];
  selectionByOrder: string;
  setSelectionByOrder: Function;
  orderNumLinkHandler: Function;
  toOrderFilterOrderNumber: Function;
};

const Products = (props: Props) => {
  const {
    products,
    selectionByOrder,
    setSelectionByOrder,
    toOrderFilterOrderNumber,
  } = props;

  const [paginationPages, setPaginationPages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const [time, setTime] = useState<number>(1);
  const [searchTime, setSearchTime] = useState<number>(0);
  const [searchSku, setSearchSku] = useState<string>('');
  const [orderStatuses, setOrderStatuses] = useState<any>({});
  const [maker, setMaker] = useState<number>(0);

  const makeStatuses = () => {
    const statusesNewSet = new Set();
    products.forEach((item) =>
      !item.status ? null : statusesNewSet.add(item.status)
    );
    const statusesArr = Array.from(statusesNewSet);
    const statuses = {};
    statusesArr.forEach((item: any) => {
      statuses[item] = false;
    });
    setOrderStatuses(statuses);
  };

  const dropFilters = () => {
    setSearchSku('');
    setTimeHandler(100);
    setMaker(0);
    makeStatuses();
  };

  useEffect(() => {
    makeStatuses();
  }, []);

  useEffect(() => {
    if (selectionByOrder) {
      dropFilters();
    }
  }, [selectionByOrder]);

  const setTimeHandler = (monthValue) => {
    if (monthValue === 100) {
      setTime(monthValue);
      setSearchTime(0);
      return;
    }
    setTime(monthValue);
    const currDate = new Date();
    currDate.setMonth(currDate.getMonth() - monthValue);
    const selectedDateTimeStamp = currDate.getTime();
    setSearchTime(selectedDateTimeStamp);
  };

  const makers = useMemo(() => {
    const makersNewSet = new Set();
    products.forEach((item) => makersNewSet.add(item.maker));
    const makers = Array.from(makersNewSet).sort();
    return makers;
  }, []);

  const filteredIsChecked = useMemo(() => {
    const isChecked: string[] = [];

    Object.entries(orderStatuses).map(([key, value]) => {
      if (value) {
        isChecked.push(key);
      }
    });

    return isChecked;
  }, [orderStatuses]);

  const callbackFilter = (el) => {
    if (!filteredIsChecked.length) {
      return true;
    }

    for (let i = 0; i < filteredIsChecked.length; i++) {
      if (filteredIsChecked[i] === el.status) {
        return true;
      }
    }

    return false;
  };

  const filteredProducts = useMemo(() => {
    const filteredData = products
      .filter((product) => {
        return (
          product.number.includes(selectionByOrder) &&
          product.sku.includes(searchSku) &&
          (maker === 0 || maker === 1
            ? product
            : product.maker.includes(makers[maker - 2]))
        );
      })
      .filter((invoice) => {
        return new Date(invoice.date).getTime() >= searchTime;
      })
      .filter(callbackFilter);
    setPage(1);

    return filteredData;
  }, [searchTime, searchSku, maker, orderStatuses, selectionByOrder]);

  const paginationProducts = useMemo(() => {
    let pages: any[] = [];
    for (
      let number = 1;
      number <= Math.ceil(filteredProducts.length / itemsPerPage);
      number++
    ) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => setPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPaginationPages([...pages]);

    if (pages.length < page) {
      setPage(pages.length);
    }

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const currentData = filteredProducts.slice(start, end);

    return currentData;
  }, [filteredProducts, page, itemsPerPage]);

  useEffect(() => {
    setTimeHandler(1);
  }, []);

  return (
    <>
      <ModalForm show={show} setShow={setShow} modalData={modalData} />
      <Row>
        <Form.Group className="mb-3 col-12 d-flex align-items-center">
          <span style={{ marginRight: '0.75rem' }}>
            Статус товара в заказе:
          </span>
          {Object.entries(orderStatuses).map(([key, value], ind) => {
            return (
              <Form.Check
                key={ind}
                inline
                label={key}
                name={`status-${ind + 100}`}
                type="checkbox"
                // @ts-ignore:next-line
                checked={value}
                id={`statusId-${ind + 100}`}
                onChange={() =>
                  setOrderStatuses({ ...orderStatuses, [key]: !value })
                }
              />
            );
          })}
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            placeholder="Введите номер артикула товара"
            onChange={(e) => setSearchSku(e.target.value)}
            value={searchSku}
            className="rounded-0"
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setTimeHandler(+e.target.value)}
            value={time}
          >
            <option value={1}>1 месяц</option>
            <option value={3}>3 месяца</option>
            <option value={6}>6 месяцев</option>
            <option value={12}>Год</option>
            <option value={100}>Всё время</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setMaker(+e.target.value)}
            value={maker}
          >
            <option value={0} disabled>
              Выберите производителя
            </option>
            <option value={1}>Все</option>
            {/* {makers.map((item: any, ind) => (
              <option key={ind} value={ind + 2}>
                {item}
              </option>
            ))} */}
            {makers.map((item: any, ind) => {
              const foundObject = filteredProducts.find(
                (obj: any) => obj.maker === item
              );
              if (foundObject) {
                return (
                  <option key={ind} value={ind + 2}>
                    {item}
                  </option>
                );
              }
              return null;
            })}
          </Form.Control>
        </Form.Group>
        {selectionByOrder && (
          <div className="mb-3 col-12">
            Отбор по возврату: {selectionByOrder}
            <span
              className="selection-by text-d-underline"
              onClick={() => setSelectionByOrder('')}
            >
              Показать все товары
            </span>
          </div>
        )}
      </Row>
      <Table striped bordered hover responsive className="products-table h-100">
        <thead>
          <tr>
            <th>Возврат</th>
            <th>Дата возврата</th>
            <th className="word-wrap">Артикул</th>
            <th>Производитель</th>
            <th className="word-wrap">Наименование</th>
            <th className="p-0 text-center">
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-disabled">Отметка о габарите</Tooltip>
                }
              >
                <div className="table-popover table-head-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001 6.971 2.789Zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z"
                    />
                  </svg>
                </div>
              </OverlayTrigger>
            </th>
            <th>Кол-во</th>
            <th>Цена, шт</th>
            <th>Удержано, %</th>
            <th>Причина возврата</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {paginationProducts.map((product, ind) => {
            return (
              <tr key={ind}>
                <td>
                  <Dropdown className="product-orders-link d-flex">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {product.number}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey="1"
                        // @ts-ignore:next-line
                        disabled={selectionByOrder}
                        onClick={() => setSelectionByOrder(product.number)}
                      >
                        Поазать все товары по данному возврату
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => toOrderFilterOrderNumber(product.number)}
                      >
                        Показать детали возврата
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td>{new Date(product.date).toLocaleDateString()}</td>
                <td className="word-wrap">
                  {product.sku}{' '}
                  {product.replacementSKU
                    ? `(замена на ${product.replacementSKU})`
                    : null}
                </td>
                <td>
                  {product.maker}{' '}
                  {product.replacementMaker
                    ? `(замена на ${product.replacementMaker})`
                    : null}
                </td>
                <td className="word-wrap">
                  {product.name}{' '}
                  {product.replacementName
                    ? `(замена на ${product.replacementName})`
                    : null}
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <input type="checkbox" checked={product.gabarit} disabled />
                  </div>
                </td>
                <td>{product.quantity}</td>
                <td>
                  {product.price} {product.currency}
                </td>
                <td>{product.hold}</td>
                <td
                  onClick={() => {
                    if (product.reasonForReturn) {
                      setModalData(product.reasonForReturn);
                      setShow(true);
                    }
                  }}
                >
                  {product.reasonForReturn && (
                    <div className="comment">{product.reasonForReturn}</div>
                  )}
                </td>
                <td>{product.status}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex overflow-auto flex-wrap">
        <Form.Group
          style={{ marginRight: '0.75rem' }}
          className="flex-shrink-0 mt-3"
        >
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setItemsPerPage(+e.target.value)}
            value={itemsPerPage}
          >
            <option value={10}>10 строк</option>
            <option value={15}>15 строк</option>
            <option value={20}>20 строк</option>
            <option value={30}>30 строк</option>
            <option value={50}>50 строк</option>
          </Form.Control>
        </Form.Group>
        {paginationPages.length > 1 && (
          <Pagination className="mt-3">{paginationPages}</Pagination>
        )}
      </div>
    </>
  );
};

export default Products;
