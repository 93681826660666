import { call, put, takeLatest } from 'redux-saga/effects';
import { getOrders, ordersLoading, getOrdersProducts, setError } from '../actions';
import {
  GET_ORDERS_SAGA,
  GET_ORDERS_PRODUCTS_SAGA,
  SEND_FORM_ORDERS_PRODUCTS_SAGA,
} from '../const';
import getLanguage from '../../../../utils/getLanguage';

const API = process.env.REACT_APP_API_URL;
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

async function request(url, options) {
  const response = await fetch(url, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return { status: response.status, msg: false };
  }
}

export function* sendFormOrdersProductsSaga(action) {
  const data = action.payload;

  //   { name: 'Отказаться от товара', value: '1', type: 'reject' },
  //   { name: 'Перезаказать товар', value: '2', type: 'reorder' },
  //   { name: 'Не перезаказывать товар', value: '3', type: 'notreorder,' },
  //   { name: 'Задать вопрос менеджеру', value: '4', type: 'askquestion' },

  const sufixUrl = {
    reject: 'Order/reject',
    reorder: 'Order/reorder',
    notreorder: 'Order/notreorder',
    askquestion: 'Order/askquestion',
  };

  const requestURL = API + sufixUrl[data.type];
  const options = {
    method: 'POST',
    body: JSON.stringify({
      text: data.text,
      cOrder: data.cOrder,
      orderNumber: data.orderNumber,
    }),
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(setError());
    }
  } catch (err) {
    yield put(setError());
  }
}

export function* getOrdersSaga() {
  yield put(ordersLoading(true));

  const requestURL = API + 'Order/all';
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(ordersLoading(false));
      yield put(setError());
    } else {
      yield put(ordersLoading(false));
      yield put(getOrders(prod));
    }
  } catch (err) {
    yield put(ordersLoading(false));
    yield put(setError());
  }
}

export function* getOrdersProductsSaga() {
  yield put(ordersLoading(true));

  const requestURL = API + 'Order/goods';
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(ordersLoading(false));
      yield put(setError());
    } else {
      yield put(ordersLoading(false));
      yield put(getOrdersProducts(prod));
    }
  } catch (err) {
    yield put(ordersLoading(false));
    yield put(setError());
  }
}

export default function* aboutSaga() {
  yield takeLatest(GET_ORDERS_SAGA, getOrdersSaga);
  yield takeLatest(GET_ORDERS_PRODUCTS_SAGA, getOrdersProductsSaga);
  yield takeLatest(SEND_FORM_ORDERS_PRODUCTS_SAGA, sendFormOrdersProductsSaga);
}
