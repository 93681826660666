export const regions = [
  {
    "name": "Выберите регион"
  },
  {
    "name": "Винницкая область",
  },
  {
    "name": "Волынская область",
  },
  {
    "name": "Днепропетровская область",
  },
  {
    "name": "Житомирская область",
  },
  {
    "name": "Закарпатская область",
  },
  {
    "name": "Запорожская область",
  },
  {
    "name": "Ивано-Франковская область",
  },
  {
    "name": "Киевская область",
  },
  {
    "name": "Кировоградская область",
  },
  {
    "name": "Львовская область",
  },
  {
    "name": "Николаевская область",
  },
  {
    "name": "Одесская область",
  },
  {
    "name": "Полтавская область",
  },
  {
    "name": "Ровенская область",
  },
  {
    "name": "Сумская область",
  },
  {
    "name": "Тернопольская область",
  },
  {
    "name": "Харьковская область",
  },
  {
    "name": "Херсонская область",
  },
  {
    "name": "Хмельницкая область",
  },
  {
    "name": "Черкасская область",
  },
  {
    "name": "Черниговская область",
  },
  {
    "name": "Черновицкая область",

  }
]

export const EMAIL_REGEXP = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;

export const buttonColor = {
  backgroundColor: "#962932",
  color: "white",
  border: "none",
  outline: "none",
  button: "focus {outline:0;}"
};

export const buttonHeaderActive = {
  backgroundColor: "#962932",
  color: "white",
  border: "none",
  outline: "none",
  width: 200,
  cursor: "pointer"
};

export const buttonHeader = {
  backgroundColor: "#fff",
  border: "1px solid #962932",
  borderWidth: 1,
  color: "#962932",
  outline: "none",
  width: 200,
  cursor: "pointer"
};
export const errorText = {
  color: "red",
};

export const notificationStyle = {
  color: "green",
};

export const typeOfPerson = ["Частное лицо", "Автомагазин", "СТО", "Авторазборка", "Транспортная компания", "Интернет магазин"];
