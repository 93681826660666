import connect from 'react-redux-connect-lifecycle';
import Page from '../Page';
import { getRegisterSelector } from '../reducer';
import { saveData, login, googleLogin } from '../actions';
//import { registerSuccess } from "../../login/actions";

const mapDispatchToProps = (dispatch) => ({
  onComponentDidMount: async (props) => {
    // dispatch(getTargetRegion());
  },
  onComponentWillUnmount: (props) => {
    console.log('component register will unmount');
  },
  saveData: (data) => dispatch(saveData(data)),
  onLogin: (data) => {
    dispatch(login(data), localStorage.setItem('editmode', 'true'));
  },
  onGoogleLogin: (data) => {
    dispatch(googleLogin(data), localStorage.setItem('editmode', 'true'));
  },
});

const mapStateToProps = (state) => getRegisterSelector(state);

export default connect(mapStateToProps, mapDispatchToProps)(Page);
