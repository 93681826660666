import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

type Props = {
  show: boolean;
  setShow: Function;
  modalData: string;
};

const ModalOrderProductForm = (props: Props) => {
  const { show, setShow, modalData } = props;

  const close = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="info-modal product-options"
    >
      <Modal.Header closeButton>
        <Modal.Title className="info-modal_header">Комментарий</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-2" style={{ fontSize: '16px' }}>
          {modalData}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} className="btn-main">
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOrderProductForm;
