import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import profile from '../../static/images/icons/profile.png';
import price from '../../static/images/icons/price.png';
import basket from '../../static/images/icons/basket.png';
import otch from '../../static/images/icons/otch.png';
import bal from '../../static/images/icons/bal.png';
import ask from '../../static/images/icons/ask.png';
import msg from '../../static/images/icons/msg.png';
import reklam from '../../static/images/icons/reklam.png';
import refund from '../../static/images/icons/refund.png';
import send from '../../static/images/icons/send.png';
import orders from '../../static/images/icons/orders.png';
import '../../styles/navigation/navigation.scss';

function CabinetNavigation() {
  const [isCabinetOpen, setIsCabinetOpen] = useState(false);

  const _onClick = () => {
    setIsCabinetOpen(!isCabinetOpen);
  };

  return (
    <>
      <Nav className="flex-column SidebarMenu" style={{ maxWidth: 250 }}>
        <div onClick={() => _onClick()} className="navProfileWrapper">
          <div>
            <img src={profile} alt="" />
          </div>
          <div className="navProfileLink">
            <p id="firstParagraph">Мой кабинет</p>
          </div>
        </div>
        {isCabinetOpen && (
          <div className="navProfileDropDown">
            <div>
              <Link to="/cabinet/main">Главная страница</Link>
            </div>
            <div>
              <Link to="/cabinet/profile">Настройка учетной записи</Link>
            </div>
          </div>
        )}
        <div className="navProfileWrapper">
          <div>
            <img src={basket} alt="basket" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/cart" className="nav-linkcabinet">
              Корзина
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={orders} alt="orders" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/cabinet/orders" className="nav-linkcabinet">
              Заказы
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={send} alt="send" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/cabinet/invoices" className="nav-linkcabinet">
              Расходные накладные
            </NavLink>
          </div>
        </div>
        {/* <div className="navProfileWrapper">
          <div>
            <img src={send} alt="send" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Отгрузки
            </NavLink>
          </div>
        </div> */}
        <div className="navProfileWrapper">
          <div>
            <img src={refund} alt="refund" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/cabinet/refunds" className="nav-linkcabinet">
              Возвраты
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={reklam} alt="reklam" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Рекламации
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={msg} alt="msg" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Сообщения
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={ask} alt="ask" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Вопрос/ответ
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={bal} alt="bal" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Баланс
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={otch} alt="otch" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Отчеты
            </NavLink>
          </div>
        </div>
        <div className="navProfileWrapper">
          <div>
            <img src={price} alt="price" />
          </div>
          <div className="navProfileLink">
            <NavLink to="/catalog" className="nav-linkcabinet">
              Прайс-лист
            </NavLink>
          </div>
        </div>
      </Nav>
    </>
  );
}

export default CabinetNavigation;
