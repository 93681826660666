import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../../utils/request";
import getToken from "../../../utils/tokenchecker";
import {
  getPageSuccess,
  getPageError,
  getContactsSuccess,
  getLanguageSuccess
} from "../actions";
import { GET_PAGE, PUT_PAGE, GET_LANGUAGE } from "../const";
import {
  API_STATIC_PAGE,
  API_CONTACTS,
  API_LANGUAGE,
  API_STATIC_PAGE_ADMIN,
  API_OFFICE_PUT
} from "../../../utils/settings";
import getLanguage from "../../../utils/getLanguage";

export function* putData(action) {
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    const { content } = action;
    // let savedContent = "";
    /* Object.entries(content).map(([key, value]) => {
       savedContent = content[key];
     });*/
    const requestURL = API_STATIC_PAGE_ADMIN;
    const options = {
      method: "PUT",
      body: JSON.stringify(content),
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.token}`
      }
    };
    try {
      const log = yield call(request, requestURL, options);
      if (log.success === true) {
        console.log("successfuly saved");
        // yield put(getPageError(log.status, log.value));
      } else {
        // yield put(getPageSuccess(log.value));
      }
      return log;
    } catch (err) {
      yield put(getPageError("id", "connection", err));
    }
  } else {
  }
}

// /api/v1/Contents/view/{id}
export function* getData(action) {
  const { id } = action;
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    let requestURL = API_STATIC_PAGE + id;

    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        prefered: "true"
      }
    };
    if (localStorage.getItem("editmode") === "true") {
      requestURL = API_STATIC_PAGE_ADMIN + id;
      options.headers["Authorization"] = `Bearer ${tokenObj.token}`;
    }
    try {
      const log = yield call(request, requestURL, options);

      if (log.contents === null || log.contents === undefined) {
        yield put(getPageError(id, log.id, log.contents));
      } else {
        yield put(getPageSuccess(id, log.contents));
      }
      return log;
    } catch (err) {
      yield put(getPageError(id, "connection", err));
    }
  }
}

export function* getContacts(action) {
  const { mRegion } = action;
  const requestURL = API_CONTACTS + "?ip=" + mRegion.ip;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      prefered: "true"
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    if (log.name) {
      yield put(getContactsSuccess(log));
    } else {
      yield put(getPageError("contacts", log.id, log.contents));
    }
    return log;
  } catch (err) {
    yield put(getPageError("contacts", "connection", err));
  }
}

export function* getLanguages() {
  const requestURL = API_LANGUAGE;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      prefered: "true"
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    if (log.name) {
      yield put(getLanguageSuccess(log));
    } else {
      yield put(getPageError("contacts", log.id, log.contents));
    }
    return log;
  } catch (err) {
    yield put(getPageError("contacts", "connection", err));
  }
}

export function* addOffice(action) {
  const { title, position, address, contacts, localizations } = action.office;
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    const requestURL = API_OFFICE_PUT;
    const options = {
      method: "POST",
      body: JSON.stringify({
        id: null,
        name: title,
        address: address,
        latitude: position.lat,
        longitude: position.lng,
        comments: null,
        contacts: contacts,
        localizations: localizations
      }),
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.token}`
      }
    };
    try {
      const log = yield call(request, requestURL, options);
      if (log.success === true) {
        console.log("successfuly saved");
        // yield put(getPageError(log.status, log.value));
      } else {
        // yield put(getPageSuccess(log.value));
      }
      return log;
    } catch (err) {
      yield put(getPageError("id", "connection", err));
    }
  } else {
  }
}

export function* deleteOffice(action) {
  const { id } = action;
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    const requestURL = API_OFFICE_PUT + "/" + id;
    const options = {
      method: "DELETE",
      body: JSON.stringify({
        id: id
      }),
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.token}`
      }
    };
    try {
      const log = yield call(request, requestURL, options);
      window.location.reload();
      if (log.success === true) {
        console.log("successfuly deleted");
        // yield put(getPageError(log.status, log.value));
      } else {
        // yield put(getPageSuccess(log.value));
      }
      return log;
    } catch (err) {
      yield put(getPageError("id", "connection", err));
    }
  } else {
  }
}
export default function* aboutSaga() {
  yield takeLatest(GET_PAGE, getData);
  yield takeLatest(GET_LANGUAGE, getLanguages);
  yield takeLatest(PUT_PAGE, putData);
  yield takeLatest("GET_CONTACTS", getContacts);
  yield takeLatest("ADDOFFICE", addOffice);
  yield takeLatest("DELETEOFFICE", deleteOffice);
  // yield takeLatest("GET_CURRENCY", getCurrency);
  //yield takeLatest(GET_ABOUTPAGE, getCoordinates);
}
