import React, { useState, useEffect } from "react";
import { Container, Row, Button, Form } from "react-bootstrap";
import { Base64 } from "js-base64";
import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/navigation/Breadcrumbs";
import ReactHtmlParser from "../../../node_modules/react-html-parser";
import EditorComponent from "./component/Editor";

type Props = {
  id: string;
  page: string;
  error: boolean;
  errorText: string;
  isLoading: boolean;
  text: string;
  textArr: any;
  onSavePage: Function;
  contact: string;
};

const PageComponent = (props: Props) => {
  const {
    text,
    onSavePage,
    id,
    textArr,
  } = props;
  const [showEditor, setShowEditor] = useState(false);
  const [name, setName] = useState("");
  const [isChanged, setisChanged] = useState(false);
  // const [fixedArr, setFixedArr] = useState([] as any);
  const fixedArr = { ...textArr };

  useEffect(() => {
    if (localStorage.getItem("editmode") === "true") {
      setShowEditor(true);
      setName(textArr.name);
    } else setShowEditor(false);
  }, [textArr.name]);

  const saveData = () => {
    //onSavePage('about', '')
    fixedArr.name = name;
    onSavePage(fixedArr);
  };

  const onContentChanged = (lang, text) => {
    // alert(lang+text);
    // let hasValue = false;
    for (var i = 0; i < fixedArr.length; i = +1) {
      if (fixedArr.localizations[i].language === lang) {
        fixedArr.localizations[i].contents = Base64.encode(text);
        // hasValue = true;
        break;
      }
    }
  };

  const onContentEdited = val => {
    setisChanged(val);
  };
  const buttonColor = {
    backgroundColor: "#962932",
    color: "white",
    border: "none",
    outline: "none",
    button: "focus {outline:0;}"
  };
  return (
    <div>
      <Breadcrumbs />
      <Container>
        {isChanged && (
          <Button style={buttonColor} onClick={() => saveData()}>
            Сохранить изменения{" "}
          </Button>
        )}
        <div />
        {showEditor && (
          <>
            <Form.Label>Page name</Form.Label>
            <Form.Control
              type="text"
              placeholder="name"
              onChange={e => {
                setName(e.target.value);
                setisChanged(true);
              }}
              value={name}
            />
          </>
        )}
        {showEditor &&
          textArr.localizations.map(function (item, index) {
            return (
              <EditorComponent
                key={item.language}
                text={Base64.decode(item.contents)}
                lang={item.language}
                id={id}
                onChanged={onContentChanged}
                onEdited={onContentEdited}
              />
            );
          })}
        {!showEditor && <Row>{ReactHtmlParser(text)}</Row>}
      </Container>
      <Footer />
    </div>
  );
};

export default PageComponent;
