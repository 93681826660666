import { POST_LOGINPAGE, LOGIN_SUCCESS, LOGIN_ERROR, POST_REGISTER } from "../const";

export function postLogin(login, pwd, lang, func,) {
  return {
    type: POST_LOGINPAGE,
    login,
    pwd,
    lang,
    func
  };
}

export function postRegister(name, surname, phone, region, email, mtype, city, company, password) {
  return {
    type: POST_REGISTER,
    name, surname, phone, region, email, mtype, city, company, password
  };
}

export function loginSuccess(value) {
  return {
    type: LOGIN_SUCCESS,
    value
  };
}

export function loginError(value) {
  return {
    type: LOGIN_ERROR,
    value
  };
}


export function registerSuccess(value) {
  return {
    type: LOGIN_SUCCESS,
    value
  };
}


export function registerError(value) {
  return {
    type: LOGIN_ERROR,
    value
  };
};
