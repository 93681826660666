import { Col, Form, InputGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSearchHook } from './search.hook';

// importing css
import '../../styles/search/search-form.scss';

const SearchForm = () => {
  let history = useHistory();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(`/search?id=${info}`);
    }
  };

  const {
    handleChange,
    valueSearch,
    info,
    toggleHistory,
    state,
    historyArray,
    handleChooseOneFromHistory,
    handleEmptySearchHistory,
  } = useSearchHook();

  return (
    <Form.Row>
      <Form.Group as={Col}>
        <InputGroup>
          <Form.Control
            className="search-input align-self-center"
            type="text"
            placeholder="№ или шрих-код запчасти"
            onChange={(e) => handleChange(e.target.value)}
            value={valueSearch}
            onKeyDown={handleKeyDown}
          />
          <InputGroup.Prepend className="search-input-button">
            <NavLink
              className="newicon search"
              to={`/search?id=${info}`}
            ></NavLink>
          </InputGroup.Prepend>
          <div
            className="search-history-icon d-none d-xl-block d-lg-block"
            onClick={toggleHistory}
          />
          {state.isPressedShowHistory && (
            <div className="search-history" id="history">
              {historyArray.length ? (
                historyArray.map((value: string, index: number) => {
                  return (
                    <li
                      onClick={() => handleChooseOneFromHistory(value)}
                      key={index}
                    >
                      {value}
                    </li>
                  );
                })
              ) : (
                <li onClick={handleEmptySearchHistory}>История поиска пуста</li>
              )}
            </div>
          )}
        </InputGroup>
      </Form.Group>
    </Form.Row>
  );
};

export default SearchForm;
