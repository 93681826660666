import { call, put, takeLatest } from 'redux-saga/effects';
import { getRefunds, refundsLoading, getRefundsProducts, setError } from '../actions';
import { GET_REFUNDS_SAGA, GET_REFUNDS_PRODUCTS_SAGA } from '../const';
import getLanguage from '../../../../utils/getLanguage';

const API = process.env.REACT_APP_API_URL;
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

async function request(url, options) {
  const response = await fetch(url, options);
  if (response.status === 200) {
    return response.json();
  } else {
    return { status: response.status, msg: false };
  }
}

export function* getRefundsSaga() {
  yield put(refundsLoading(true));

  const requestURL = API + 'Order/returns';
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(refundsLoading(false));
      yield put(setError());
    } else {
      yield put(refundsLoading(false));
      yield put(getRefunds(prod));
    }
  } catch (err) {
    yield put(refundsLoading(false));
    yield put(setError());
  }
}

export function* getOrdersProductsSaga() {
  yield put(refundsLoading(true));

  const requestURL = API + 'Order/returns/goods';
  const options = {
    headers: {
      accept: 'application/json',
      'Accept-Language': getLanguage().toLowerCase(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const prod = yield call(request, requestURL, options);

    if (prod.msg === false) {
      yield put(refundsLoading(false));
      yield put(setError());
    } else {
      yield put(refundsLoading(false));
      yield put(getRefundsProducts(prod));
    }
  } catch (err) {
    yield put(refundsLoading(false));
    yield put(setError());
  }
}

export default function* aboutSaga() {
  yield takeLatest(GET_REFUNDS_SAGA, getRefundsSaga);
  yield takeLatest(GET_REFUNDS_PRODUCTS_SAGA, getOrdersProductsSaga);
}
