import {
  GET_NEWS,
  GET_TARGETOF_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  GET_TARGET_REGION,
  POST_NEWS,
  FETCH_CONTACTS,
  GET_TARGET_SUCCESS,
  GET_TARGET_ERROR,
  GET_TARGET_OFFICES
} from "../const";

export function getTargetRegion() {
  return {
    type: GET_TARGET_REGION
  };
}

export function getTargetOffives() {
  return {
    type: GET_TARGET_OFFICES
  };
}

export function getNewsSuccess(value: any) {
  return {
    type: GET_NEWS_SUCCESS,
    value
  };
}

export function getNewsError(page: string, status: string, value: any) {
  return {
    type: GET_NEWS_ERROR,
    status,
    value
  };
}
export function getTargetSuccess(value: any) {
  return {
    type: GET_TARGET_SUCCESS,
    value
  };
}
export function getTargetOfSuccess(value: any) {
  return {
    type: GET_TARGETOF_SUCCESS,
    value
  };
}
export function getTargetError(page: string, status: string, value: any) {
  return {
    type: GET_TARGET_ERROR,
    status,
    value
  };
}

export function getPage(ip: string, start: number) {
  return {
    type: GET_NEWS,
    ip: ip,
    start
  };
}
export function deleteNews(language: string, id: string) {
  return {
    type: GET_NEWS,
    lang: language,
    id: id
  };
}

export function saveNews(data: any) {
  return {
    type: POST_NEWS,
    data
  };
}

export function savePage(id: string, content: string) {
  return {
    type: POST_NEWS,
    id,
    content
  };
}
export function getContactsSuccess(contact: any) {
  return {
    type: FETCH_CONTACTS,
    contact
  };
}
