import React from "react";
import { Col, Row } from "react-bootstrap";

type InfoProps = {
  data: any;
};

export const InfoBlock = ({ data }: InfoProps) => {
  return (
    <>
      <div className={data.show} id={data.show}>
        <Row className="margin-bottom-20">
          <h2 className="text-uppercase">{data.name}</h2>
        </Row>
        {data.address !== null && (
          <Row className="margin-bottom-10">
            <p>
              <b>{data.address}</b>
            </p>
          </Row>
        )}
        <Row className="ContactInfo">
          <Col xs={12} md={12} lg={6}>
            {data.contacts &&
              data.contacts.map((contact, index) => (
                <Row key={contact.id}>
                  <div className="span">
                    <div className="contacticon" id="iconMessage" />
                  </div>
                  <div className="span margin-left-15 align-self-center">
                    <p className="gray-12px">{contact.type}</p>
                    <p>{contact.name}</p>
                  </div>
                </Row>
              ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InfoBlock;
