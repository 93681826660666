// @flow
import { all, fork } from 'redux-saga/effects'; //  spawn,
import staticSaga from '../pages/information/saga';
import loginSaga from '../pages/login/saga';
import newsSaga from '../pages/news/saga';
import newsAdminSaga from '../pages/newsadmin/saga';
import registerSaga from '../pages/register/saga';
import cartSaga from '../pages/cart/saga';
import profileOrdersSaga from '../pages/personal_profile/orders/saga';
import profileInvoicesSaga from '../pages/personal_profile/invoices/saga';
import profileRefundsSaga from '../pages/personal_profile/refunds/saga';
export default function* rootSaga() {
  yield all([
    fork(staticSaga),
    fork(loginSaga),
    fork(newsSaga),
    fork(newsAdminSaga),
    fork(registerSaga),
    fork(cartSaga),
    fork(profileOrdersSaga),
    fork(profileInvoicesSaga),
    fork(profileRefundsSaga),
  ]);
}
