import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import GoogleMapReact from "google-map-react";

import "../../styles/contacts/map.scss";
import Marker from "./marker";
import InfoBlock from "./infoblock";

const mainOffice = {
  show: "markerinfo active",
  name: "г. Харьков (головной офис)",
  address: "г. Харьков, пр-т Гагарина 201",
  latitude: 49.9316469,
  longitude: 36.26492,
  comments: null,
  contacts: [
    {
      id: "26",
      type: "Kyivstar",
      name: "+38-098-800-800-1",
      prefered: true
    },
    {
      id: "27",
      type: "Vodafone",
      name: "+38-050-557-03-37",
      prefered: false
    },
    {
      id: "28",
      type: "Email",
      name: "alexander.rudakov@s-car.com.ua",
      prefered: false
    }
  ]
};

const regions = [
  { id: "12", name: "г. Днепр (филиал)" },
  { id: "63", name: "г. Харьков (головной офис)" },
  { id: "32", name: "г. Киев (филиал)" }
];

const Map = (props: any) => {
  const [center] = useState({ lat: 49, lng: 31 });

  const [state, setState] = useState({
    data: mainOffice,
    isPressedMarker: true
  });

  useEffect(() => {
    fetch("https://freegeoip.app/json/")
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.country_name === "Ukraine") {
          fetch("https://letters.s-car.com.ua/api/v1/Company/view/offices", {
            headers: {
              "Content-Type": "application/json",
              "Accept-Language": "ru"
            }
          })
            .then(response => {
              return response.json();
            })
            .then(data1 => {
              console.log(JSON.stringify(data1));
              let regionName = "г. Харьков (головной офис)";
              for (let i = 0; i < regions.length; i++) {
                if (regions[i].id === data.region_code) {
                  regionName = regions[i].name;
                  break;
                }
              }

              for (let i = 0; i < data1.length; i++) {
                if (data1[i].name === regionName) {
                  setState({ data: data1[i], isPressedMarker: true });
                  break;
                }
              }
            })
            .catch(function(error) {
              setState({ data: mainOffice, isPressedMarker: true });
            });
        } else {
          setState({ data: mainOffice, isPressedMarker: true });
        }
      })
      .catch(function(error) {
        setState({ data: mainOffice, isPressedMarker: true });
      });
  }, []);

  const toggleMarker = (data: any) => {
    setState({
      data,
      isPressedMarker: state.isPressedMarker
    });
  };

  return (
    <Container>
      <Row>
        <Col lg={6} md={12} xs={12}>
          {state.isPressedMarker ? <InfoBlock data={state.data} /> : null}
        </Col>
        <Col lg={6} md={12} xs={12} className="margin-top-50-mobile">
          <Row className="margin-bottom-15">
            <div className="d-flex">
              <div className="marker small" id="blue" />
              <p className="margin-left-10 align-self-center">Склады</p>
            </div>
            <div className="d-flex margin-left-50">
              <div className="marker small" id="red" />
              <p className="margin-left-10 align-self-center">
                Представительства
              </p>
            </div>
          </Row>
          <Row style={{ height: "450px" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDzOjrwcI1rI0qh3SbWNVWQ0Yf-Lxu9ezE"
              }}
              defaultCenter={center}
              defaultZoom={5.6}
            >
              {props.offices.map((office, index) => (
                <Marker
                  lat={office.latitude as number}
                  lng={office.longitude as number}
                  color="blue"
                  isDraggable={false}
                  key={index}
                  onClick={() => {
                    toggleMarker({
                      show: "markerinfo active",
                      name: office.name,
                      contacts: office.contacts,
                      address: office.address
                    });
                  }}
                />
              ))}
            </GoogleMapReact>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Map;
