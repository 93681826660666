import React, { useEffect, useState } from "react";

import Slider from "../components/mainpage/Slider";
import Manufacturers from "../components/mainpage/Manufacturers";
import Benefits from "../components/mainpage/Benefits";
import Contacts from "../components/mainpage/Contacts";
import Description from "../components/mainpage/Description";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import getMainOffice from "../utils/findmainoffice";

const mainOffice = {
  name: "Харьков",
  contact: {
    id: "39",
    latitude: 49.9316469,
    longitude: 36.26492,
    type: "Kyivstar",
    name: "",
    prefered: true
  }
};

function Mainpage() {
  const dispatch = useDispatch();
  const [region, setRegion] = useState(mainOffice);
  const [news, setDataNews] = useState(null);

  // useEffect(() => dispatch({ type: GET_LANGUAGE }),[]);
  useEffect(() => {
    (async () => {
      let mRegion = await getMainOffice();
      fetch(
        "https://letters.s-car.com.ua/api/v1/Company/contacts?ip=" + mRegion.ip,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "ru"
          }
        }
      )
        .then(response => {
          return response.json();
        })
        .then((data1: any) => {
          if (data1.contact) {
            setRegion(data1);
            dispatch({ type: "FETCH_CONTACTS", contact: data1.contact.name });
          } else {
            setRegion(mainOffice);
          }
        })
        .catch(function (error) {
          setRegion(mainOffice);
        });
    })();
  }, [dispatch]);
  useEffect(() => {
    (async () => {
      let mRegion = await getMainOffice();
      fetch("https://letters.s-car.com.ua/api/v1/News/short?ip=" + mRegion.ip, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru"
        }
      })
        .then(response => {
          return response.json();
        })
        .then((datanews: any) => {
          if (!datanews.errors) {
            setDataNews(datanews);
          }
        })
        .catch(function (error) {
          setDataNews(null);
        });
    })();
  }, []);
  return (
    <div>
      <Slider news={news} />
      <Manufacturers />
      <Benefits />
      <Contacts region={region} />
      <Description />
      <Footer />
    </div>
  );
}

export default Mainpage;
