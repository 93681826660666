import React, { useState, useEffect } from 'react';
import ReactModalLogin from 'react-modal-login';
import { useHistory } from 'react-router-dom';

type LoginProps = {
  showModal: boolean;
  isLogged: boolean;
  loading: boolean;
  errorText: string;
  error: any;
  initialTab: null;
  goToEditorState: boolean;
  recoverPasswordSuccess: null;
  setLogin: Function;
  setRegister: Function;
  user: Object;
  callback: Function;
};

const Login = (props: LoginProps) => {
  const { setLogin, setRegister, isLogged, errorText, callback } = props;
  let history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [loading, setShowLoading] = useState(false);
  const [error, setError] = useState('');
  //const [initialTab, setInitialTab] = useState(null);
  //const [recoverPasswordSuccess, setRecovery] = useState(null);
  const initialTab = null;
  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  useEffect(() => {
    setShowLoading(isLogged);
  }, [isLogged]);

  const onLogin = () => {
    let emailfield = document.querySelector('#email') as HTMLInputElement;
    let pwdfield = document.querySelector('#password') as HTMLInputElement;

    // check if objects are null
    if (!emailfield.value || !pwdfield.value) {
      alert('please add data');
    } else {
      const email = emailfield.value;
      const password = pwdfield.value;
      setLogin(email, password, 'uk', callback);
    }
  };

  const onRegister = () => {
    let nameField = document.querySelector('#email') as HTMLInputElement;
    let surnameField = document.querySelector('#password') as HTMLInputElement;
    let phoneField = document.querySelector('#email') as HTMLInputElement;
    let regionField = document.querySelector('#password') as HTMLInputElement;
    let emailField = document.querySelector('#email') as HTMLInputElement;
    let typeField = document.querySelector('#email') as HTMLInputElement;
    let cityField = document.querySelector('#password') as HTMLInputElement;
    let companyField = document.querySelector('#email') as HTMLInputElement;
    let passwordField = document.querySelector('#password') as HTMLInputElement;

    if (
      !nameField.value ||
      !phoneField.value ||
      !emailField.value ||
      !passwordField.value ||
      !companyField.value
    ) {
      alert('Пожалуйста заполните все поля');
    } else {
      const name = nameField.value;
      const surname = surnameField.value;
      const phone = phoneField.value;
      const region = regionField.value;
      const email = emailField.value;
      const type = typeField.value;
      const city = cityField.value;
      const company = companyField.value;
      const password = passwordField.value.toString();
      setRegister(
        name,
        surname,
        phone,
        region,
        email,
        type,
        city,
        company,
        password
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setError('');
    history.push('/');
  };

  const startLoading = () => {
    setShowLoading(true);
  };
  const finishLoading = () => {
    setShowLoading(false);
  };

  const afterTabsChange = () => {
    setError('');
  };

  const isLoading = loading;

  return (
    <div>
      {isLogged && history.goBack()}

      <ReactModalLogin
        visible={showModal}
        onCloseModal={closeModal}
        loading={isLoading}
        initialTab={initialTab}
        error={error}
        tabs={{
          loginLabel: 'ВХОД',
          registerLabel: 'РЕГИСТРАЦИЯ',
          afterChange: afterTabsChange,
        }}
        startLoading={startLoading}
        finishLoading={finishLoading}
        form={{
          onLogin: onLogin,
          onRegister: onRegister,

          loginBtn: {
            label: 'ВХОД',
          },
          registerBtn: {
            label: 'РЕГИСТРАЦИЯ',
          },
          loginInputs: [
            {
              containerClass: 'RML-form-group',
              type: 'email',
              inputClass: 'RML-form-control',
              id: 'email',
              name: 'email',
              placeholder: 'Login',
            },
            {
              containerClass: 'RML-form-group',
              type: 'password',
              inputClass: 'RML-form-control',
              id: 'password',
              name: 'password',
              placeholder: 'Password',
            },
          ],
          registerInputs: [
            {
              containerClass: 'RML-form-group',
              label: 'Ваше имя',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'name',
              name: 'name',
              placeholder: 'Ваше имя',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Ваша фамилия ',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'surname',
              name: 'surname',
              placeholder: 'Ваша фамилия',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Телефон ',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'phone',
              name: 'phone',
              placeholder: 'Телефон',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Регион ',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'region',
              name: 'region',
              placeholder: 'Регион',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Email',
              type: 'email',
              inputClass: 'RML-form-control',
              id: 'email',
              name: 'email',
              placeholder: 'Email',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Город',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'city',
              name: 'city',
              placeholder: 'Город',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Тип пользователя',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'type',
              name: 'type',
              placeholder: 'Тип пользователя',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Название компании',
              type: 'text',
              inputClass: 'RML-form-control',
              id: 'company',
              name: 'company',
              placeholder: 'Название компании',
            },
            {
              containerClass: 'RML-form-group',
              label: 'Пароль',
              type: 'password',
              inputClass: 'RML-form-control',
              id: 'password',
              name: 'password',
              placeholder: 'Пароль',
            },
          ],
        }}
      />
      {
        // loggedIn
      }
    </div>
  );
};

export default Login;
