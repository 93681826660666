import connect from "react-redux-connect-lifecycle";
import Page from "../Page";
import { getNewsSelector } from "../reducer";
import { getPage } from "../actions";
import getMainOffice from "../../../utils/findmainoffice";
let ip;
const mapDispatchToProps = dispatch => ({
  onComponentDidMount: async props => {
    const region = await getMainOffice();
    ip = region.ip;
    dispatch(getPage(region.ip, 0));
  },
  onComponentWillUnmount: props => {
    console.log("component NEWS will unmount");
  },
  loadNext: page => dispatch(getPage(ip, page))
});

const mapStateToProps = state => getNewsSelector(state);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
