import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";

import useOnclickOutside from "react-cool-onclickoutside";

const Autocomplete = props => {
  const {
    // ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    const inputText = e.target.value;
    const result = inputText.split(', ');
    const isNumber = !isNaN(+result[2]);
    if (isNumber) {
      props.setValidate(true);
    } else {
      props.setValidate(false)
    }
    setValue(inputText);
  };

  const handleSelect = ({description}) => () => {

    const autocompleteText = description;
    const splitText = autocompleteText.split(', ');
    const isNumberTwo = !isNaN(+splitText[1]);
    const isNumberOne = !isNaN(+splitText[0]);
    if (isNumberOne || isNumberTwo) {
      props.setValidate(true);
    } else {
      props.setValidate(false);
    }

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();
    props.setAddress(description, props.language);
    // Get latitude and longitude via utility functions
    getGeocode({address: description})
      .then(results => getLatLng(results[0]))
      .then(({lat, lng}) => {
        props.setMap({lat: lat, lng: lng});
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  };


  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        id,
        structured_formatting: {main_text, secondary_text}
      } = suggestion;
      return (
        <li key={id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <input value={value} onChange={handleInput} placeholder="Введите город, улицу, номер дома" style={{
        paddingLeft: 14,
        width: '100%',
        height: 40,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#962932"
      }}/>
      {status === "OK" && <div style={{border: '1px solid black', borderRadius: '0.5rem'}} className={'p-2'}>
        <ul style={{listStyleType: 'none'}}>{renderSuggestions()}</ul>
      </div>}
    </div>
  );
};
export default Autocomplete;
