import '../../../styles/cabinet/profile.scss';
import { Col, Row, Table } from 'react-bootstrap';
import Breadcrumbs from '../../../components/navigation/Breadcrumbs';

export const PageItem = ({ profile, currency }) => {
  return (
    <div>
      <Row className="profileHeader">
        <Col className="navigBreadCrumbs">
          {/*<b><h2>Мой кабинет</h2></b>*/}
          <Breadcrumbs />
        </Col>
        <Col xs={3}>
          {/* <p>{currency.data.map((value, index) => <p key={index}>{value.name} = {value.rate} UAH / {value.name} = {value.increase}</p>)}</p> */}
          <div>
            {currency.data.map((value, index) => (
              <p key={index}>
                {value.name} = {value.rate} UAH
              </p>
            ))}
          </div>
        </Col>
      </Row>
      <div className="container">
        <div className="row-item">
          <div className="item1">
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <td colSpan={2}> */}
                    <th colSpan={2}>ВАШ ПРОФИЛЬ</th>
                    {/* </td> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{profile.code}</td>
                  </tr>
                  <tr>
                    <td>Тип клиента</td>
                    <td>{profile.userTypeCode}</td>
                  </tr>
                  <tr>
                    <td>Клиент</td>
                    <td>{profile.name}</td>
                  </tr>
                  <tr>
                    <td>ФИО</td>
                    <td>{profile.deliveryName}</td>
                  </tr>
                  <tr>
                    <td>Юридический адрес</td>
                    <td>{profile.mainDeliveryPointName}</td>
                  </tr>
                  <tr>
                    <td>Телефон</td>
                    <td>{profile.deliveryPhone}</td>
                  </tr>
                  <tr>
                    <td>Е-мейл</td>
                    <td>{profile.eMail}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </div>
          <div className="item1">
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <td colSpan={2}> */}
                    <th colSpan={2}>Взаиморасчеты</th>
                    {/* </td> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Валюта учета баланса</td>
                    <td>{profile.buyerCurrencyName || ''}</td>
                  </tr>
                  <tr>
                    <td>Уровень цен</td>
                    <td>
                      {profile.priceLevel !== null ? profile.priceLevel : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Кредитый лимит</td>
                    <td>
                      {profile.creditLimit !== null
                        ? profile.creditLimit + ' EUR'
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Отсрочка</td>
                    <td>
                      {profile.postponement !== null
                        ? profile.postponement + ' дня'
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Лимит возвратов от оборота</td>
                    <td>
                      {profile.returnLimit !== null
                        ? profile.returnLimit + ' %'
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Телефон SMS информирования об оплатах</td>
                    <td>
                      {profile.paymentPhone !== null
                        ? profile.paymentPhone
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Второй телефон SMS информирования об оплатах</td>
                    <td>
                      {profile.paymentPhoneSecond !== null
                        ? profile.paymentPhoneSecond
                        : ''}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </div>
        </div>
        <div className="row-item">
          <div className="item1">
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <td colSpan={2}> */}
                    <th colSpan={2}>
                      Информация по получению товара (доставка / отправка)
                    </th>
                    {/* </td> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Склад отгрузки</td>
                    <td>{profile.mainStoreName || ''}</td>
                  </tr>
                  <tr>
                    <td>Основной способ получения товара</td>
                    <td>{profile.mainDeliveryTypeName || ''}</td>
                  </tr>
                  {profile.mainDeliveryTypeName === 'Отправка перевозчиком' ? (
                    <>
                      <tr>
                        <td>Город доставки</td>
                        <td>Глухов</td>
                      </tr>
                      <tr>
                        <td>Основной перевозчик</td>
                        <td>Нова пошта</td>
                      </tr>
                      <tr>
                        <td>Отделение перевозчика</td>
                        <td>125</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>Основная точка доставки</td>
                        <td>{profile.mainDeliveryPointName}</td>
                      </tr>
                      <tr>
                        <td>Режим роботы</td>
                        <td>
                          <p>Пн-Пт: 9:00 - 18:00</p>
                          <p>Сб: 10:00 - 16:00; Вс - выходной</p>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>ФИО получателя</td>
                    <td>{profile.deliveryName || ''}</td>
                  </tr>
                  <tr>
                    <td>Телефон получателя</td>
                    <td>{profile.deliveryPhone || ''}</td>
                  </tr>
                  <tr>
                    <td>Телефон SMS информирования об отправках</td>
                    <td>+38-095-45-46-888</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </div>
        </div>
        <div className="row-item">
          <div className="item1">
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <td colSpan={2}> */}
                    <th colSpan={2}>Основной менеджер</th>
                    {/* </td> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ФИО</td>
                    <td>{profile.mainManagerName}</td>
                  </tr>
                  {profile.managerInfo.jsonStruct.contacts &&
                    profile.managerInfo.jsonStruct.contacts.map(
                      (value, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{value.name}</td>
                            <td>{value.value}</td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
            </Col>
          </div>
          <div className="item1">
            {profile.salesRepresentativeInfo !== null ? (
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <td colSpan={2}>
                        <th>Торговый представитель</th>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ФИО</td>
                      <td>{profile.salesRepresentativeName || ''}</td>
                    </tr>
                    {profile.salesRepresentativeInfo.jsonStruct.contacts.map(
                      (value) => {
                        return (
                          <tr>
                            <td>{value.name || ''}</td>
                            <td>{value.value || ''}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Col>
            ) : null}
          </div>
        </div>
        <div className="row-item">
          {profile.secondManagerName !== null ? (
            <div className="item1">
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <td colSpan={2}>
                        <th>Дополнительный менеджер</th>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ФИО</td>
                      <td>{profile.secondManagerName}</td>
                    </tr>
                    {profile.secondManagerInfo.jsonStruct.contacts.map(
                      (value) => {
                        return (
                          <tr>
                            <td>{value.name || ''}</td>
                            <td>{value.value || ''}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Col>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
