import React from "react";
import { Container } from "react-bootstrap";

// importing Mainpage components
// import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/notfoundpage/notfound.scss";

function Profile() {
  return (
    <body>
      <Container className="notfound">
          <div className="alert alert-danger" role="alert">
              <h2>
                  Ошибка...
              </h2>
              <p>
                  Что-то пошло не так
              </p>
              <p>
                  Вернитесь на <a href="/" className="alert-link">главную страницу</a> и повторите ваше действие
              </p>
          </div>
      </Container>
      <Footer />
    </body>
  );
}

export default Profile;
