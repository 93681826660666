import { call, put, takeLatest } from "redux-saga/effects";
import { Base64 } from "js-base64";
import request from "../../../utils/request";
import getToken from "../../../utils/tokenchecker";
import {
  getAdminNews,
  getNewsSuccess,
  getNewsError,
  getContactsSuccess,
  getTargetSuccess,
  getTargetOfSuccess,
  getTargetError,
  getLanguageSuccess,
  getPageError,
  getNewsEditedSuccess
} from "../actions";
import {
 // GET_PAGE,
  EDIT_NEWS,
  GET_ADMIN_NEWS,
  DELETE_NEWS,
  POST_NEWS,
  PUT_PAGE,
  GET_TARGET_OFFICES,
  GET_TARGET_REGION,
  GET_LANGUAGE
} from "../const";
// import getMainOffice from "../../../utils/findmainoffice";
import {
  // API_NEWS,
  API_STATIC_PAGE,
  API_CONTACTS,
  API_OFFICE,
  API_REGION,
  API_NEWS_ADMIN,
  API_LANGUAGE
} from "../../../utils/settings";
import getLanguage from "../../../utils/getLanguage";

export function* putData(action) {
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    const { id, content } = action;
    const requestURL = API_STATIC_PAGE;
    const options = {
      method: "PUT",
      body: JSON.stringify({
        contents: Base64.encode(content),
        id: id
      }),
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.token}`
      }
    };
    try {
      const log = yield call(request, requestURL, options);
      if (log.success === true) {
        console.log("successfuly saved");
        // yield put(getPageError(log.status, log.value));
      } else {
        // yield put(getPageSuccess(log.value));
      }
      return log;
    } catch (err) {
      yield put(getNewsError("id", "connection", err));
    }
  } else {
  }
}

export function* getDataAdmin(action) {
  const { page } = action;
  let tokenObj = getToken();
  const requestURL = API_NEWS_ADMIN + "?currentPage=" + page + "&pageSize=" + 2;

  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.token}`
    }
  };
  try {
    const log = yield call(request, requestURL, options);

    if (log.items === null || log.items === undefined) {
      yield put(getNewsError("news", log.id, log.contents));
    } else {
      yield put(getNewsSuccess(log));
    }
    return log;
  } catch (err) {
    yield put(getNewsError("news", "connection", err));
  }
}

export function* editNews(action) {
  const { id } = action;
  let tokenObj = getToken();
  const requestURL = API_NEWS_ADMIN + id;

  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.token}`
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    console.log("news edited", JSON.stringify(log));

    if (log.items === null || log.items === undefined) {
      yield put(getNewsEditedSuccess(log));
    } else {
      // yield put(getNewsSuccess(log));
    }
    return log;
  } catch (err) {
    yield put(getNewsError("news", "connection", err));
  }
}

export function* getDataOffices() {
  let tokenObj = getToken();
  const requestURL = API_OFFICE;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.token}`
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    yield put(getTargetOfSuccess(log));
    return log;
  } catch (err) {
    yield put(getTargetError("news", "connection", err));
  }
}
export function* getDataRegion() {
  let tokenObj = getToken();
  const requestURL = API_REGION;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.token}`
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    yield put(getTargetSuccess(log));
    return log;
  } catch (err) {
    yield put(getTargetError("news", "connection", err));
  }
}
export function* getContacts(action) {
  const { mRegion } = action;
  const requestURL = API_CONTACTS + "?ip=" + mRegion.ip;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      prefered: "true"
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    if (log.name) {
      yield put(getContactsSuccess(log));
    } else {
      yield put(getNewsError("contacts", log.id, log.contents));
    }
    return log;
  } catch (err) {
    yield put(getNewsError("contacts", "connection", err));
  }
}

export function* addNews(action) {
  const { data } = action;
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    const requestURL = API_NEWS_ADMIN;
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.token}`
      }
    };
    try {
      const log = yield call(request, requestURL, options);
      return log;
    } catch (err) {
      yield put(getNewsError("id", "connection", err));
    }
  } else {
  }
}

export function* getLanguages() {
  const requestURL = API_LANGUAGE;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      prefered: "true"
    }
  };
  try {
    const log = yield call(request, requestURL, options);
    yield put(getLanguageSuccess(log));
    return log;
  } catch (err) {
    yield put(getPageError("contacts", "connection", err));
  }
}
export function* deleteNews(action) {
  const { id } = action;
  let tokenObj = getToken();
  if (tokenObj.valid === true) {
    const requestURL = API_NEWS_ADMIN + id;
    const options = {
      method: "DELETE",
      body: JSON.stringify({
        id: id
      }),
      headers: {
        accept: "application/json",
        "Accept-Language": getLanguage().toLowerCase(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenObj.token}`
      }
    };
    try {
      const log = yield call(request, requestURL, options);
      // window.location.reload();
      yield put(getAdminNews(0));
      if (log.success === true) {
        console.log("successfuly deleted");
        // yield put(getPageError(log.status, log.value));
      } else {
        // yield put(getPageSuccess(log.value));
      }
      return log;
    } catch (err) {
      yield put(getNewsError("id", "connection", err));
    }
  } else {
  }
}
export default function* aboutSaga() {
  yield takeLatest(GET_ADMIN_NEWS, getDataAdmin);
  yield takeLatest(GET_TARGET_REGION, getDataRegion);
  yield takeLatest(GET_LANGUAGE, getLanguages);
  yield takeLatest(GET_TARGET_OFFICES, getDataOffices);
  yield takeLatest(PUT_PAGE, putData);
  //  yield takeLatest("GET_CONTACTS", getContacts);
  yield takeLatest(POST_NEWS, addNews);
  yield takeLatest(EDIT_NEWS, editNews);
  yield takeLatest(DELETE_NEWS, deleteNews);
  // yield takeLatest("GET_CURRENCY", getCurrency);
  //yield takeLatest(GET_ABOUTPAGE, getCoordinates);
}
