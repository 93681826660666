import {
  ADD_TO_CART,
  REMOVE_ITEM_CART,
  COUNT_TO_CART,
  COMMENT_TO_CART,
  GABARITE_TO_CART,
  GET_CART,
  ADD,
  REMOVE,
  GET,
  UPDATE,
  CART_FETCH_FAIL,
  CART_CATCH_FAIL,
  CART_LOADING,
  CART_ITEM_CHECKED,
  TO_STEP,
  ORDER,
  SUCCESS,
  ADD_TO_CART_MESS,
} from '../const';

export function addToCart(data: any) {
  return {
    type: ADD_TO_CART,
    payload: data,
  };
}

export function getCart(data: any) {
  return {
    type: GET_CART,
    data,
  };
}

export function removeCartItem(data: any) {
  return {
    type: REMOVE_ITEM_CART,
    data,
  };
}

export function countToCart(data: any) {
  return {
    type: COUNT_TO_CART,
    data,
  };
}

export function commentToCart(data: any) {
  return {
    type: COMMENT_TO_CART,
    data,
  };
}

export function gabariteToCart(data: any) {
  return {
    type: GABARITE_TO_CART,
    data,
  };
}

export function sagaCartAdd(data: any) {
  return {
    type: ADD,
    payload: data,
  };
}

export function sagaCartRemove(data: any) {
  return {
    type: REMOVE,
    payload: data,
  };
}

export function sagaCartUpdate(data: any) {
  return {
    type: UPDATE,
    payload: data,
  };
}

export function sagaOrder(data: any) {
  return {
    type: ORDER,
    payload: data,
  };
}

export function sagaCartGet() {
  return {
    type: GET,
  };
}

export function cartFetchFail(data) {
  return {
    type: CART_FETCH_FAIL,
    data,
  };
}

export function cartCatchFail(data) {
  return {
    type: CART_CATCH_FAIL,
    data,
  };
}

export function cartLoading(data) {
  return {
    type: CART_LOADING,
    data,
  };
}

export function cartItemChecked(data) {
  return {
    type: CART_ITEM_CHECKED,
    data,
  };
}

export function toStep(data) {
  return {
    type: TO_STEP,
    data,
  };
}

export function cartSuccess(data) {
  return {
    type: SUCCESS,
    data,
  };
}

export function addToCartMessClose() {
  return {
    type: ADD_TO_CART_MESS,
  };
}
