import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useCallback, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getInvoicesSaga, getInvocesProductsSaga } from './actions';
import { LoadingComponent } from '../../../components/LoadingComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Invoices from './component/Invoices';
import Products from './component/Products';
import '../../../styles/cabinet/tables-pages.scss';

interface DefaultRootState {
  profileInvoices: {
    invoices: [any];
    invoicesProducts: [any];
    isGetInvoices: boolean;
    isGetInvoicesProducts: boolean;
    loading: boolean;
    error: boolean;
  };
}

const MemoInvoices = memo(Invoices);
const MemoProducts = memo(Products);

const InvoicesPage = () => {
  const [tab, setTab] = useState<string>('invoices');
  const [selectionByInvoiceNumber, setSelectionByInvoiceNumber] =
    useState<string>('');
  const [selectionInvoiceByInvoiceNumber, setSelectionInvoiceByInvoiceNumber] =
    useState<string>('');
  const [selectionInvoiceByOrderNumber, setSelectionInvoiceByOrderNumber] =
    useState<string>('');

  const invoices = useSelector(
    (state: DefaultRootState) => state.profileInvoices
  );
  const dispatch = useDispatch();

  const { search } = useLocation<any>();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const orderNumber = queryParams.get('orderNumber');
    if (orderNumber) {
      toInvoiceFilterOrderNumber(orderNumber);
    }
  }, []);

  const toInvoiceFilterOrderNumber = useCallback((num) => {
    setTab('invoices');
    setSelectionInvoiceByOrderNumber(num);
  }, []);

  const toInvoiceProductsFilterInvoiceNumber = useCallback((num) => {
    setTab('invoicesProducts');
    setSelectionByInvoiceNumber(num);
  }, []);

  const toInvoiceFilterInvoiceNumber = useCallback((num) => {
    setTab('invoices');
    setSelectionInvoiceByInvoiceNumber(num);
  }, []);

  useEffect(() => {
    if (!invoices.isGetInvoices) {
      dispatch(getInvoicesSaga());
    }
    if (!invoices.isGetInvoicesProducts) {
      dispatch(getInvocesProductsSaga());
    }
  }, []);

  return (
    <Container fluid className="profile-orders">
      <Row className="justify-content-between mb-2">
        <Col>
          <h2 className="text-uppercase">Расходные накладные</h2>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col>
          <Tabs
            id="controlled-tab"
            activeKey={tab}
            onSelect={(k: any) => setTab(k)}
            className="mb-3"
          >
            <Tab eventKey="invoices" title="Накладные">
              {!invoices.loading ? (
                <>
                  {invoices.invoices.length ? (
                    <MemoInvoices
                      invoices={invoices.invoices}
                      toInvoiceProductsFilterInvoiceNumber={
                        toInvoiceProductsFilterInvoiceNumber
                      }
                      setSelectionInvoiceByInvoiceNumber={
                        setSelectionInvoiceByInvoiceNumber
                      }
                      selectionInvoiceByInvoiceNumber={
                        selectionInvoiceByInvoiceNumber
                      }
                      selectionInvoiceByOrderNumber={selectionInvoiceByOrderNumber}
                    />
                  ) : (
                     invoices.error ? 
                      <h3>Ошибка запроса</h3>
                      :
                      <h3>Заказов нет</h3>
                     
                  )}
                </>
              ) : (
                <LoadingComponent />
              )}
            </Tab>
            <Tab eventKey="invoicesProducts" title="Товары">
              {!invoices.loading ? (
                <>
                  {invoices.invoicesProducts.length ? (
                    <MemoProducts
                      products={invoices.invoicesProducts}
                      toInvoiceFilterInvoiceNumber={
                        toInvoiceFilterInvoiceNumber
                      }
                      selectionByInvoiceNumber={selectionByInvoiceNumber}
                      setSelectionByInvoiceNumber={setSelectionByInvoiceNumber}
                    />
                  ) : (
                    invoices.error ? 
                      <h3>Ошибка запроса</h3>
                      :
                      <h3>Заказов нет</h3>
                  )}
                </>
              ) : (
                <LoadingComponent />
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoicesPage;
