import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../../utils/request";
import getToken from "../../../utils/tokenchecker";
import { getNewsSuccess, getNewsError } from "../actions";
import { GET_NEWS } from "../const";
// import getMainOffice from "../../../utils/findmainoffice";
import { API_NEWS } from "../../../utils/settings";
import getLanguage from "../../../utils/getLanguage";

export function* getData(action) {
  const { ip, start } = action;
  let tokenObj = getToken();
  const requestURL =
    API_NEWS + "?ip=" + ip + "&currentPage=" + start + "&pageSize=" + 5;
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "Accept-Language": getLanguage().toLowerCase(),
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenObj.token}`
    }
  };
  try {
    const log = yield call(request, requestURL, options);

    if (log.items === null || log.items === undefined) {
      yield put(getNewsError("news", log.id, log.contents));
    } else {
      yield put(getNewsSuccess(log));
    }
    return log;
  } catch (err) {
    yield put(getNewsError("news", "connection", err));
  }
}

export default function* aboutSaga() {
  yield takeLatest(GET_NEWS, getData);
}
