import React from 'react';
import { Row, Col, Container, Table, Image } from 'react-bootstrap';
import arrow from '../../../static/images/icons/arrow.png';

const table1 = [
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
  {
    number: 'SC123141512',
    date: '23.12.2019',
    sum: '234 EUR',
    status: '',
    comment: 'Отгружено,проверить комплектацию',
    goto: '->',
  },
];
const MainCabinet = () => {
  return (
    <Container>
      <Row className="justify-content-between">
        <Col>
          <b className="text-uppercase">
            {' '}
            <h2>Мой кабинет</h2>{' '}
          </b>
        </Col>
        <Col xs={3}>
          <p> EUR = 27.10 USD / EUR = 1.12</p>
          <p>
            {' '}
            <b>Лимит</b> - 2000 EUR
          </p>
          <p>
            {' '}
            <b>Долг</b> - 50 EUR
          </p>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <b className="text-uppercase"> ПОСЛЕДНИЕ ЗАКАЗЫ</b>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Номер</th>
              <th>Дата</th>
              <th>Сумма</th>
              <th>Cтатус</th>
              <th>Комментарий</th>
              <th>Перейти</th>
            </tr>
          </thead>
          <tbody>
            {table1.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.number}</td>
                  <td>{row.date}</td>
                  <td>{row.sum}</td>
                  <td>{row.status}</td>
                  <td>{row.comment}</td>
                  <td>
                    {' '}
                    <Image src={arrow} width={35} height={35} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Col style={{ paddingLeft: 0 }}>
          <b className="text-uppercase"> Ваши возвраты</b>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Номер</th>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Cтатус</th>
                <th>Перейти</th>
              </tr>
            </thead>
            <tbody>
              {table1.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row.number}</td>
                    <td>{row.date}</td>
                    <td>{row.sum}</td>
                    <td>{row.status}</td>
                    <td>
                      {' '}
                      <Image src={arrow} width={35} height={35} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
        <Col xs={3}>
          <b className="text-uppercase"> Открытые вопросы</b>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Возвраты</th>
                <td>
                  {' '}
                  <Image src={arrow} width={25} height={25} />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Возвраты</b>
                  <p>
                    Вы получили ответ на ваш запрос о возврате заказа
                    NoSC2134421
                  </p>
                </td>
                <td>
                  {' '}
                  <Image src={arrow} width={35} height={35} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default MainCabinet;
