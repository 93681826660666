import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// importing Contact components styles
import "../../styles/mainpage/contacts.scss";

// importing Map image (instead of iframe for now) !!!
import MainpageMap from "./MainpageMap";

// setting Contact parameters
type ContactProps = {
  contactIcon: string;
  contactTitle: string;
  contactText: string;
};

export const Contact = ({
  contactIcon,
  contactTitle,
  contactText
}: ContactProps) => (
  <Row>
    <div className="span">
      <div className="contacticon" id={contactIcon} />
    </div>
    <div className="span margin-left-15 align-self-center">
      <p className="gray-12px">{contactTitle}</p>
      <p>{contactText}</p>
    </div>
  </Row>
);

type Props = {
  region: Object;
};

function Contacts(props: Props) {
  return (
    <Container className="margin-bottom-50">
      <Row className="margin-bottom-30 justify-content-center">
        <h2>Contacts</h2>
      </Row>
      <Row className="ContactsBlock">
        <Col xs={12} lg={true}>
          <Row>
            <p className="ContactPhone margin-bottom-20">
              {props.region["name"]}
            </p>
          </Row>
          <Row>
            <p className="ContactPhone margin-bottom-20">
              {props.region["contact"].name}
            </p>
          </Row>
          <Row className="ContactInfo">
            <Col xs={12} md={6} lg={true}>
              <Contact
                contactIcon="iconMessage"
                contactTitle={props.region["contact"].type}
                contactText={props.region["contact"].name}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mainpage-map" xs={12} lg={true}>
          <MainpageMap region={props.region} />
        </Col>
      </Row>
    </Container>
  );
}

export default Contacts;
