import {AXIOS, AXIOS_CURRENCY} from "./axiosConfig";

class ProfileService {
  async getInfoAboutUser() {
    const id = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    return AXIOS.post(`/profile`,
      {
        userId: id,
      },
      {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": 'GET,POST,OPTIONS',
          // "Access-Control-Allow-Headers": "Authorization, DNT,User-Agent, X-Requested-With, If-Modified-Since, Cache-Control, Content-Type, Range",
          // "Access-Control-Max-Age": "1728000",
          // accept: "application/json",
          // "Accept-Language": getLanguage().toLowerCase(),
          "Content-Type": "application/json",
          'authorization': token ? `Bearer ${token}`: ''
        }
      }
    )
  }

  async getInfoCurrency () {
    const token = localStorage.getItem('token');
    return AXIOS_CURRENCY.get('/currency', {
      headers: {
        "Content-Type": "application/json",
        "authorization": token ? `Bearer ${token}` : ""
      }
    })
  }
}

export const profileService = new ProfileService();
