import { DEBUG } from './settings';

function ErrorVal(this: any, status, error) {
  this.name = 'Error';
  this.success = false;
  this.status = status;
  this.error = error;
}

ErrorVal.prototype = Object.create(Error.prototype);
ErrorVal.prototype.constructor = ErrorVal;

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return { success: true };
  }
  let retVal = response.json();
  retVal.success = true;
  return retVal;
}

function checkStatus(response) {
  if (DEBUG === true) {
    console.log('request response:' + JSON.stringify(response));
  }
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ErrorVal(response.statusText, response);
  throw error;
}

export default async function request(url, options) {
  const response = await fetch(url, options);

  const response2 = await checkStatus(response);
  return parseJSON(response2);
}
