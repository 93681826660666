import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// importing Slider components styles
import "../../styles/mainpage/slider.scss";
import MainSlider from "./benefits-slider";//
//import Logo1 from "../../static/images/01.png";
import News from "./News";
import Bill from "./Bill";
const billsArrTitle = [
  ["Регион", "Дата", "Производитель", "Артикул", "Номенклатура", "Количество"]
];
const billsArr = [
  ["Днепр", "02.05.2020 ", "FAG", "983256", "Подшипник", "2"],
  ["Харьков", "02.05.2020", "Ruville", "Ru-1265", "Хомут", "10"],
  ["Киев", "02.05.2020", "FAST", "re-1234-98", "Фильтр ", "3"],
  ["Сумы", "02.05.2020", "Monrow", "M-1287", "Стойка", "2"]
];
/*const stylesArr = [
  [
    'style={"color: green"}',
    "Дата",
    "Производитель",
    "Артикул",
    "Номенклатура",
    "Количество"
  ]
];
*/
interface Props {
  news: any;
}
function Slider(props: Props) {
  const { news } = props;
  // const [arr, setArr] = useState(billsArr);

  return (
    <Container fluid className="Slider margin-bottom-50">
      <Container>
        <Row>
          <Col lg={8} className="SliderBackground align-self-center">
            <MainSlider />
            <Row style={{ marginTop: -160 }}>
              <Col className="align-self-center">
                {billsArrTitle.map((bill, index) => {
                  return <Bill key={index} data={bill} indexTable={-1} />;
                })}
              </Col>
            </Row>
            <Row style={{ marginTop: -10 }}>
              <Col className="align-self-center">
                {billsArr.map((bill, index) => {
                  return <Bill key={index} data={bill} indexTable={index} />;
                })}
              </Col>
            </Row>
          </Col>
          <Col
            lg={4}
            className="padding-left-50 padding-right-50 news align-self-center"
          >
            <Row className="margin-bottom-10 padding-left-20 padding-right-20">
              <Col lg={12}>
                <h2>Новости:</h2>
              </Col>
            </Row>
            {news !== null &&
              news.map((itemnews, index) => {
                return (
                  <Row>
                    <Col lg={12} className="news-block">
                      {index < 5 && (
                        <News
                          key={itemnews.id}
                          newsTitle={itemnews.name}
                          newsContent={itemnews.annotation}
                          newsDate={itemnews.date}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            {news === null ||
              (news.length === 0 && (
                <Row>
                  <Col lg={12} className="news-block">
                    <News
                      newsTitle="Скоро... "
                      newsContent="Новости в процессе наполнения..."
                      newsDate=""
                    />
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Slider;
