import {
  GET_INVOICES,
  GET_INVOICES_SAGA,
  INVOICES_LOADING,
  GET_INVOICES_PRODUCTS_SAGA,
  GET_INVOICES_PRODUCTS,
  SEND_FORM_INVOICE_PRODUCTS_SAGA,
  SET_ERROR,
} from '../const';

export function getInvoices(data) {
  return {
    type: GET_INVOICES,
    payload: data,
  };
}

export function getInvoicesSaga() {
  return {
    type: GET_INVOICES_SAGA,
  };
}

export function getInvocesProductsSaga() {
  return {
    type: GET_INVOICES_PRODUCTS_SAGA,
  };
}

export function invoicesLoading(data) {
  return {
    type: INVOICES_LOADING,
    payload: data,
  };
}

export function getInvoicesProducts(data) {
  return {
    type: GET_INVOICES_PRODUCTS,
    payload: data,
  };
}

export function sendFormInvoiceProductSaga(data) {
  return {
    type: SEND_FORM_INVOICE_PRODUCTS_SAGA,
    payload: data,
  };
}

export function setError() {
  return {
    type: SET_ERROR,
  };
}
