import {
  GET_ORDERS,
  GET_ORDERS_SAGA,
  ORDERS_LOADING,
  GET_ORDERS_PRODUCTS_SAGA,
  GET_ORDERS_PRODUCTS,
  SEND_FORM_ORDERS_PRODUCTS_SAGA,
  SET_ERROR,
} from '../const';

export function getOrders(data) {
  return {
    type: GET_ORDERS,
    payload: data,
  };
}

export function getOrdersSaga() {
  return {
    type: GET_ORDERS_SAGA,
  };
}

export function getOrdersProductsSaga() {
  return {
    type: GET_ORDERS_PRODUCTS_SAGA,
  };
}

export function ordersLoading(data) {
  return {
    type: ORDERS_LOADING,
    payload: data,
  };
}

export function getOrdersProducts(data) {
  return {
    type: GET_ORDERS_PRODUCTS,
    payload: data,
  };
}

export function sendFormOrdersProductSaga(data) {
  return {
    type: SEND_FORM_ORDERS_PRODUCTS_SAGA,
    payload: data,
  };
}

export function setError() {
  return {
    type: SET_ERROR,
  };
}
