import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../map/marker";

export const RedMarker = (props: any) => {
  return <div className="marker" />;
};

const MainpageMap = (props: any) => {
  const [zoom] = useState(11);
  const [lat, setLat] = useState(49.9316469);
  const [lon, setLon] = useState(36.26492);
  let [center, setCenter] = useState({ lat: lat, lng: lon });
  if (props.region.latitude && props.region.longitute) {
    setCenter({ lat: props.region.latitude, lng: props.region.longitute });
    setLat(props.region.latitude as number);
    setLon(props.region.longitute as number);
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyDzOjrwcI1rI0qh3SbWNVWQ0Yf-Lxu9ezE" }}
      defaultCenter={center}
      defaultZoom={zoom}
    >
      <Marker
        lat={lat as number}
        lng={lon as number}
        color="blue"
        isDraggable={false}
        onClick={() => {
          alert(props.region["name"]);
        }}
      />
    </GoogleMapReact>
  );
};

export default MainpageMap;

