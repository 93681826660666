import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,

} from "react-bootstrap";

import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/navigation/Breadcrumbs";
import NewsComponent from "../../components/mainpage/News";
import PaginationComponent from "../../components/PaginationComponent";

type Props = {
  newsArr: any;
  langArr: [];
  targetArr: [];
  targetOfArr: [];
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  saveData: Function;
  getTargetOf: Function;
  getTargetReg: Function;
  getAdminNews: Function;
  loadNext: Function;
};

const News = (props: Props) => {
  const {
    newsArr,
    totalPages,
    loadNext
  } = props;

  const [count, setCount] = useState<number[]>([]);
  let countArr: Array<number> = [];
  useEffect(() => {
    for (var i = 0; i < totalPages; i++) {
      countArr.push(i + 1);
    }
    setCount(countArr);
  }, [totalPages, countArr]);

  const onSelect = e => {
    console.log(e);
    loadNext(e);
  };
  return (
    <div>
      <Breadcrumbs />
      <Container>
        {totalPages > 1 && (
          <PaginationComponent count={count} active={0} onSelect={onSelect} />
        )}
        {newsArr !== null &&
          newsArr.map((itemnews, index) => {
            return (
              <Row key={index}>
                <Col lg={12} className="news-block">
                  <NewsComponent
                    key={itemnews.id}
                    newsTitle={itemnews.name}
                    newsContent={itemnews.annotation}
                    newsDate={itemnews.date}
                  />
                </Col>
              </Row>
            );
          })}
      </Container>
      <Footer />
    </div>
  );
};

export default News;
