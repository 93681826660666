import { combineReducers } from 'redux';
import createStaticPagesReducer from '../pages/information/reducer';
import loginReducer from '../pages/login/reducer';
import newsReducer from '../pages/news/reducer';
import newsAdminReducer from '../pages/newsadmin/reducer';
import registerReducer from '../pages/register/reducer';
import cartReducer from '../pages/cart/reducer';
import profileOrdersReducer from '../pages/personal_profile/orders/reducer';
import profileInvoicesReducer from '../pages/personal_profile/invoices/reducer';
import profileRefundsReducer from '../pages/personal_profile/refunds/reducer';

const rootReducer = combineReducers({
  about: createStaticPagesReducer('about'),
  login: loginReducer,
  delivery: createStaticPagesReducer('delivery'),
  payment: createStaticPagesReducer('payment'),
  clients: createStaticPagesReducer('clients'),
  business: createStaticPagesReducer('business'),
  news: newsReducer,
  newsadmin: newsAdminReducer,
  register: registerReducer,
  cart: cartReducer,
  profileOrders: profileOrdersReducer,
  profileInvoices: profileInvoicesReducer,
  profileRefunds: profileRefundsReducer,
});

export default rootReducer;
