import produce from 'immer';
import {
  ADD_TO_CART,
  REMOVE_ITEM_CART,
  COUNT_TO_CART,
  COMMENT_TO_CART,
  GABARITE_TO_CART,
  GET_CART,
  CART_FETCH_FAIL,
  CART_CATCH_FAIL,
  CART_LOADING,
  CART_ITEM_CHECKED,
  TO_STEP,
  SUCCESS,
  ADD_TO_CART_MESS,
} from '../const';

const calcSum = (products, selected) => {
  let sum = 0;
  let isTransport = true;
  let isGabarite = false;
  // eslint-disable-next-line
  products.map((prod: any) => {
    if ((selected && selected.includes(prod.id)) || !selected) {
      if (
        prod.transportPerKg !== 0 &&
        prod.transportPerKg &&
        (prod.weightAprox !== 0 || prod.weightSharp !== 0)
      ) {
        prod.weightSharp
          ? (sum += Number(
              (
                (prod.transportPerKg * prod.weightSharp + prod.price).toFixed(
                  2
                ) * prod.count
              ).toFixed(2)
            ))
          : (sum += Number(
              (
                (prod.transportPerKg * prod.weightAprox + prod.price).toFixed(
                  2
                ) * prod.count
              ).toFixed(2)
            ));
        isTransport = false;
      } else {
        sum += Number((prod.price * prod.count).toFixed(2));
      }
      if (prod.gabarite) {
        isGabarite = true;
      }
    }
  });

  return { sum: Number(sum.toFixed(2)), isTransport, isGabarite };
};

const calcCount = (products) => {
  let count = 0;
  // eslint-disable-next-line
  products.map((prod: any) => {
    count += Number(prod.count);
  });

  return count;
};

export const initialState = {
  products: [],
  selectedProducts: [],
  sum: { sum: 0, isTransport: true, isGabarite: false },
  selectedSum: { sum: 0, isTransport: true, isGabarite: false },
  step: 1,
  count: 0,
  error: '',
  loading: false,
  success: false,
  alreadyInCart: false,
  addToCartMess: '',
  showMess: false,
};

const cartReducer = (state = initialState, action) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case CART_LOADING:
        draft.loading = action.data;
        break;
      case TO_STEP:
        draft.step = action.data;
        break;
      case ADD_TO_CART:
        draft.count += action.payload.count;
        draft.alreadyInCart = action.payload.res.alreadyInCart;
        draft.addToCartMess = `${action.payload.res.description} ${
          action.payload.res.manufacturer
        } ${
          action.payload.res.alreadyInCart ? 'повторно' : ''
        } добавлен в корзину`;
        draft.showMess = true;
        draft.error = '';
        break;
      case ADD_TO_CART_MESS:
        draft.alreadyInCart = false;
        draft.addToCartMess = '';
        draft.showMess = false;
        draft.error = '';
        break;
      case GET_CART:
        draft.products = action.data;
        draft.sum = calcSum(draft.products, null);
        draft.count = calcCount(draft.products);
        draft.error = '';
        break;
      case REMOVE_ITEM_CART:
        let newProducts = state.products.filter(
          (item: any) => !action.data.includes(item.id)
        );
        draft.products = newProducts;
        draft.sum = calcSum(draft.products, null);
        let selectedProducts = state.selectedProducts.filter(
          (item: any) => !action.data.includes(item)
        );
        draft.selectedProducts = selectedProducts;
        draft.selectedSum = calcSum(draft.products, draft.selectedProducts);
        draft.count = calcCount(draft.products);
        draft.error = '';
        break;
      case COUNT_TO_CART:
        let countProducts = draft.products.map((item: any) => {
          if (item.id === action.data.id) {
            item.count = action.data.count;
            return item;
          } else {
            return item;
          }
        });
        draft.products = countProducts;
        draft.sum = calcSum(draft.products, null);
        draft.count = calcCount(draft.products);
        draft.selectedSum = calcSum(draft.products, draft.selectedProducts);
        draft.error = '';
        break;
      case COMMENT_TO_CART:
        let commentProducts = draft.products.map((item: any) => {
          if (item.id === action.data.id) {
            item.comment = action.data.comment;
            return item;
          } else {
            return item;
          }
        });
        draft.products = commentProducts;
        draft.error = '';
        break;
      case GABARITE_TO_CART:
        let gabariteProducts = draft.products.map((item: any) => {
          if (item.id === action.data.id) {
            item.gabarite = action.data.gabarite;
            return item;
          } else {
            return item;
          }
        });
        draft.products = gabariteProducts;
        draft.sum = calcSum(draft.products, null);
        draft.selectedSum = calcSum(draft.products, draft.selectedProducts);
        draft.error = '';
        break;
      case CART_ITEM_CHECKED:
        if (action.data === 'check_all') {
          draft.selectedProducts.length === draft.products.length
            ? (draft.selectedProducts = [])
            : (draft.selectedProducts = draft.products.map(
                (item: any) => item.id
              ));
        } else {
          const searchId = draft.selectedProducts.filter(
            (item: any) => item === action.data
          );
          let newSelectedProducts;
          if (searchId.length) {
            newSelectedProducts = draft.selectedProducts.filter(
              (item: any) => item !== action.data
            );
          } else {
            newSelectedProducts = draft.selectedProducts;
            newSelectedProducts.push(action.data);
          }
          draft.selectedProducts = newSelectedProducts;
        }
        draft.selectedSum = calcSum(draft.products, draft.selectedProducts);
        draft.error = '';
        break;
      case CART_FETCH_FAIL:
        draft.error = action.data;
        break;
      case CART_CATCH_FAIL:
        draft.error = 'catch error';
        break;
      case SUCCESS:
        draft.success = action.data;
        break;
      default:
        return state;
    }
  });

export default cartReducer;

// calcSum(draft.products, draft.selectedProducts) - calc all products
// calcSum(draft.products, null) - calc selected products
