import { useState, useEffect, useMemo } from 'react';
import { Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalForm from './ModalOrderProductForm';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { LightboxComp } from '../../../../components/lightbox';

type Props = {
  products: [any];
  selectionByOrder: string;
  setSelectionByOrder: Function;
  orderNumLinkHandler: Function;
  toOrderFilterOrderNumber: Function;
};

type modalData = {
  cOrder: string;
  orderNumber: string;
  type: string;
  name: string;
  maker: string;
};

const Products = (props: Props) => {
  const {
    products,
    selectionByOrder,
    setSelectionByOrder,
    toOrderFilterOrderNumber,
  } = props;

  const [paginationPages, setPaginationPages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<modalData>({} as modalData);
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);
  const [time, setTime] = useState<number>(1);
  const [searchTime, setSearchTime] = useState<number>(0);
  const [searchSku, setSearchSku] = useState<string>('');
  const [orderStatuses, setOrderStatuses] = useState<any>({});
  const [maker, setMaker] = useState<number>(0);
  const [runLightbox, setRunLightbox] = useState<any>([]);

  const makeStatuses = () => {
    const statusesNewSet = new Set();
    products.forEach((item) =>
      !item.status ? null : statusesNewSet.add(item.status)
    );
    const statusesArr = Array.from(statusesNewSet);
    const statuses = {};
    statusesArr.forEach((item: any) => {
      statuses[item] = false;
    });
    setOrderStatuses(statuses);
  };

  const dropFilters = () => {
    setSearchSku('');
    setTimeHandler(100);
    setMaker(0);
    makeStatuses();
  };

  useEffect(() => {
    makeStatuses();
  }, []);

  useEffect(() => {
    if (selectionByOrder) {
      dropFilters();
    }
  }, [selectionByOrder]);

  const modalHandler = (data) => {
    setModalData({ ...data });
    setShow(true);
  };

  const setTimeHandler = (monthValue) => {
    if (monthValue === 100) {
      setTime(monthValue);
      setSearchTime(0);
      return;
    }
    setTime(monthValue);
    const currDate = new Date();
    currDate.setMonth(currDate.getMonth() - monthValue);
    const selectedDateTimeStamp = currDate.getTime();
    setSearchTime(selectedDateTimeStamp);
  };

  const makers = useMemo(() => {
    const makersNewSet = new Set();
    products.forEach((item) => makersNewSet.add(item.maker));
    const makers = Array.from(makersNewSet).sort();

    return makers;
  }, []);

  const filteredIsChecked = useMemo(() => {
    const isChecked: string[] = [];

    Object.entries(orderStatuses).map(([key, value]) => {
      if (value) {
        isChecked.push(key);
      }
    });

    return isChecked;
  }, [orderStatuses]);

  const callbackFilter = (el) => {
    if (!filteredIsChecked.length) {
      return true;
    }

    for (let i = 0; i < filteredIsChecked.length; i++) {
      if (filteredIsChecked[i] === el.status) {
        return true;
      }
    }

    return false;
  };

  const filteredProducts = useMemo(() => {
    const filteredData = products
      .filter((product) => {
        return (
          product.orderNumber.includes(selectionByOrder) &&
          product.sku.includes(searchSku) &&
          (maker === 0 || maker === 1
            ? product
            : product.maker.includes(makers[maker - 2]))
        );
      })
      .filter((invoice) => {
        return new Date(invoice.date).getTime() >= searchTime;
      })
      .filter(callbackFilter);
    setPage(1);

    return filteredData;
  }, [searchTime, searchSku, maker, orderStatuses, selectionByOrder]);

  const paginationProducts = useMemo(() => {
    let pages: any[] = [];
    for (
      let number = 1;
      number <= Math.ceil(filteredProducts.length / itemsPerPage);
      number++
    ) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => setPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPaginationPages([...pages]);

    if (pages.length < page) {
      setPage(pages.length);
    }

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const currentData = filteredProducts.slice(start, end);

    return currentData;
  }, [filteredProducts, page, itemsPerPage]);

  useEffect(() => {
    setTimeHandler(1);
  }, []);

  return (
    <>
      <ModalForm show={show} setShow={setShow} modalData={modalData} />
      <Row>
        <Form.Group className="mb-3 col-12 d-flex align-items-center">
          <span style={{ marginRight: '0.75rem' }}>
            Статус товара в заказе:
          </span>
          {Object.entries(orderStatuses).map(([key, value], ind) => {
            return (
              <Form.Check
                key={ind}
                inline
                label={key}
                name={`status-${ind + 100}`}
                type="checkbox"
                // @ts-ignore:next-line
                checked={value}
                id={`statusId-${ind + 100}`}
                onChange={() =>
                  setOrderStatuses({ ...orderStatuses, [key]: !value })
                }
              />
            );
          })}
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            placeholder="Введите номер артикула товара"
            onChange={(e) => setSearchSku(e.target.value)}
            value={searchSku}
            className="rounded-0"
          />
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setTimeHandler(+e.target.value)}
            value={time}
          >
            <option value={1}>1 месяц</option>
            <option value={3}>3 месяца</option>
            <option value={6}>6 месяцев</option>
            <option value={12}>Год</option>
            <option value={100}>Всё время</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-12 col-sm-6 col-md-4">
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setMaker(+e.target.value)}
            value={maker}
          >
            <option value={0} disabled>
              Выберите производителя
            </option>
            <option value={1}>Все</option>
            {/* {makers.map((item: any, ind) => (
              <option key={ind} value={ind + 2}>
                {item}
              </option>
            ))} */}
            {makers.map((item: any, ind) => {
              const foundObject = filteredProducts.find(
                (obj: any) => obj.maker === item
              );
              if (foundObject) {
                return (
                  <option key={ind} value={ind + 2}>
                    {item}
                  </option>
                );
              }
              return null;
            })}
          </Form.Control>
        </Form.Group>
        {selectionByOrder && (
          <>
            <div className="mb-1 col-12">
              Отбор по заказу: {selectionByOrder}
              <span
                className="selection-by text-d-underline"
                onClick={() => setSelectionByOrder('')}
              >
                Показать все товары
              </span>
            </div>
            <div className="mb-3 col-12">
              Показать РН по заказу:
              <span className="selection-by text-d-underline link-not-hover">
                <Link to={`/cabinet/invoices?orderNumber=${selectionByOrder}`}>
                  {selectionByOrder}
                </Link>
              </span>
            </div>
          </>
        )}
      </Row>
      <Table striped bordered hover responsive className="products-table h-100">
        <thead>
          <tr>
            <th>Заказ</th>
            <th>Дата заказа</th>
            <th className="word-wrap">Артикул</th>
            <th>Производитель</th>
            <th className="word-wrap">Наименование</th>
            <th className="p-0 text-center">
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-disabled">Отметка о габарите</Tooltip>
                }
              >
                <div className="table-popover table-head-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001 6.971 2.789Zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z"
                    />
                  </svg>
                </div>
              </OverlayTrigger>
            </th>
            <th>Кол-во</th>
            <th>Цена, шт</th>
            <th>Транспорт, шт</th>
            <th>Дата поставки</th>
            <th>Статус</th>
            <th className="p-0 text-center">
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-disabled">Управление заказом</Tooltip>
                }
              >
                <div className="table-popover table-head-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-gear-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                  </svg>
                </div>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {paginationProducts.map((product, ind) => {
            return (
              <tr key={ind}>
                <td>
                  <Dropdown className="product-orders-link d-flex">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {product.orderNumber}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey="1"
                        // @ts-ignore:next-line
                        disabled={selectionByOrder}
                        onClick={() => setSelectionByOrder(product.orderNumber)}
                      >
                        Поазать все товары по данному заказу
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() =>
                          toOrderFilterOrderNumber(product.orderNumber)
                        }
                      >
                        Показать детали заказа
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td>{new Date(product.date).toLocaleDateString()}</td>
                <td className="word-wrap">
                  {product.sku}{' '}
                  {product.replacementSKU
                    ? `(замена на ${product.replacementSKU})`
                    : null}
                </td>
                <td>
                  {product.maker}{' '}
                  {product.replacementMaker
                    ? `(замена на ${product.replacementMaker})`
                    : null}
                </td>
                <td className="word-wrap">
                  {product.name}{' '}
                  {product.replacementName
                    ? `(замена на ${product.replacementName})`
                    : null}
                  <button
                    className="btn btn-sm icon-modal-btn"
                    disabled={!product?.images.length}
                    onClick={() => setRunLightbox([...product.images])}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill={product.images.length ? 'currentColor' : '#ccc'}
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                    </svg>
                  </button>
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <input type="checkbox" checked={product.gabarit} disabled />
                  </div>
                </td>
                <td>{product.quantity}</td>
                <td>
                  {product.price} {product.currency}
                </td>
                <td>
                  {product.transport
                    ? `${product.transport} ${product.currency}`
                    : null}
                </td>
                <td>{new Date(product.deliveryDate).toLocaleDateString()}</td>
                <td>{product.status}</td>
                <td className="p-0">
                  <Button
                    className="product-options-btn d-flex justify-content-center align-items-center h-100"
                    variant="link"
                    // @ts-ignore:next-line
                    disabled={!product.cOrder}
                    onClick={() =>
                      modalHandler({
                        cOrder: product.cOrder,
                        orderNumber: product.orderNumber,
                        name: product.name
                          ? product.name
                          : product.replacementName,
                        maker: product.maker
                          ? product.maker
                          : product.replacementMaker,
                      })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-gear"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                      <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex overflow-auto flex-wrap">
        <Form.Group
          style={{ marginRight: '0.75rem' }}
          className="flex-shrink-0 mt-3"
        >
          <Form.Control
            as="select"
            custom
            className="rounded-0"
            onChange={(e) => setItemsPerPage(+e.target.value)}
            value={itemsPerPage}
          >
            <option value={10}>10 строк</option>
            <option value={15}>15 строк</option>
            <option value={20}>20 строк</option>
            <option value={30}>30 строк</option>
            <option value={50}>50 строк</option>
          </Form.Control>
        </Form.Group>
        {paginationPages.length > 1 && (
          <Pagination className="mt-3">{paginationPages}</Pagination>
        )}
      </div>
      <LightboxComp runLightbox={runLightbox} setRunLightbox={setRunLightbox} />
    </>
  );
};

export default Products;
