import {
  LANGUAGE,
  GET_ERROR,
  EDIT_NEWS,
  GET_NEWS_EDITED,
  GET_ADMIN_NEWS,
  DELETE_NEWS,
  GET_TARGETOF_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  GET_TARGET_REGION,
  POST_NEWS,
  FETCH_CONTACTS,
  GET_TARGET_SUCCESS,
  GET_TARGET_ERROR,
  GET_TARGET_OFFICES,
  GET_NEWS_EDITEDCLEAN
} from "../const";

export function cleanEditedNews() {
  return {
    type: GET_NEWS_EDITEDCLEAN
  };
}

export function getAdminNews(page) {
  return {
    type: GET_ADMIN_NEWS,
    page: page
  };
}

export function getTargetRegion() {
  return {
    type: GET_TARGET_REGION
  };
}

export function getTargetOffives() {
  return {
    type: GET_TARGET_OFFICES
  };
}

export function getNewsSuccess(value: any) {
  return {
    type: GET_NEWS_SUCCESS,
    value: value
  };
}
export function getNewsEditedSuccess(value: any) {
  return {
    type: GET_NEWS_EDITED,
    value: value
  };
}

export function getNewsError(page: string, status: string, value: any) {
  return {
    type: GET_NEWS_ERROR,
    status,
    value
  };
}
export function getTargetSuccess(value: any) {
  return {
    type: GET_TARGET_SUCCESS,
    value
  };
}
export function getTargetOfSuccess(value: any) {
  return {
    type: GET_TARGETOF_SUCCESS,
    value
  };
}
export function getTargetError(page: string, status: string, value: any) {
  return {
    type: GET_TARGET_ERROR,
    status,
    value
  };
}

export function deleteNews(language: string, id: string) {
  return {
    type: DELETE_NEWS,
    lang: language,
    id: id
  };
}

export function saveNews(data: any) {
  return {
    type: POST_NEWS,
    data
  };
}

export function editNews(id: any) {
  return {
    type: EDIT_NEWS,
    id
  };
}

export function savePage(id: string, content: string) {
  return {
    type: POST_NEWS,
    id,
    content
  };
}
export function getContactsSuccess(contact: any) {
  return {
    type: FETCH_CONTACTS,
    contact
  };
}

export function getLanguageSuccess(language: any) {
  return {
    type: LANGUAGE,
    language
  };
}
export function getPageError(page: string, status: string, value: any) {
  return {
    type: GET_ERROR + page,
    status,
    value
  };
}
