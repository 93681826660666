import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/fonts.scss';
import './styles/icons.scss';
import './App.scss';
import Header from './components/Header';
import Mainpage from './pages/Mainpage';
import InformationPage from './pages/information/pages';
import PersonalCabinet from './pages/personal_profile/personal_cabinet/container';
import Contacts from './pages/information/Contacts';
import Catalog from './pages/Catalog';
import Office from './pages/information/Office';
import Cart from './pages/cart';
import News from './pages/news/container';
import Newsadmin from './pages/newsadmin/container';
import Register from './pages/register/container';
import Table from './pages/Table';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/login/container';
//import LanguageProvider from "./LanguageProvider";
//import { translationMessages } from "./i18n";
//import { Redirect } from "react-router";

import './styles/adaptation/mobile.scss';
import './styles/adaptation/tablet.scss';
import './styles/adaptation/desktop.scss';
// import { Office } from './components/office/OfficeImages';
import getMainOffice from './utils/findmainoffice';
import { checkToken } from './utils/tokenchecker';
import { SearchInformation } from './pages/searchInformation';
import { sagaCartGet } from './pages/cart/actions';
//import useScript from "./utils/hook/useScript";
//import { GOOGLE_MAP_API } from "./utils/settings";

// const store = configureStore({});
const mainOffice = {
  name: 'Харьков',
  contact: {
    id: '39',
    latitude: 49.9316469,
    longitude: 36.26492,
    type: 'Kyivstar',
    name: '+38-98-900-800-1',
    prefered: true,
  },
};
function App() {
  const [contact, setRegionContact] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const { isLogged: isLoggedFromRedux } = useSelector((state: any) => {
    return state.register;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('token') && isLogged) {
      dispatch(sagaCartGet());
    }
  }, [isLogged]);

  useEffect(() => {
    if (localStorage.getItem('editmode') === 'true') {
      setShowEditor(true);
    } else {
      setShowEditor(false);
    }

    checkToken();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('isLogged') === '1') {
      setIsLogged(true);
    } else setIsLogged(false);
  }, [isLoggedFromRedux]);

  useEffect(() => {
    (async () => {
      let mRegion = await getMainOffice();
      fetch(
        'https://letters.s-car.com.ua/api/v1/Company/contacts?ip=' + mRegion.ip,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'ru',
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((region: any) => {
          if (region.contact) {
            setRegionContact(region.contact.name);
          } else {
            setRegionContact(mainOffice.contact.name);
          }
        })
        .catch(function (error) {
          setRegionContact(mainOffice.contact.name);
        });
    })();
  }, [contact]);

  const [rerender, setrerender] = useState(false);
  const logincallback = () => {
    setrerender(true);

    // setShowEditor(true);
  };
  return (
    <BrowserRouter>
      <Header contact={contact} rerender={rerender} isLogged={isLogged} />
      <Switch>
        <Route component={Mainpage} path="/" exact />
        <Route
          render={() => <Login page="login" callback={logincallback} />}
          path="/login"
          exact
        />
        <Route
          render={() => <PersonalCabinet page="cabinet" />}
          path="/cabinet"
        ></Route>
        <Route
          render={() => (
            <InformationPage page="about" showEditor={showEditor} />
          )}
          path="/about"
          exact
        />
        <Route
          render={() => (
            <InformationPage page="delivery" showEditor={showEditor} />
          )}
          path="/delivery"
          exact
        />
        <Route component={Office} path="/office" exact />
        <Route
          render={() => (
            <InformationPage page="payment" showEditor={showEditor} />
          )}
          path="/payment"
          exact
        />
        <Route
          render={() => (
            <InformationPage page="clients" showEditor={showEditor} />
          )}
          path="/clients"
          exact
        />
        <Route
          render={() => (
            <InformationPage page="business" showEditor={showEditor} />
          )}
          path="/business"
          exact
        />
        <Route
          render={() => <News page="news" showEditor={showEditor} />}
          path="/news"
          exact
        />
        <Route
          render={() => <Newsadmin showEditor={showEditor} />}
          path="/newsadmin"
          exact
        />
        <Route
          render={() => <Register showEditor={showEditor} />}
          path="/register"
          exact
        />
        <Route path={'/search'}>
          <SearchInformation />
        </Route>
        <Route component={Contacts} path="/contacts" exact />
        <Route component={Catalog} path="/catalog" exact />
        <Route component={Cart} path="/cart" exact />
        <Route component={Table} path="/table" exact />
        <Route component={NotFoundPage} path={'*' || '/errorPage'} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
